import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ViewTitle from '../../components/ViewTitle';
import {
  SubjectCard,
  ProfileWidget,
  UpgradeCard,
  VideoPlanCard,
  PubsCards,
} from '../../components';
import { useSelector, useDispatch } from 'react-redux';
import { getSubjects } from '../../redux/slices/subjects';
import { getSubjectsProgress } from '../../redux/slices/progress';
// import Link from '@material-ui/core/Link';
import Skeleton from '@material-ui/lab/Skeleton';
import ImP1 from '../../assets/img/icons/info.svg';
import ImP2 from '../../assets/img/icons/tinder.svg';
import ImP3 from '../../assets/img/icons/gift.svg';
import ImP5 from '../../assets/img/icons/trial.svg';
import TxP1 from '../../assets/img/icons/text1.svg';
import TxP2 from '../../assets/img/icons/text2.svg';
import TxP3 from '../../assets/img/icons/text3.svg';
import TxP5 from '../../assets/img/icons/text5.svg';
import Bg5 from '../../assets/img/icons/bg5.svg';
import { getNewSubjectsProgress } from '../../redux/slices/newProgress';
import TxP4 from '../../assets/img/icons/text4.svg';
import ImP4 from '../../assets/img/icons/nine.svg';

const Index = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { subjects, isLoading } = useSelector((state) => state.subjects);
  //const { subjectsProgress, isSubjectsProgressLoading } = useSelector((state) => state.progress);
  const { newsubjectsProgress, newisSubjectsProgressLoading } = useSelector(
    (state) => state.newProgress
  );
  const { informations, permissions } = useSelector((state) => state.user);

  useEffect(() => {
    if (!subjects && informations?.division_id) {
      dispatch(getSubjects());
    }
    /* if (!subjectsProgress) {
      dispatch(getSubjectsProgress());
    }*/
    if (!newsubjectsProgress && informations?.division_id) {
      dispatch(getNewSubjectsProgress());
    }
  }, [informations?.division_id]);

  if (isLoading) {
    return (
      <div className="ta-row">
        <div className="ta-column right-margin subjects-container">
          <div className="child-full subjects-list">
            {[...Array(8)].map(() => (
              <Skeleton variant="rect" width={179} height={149} />
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="full-page">
      <ViewTitle title={t('Matières')} />
      <div className="ta-row">
        {isLoading !== true ? (
          <div className="ta-column right-margin subjects-container">
            {subjects && subjects.length > 0 ? (
              <div className="child-full subjects-list">
                {subjects
                  .filter((subject) => subject.active === true)
                  .sort((a, b) => (parseInt(a.position) > parseInt(b.position) ? 1 : -1))
                  .map((subject) => {
                    //old progress
                    /*  const progress =
                      subjectsProgress &&
                      subjectsProgress.length > 0 &&
                      subjectsProgress.find((p) => p.subject_id == subject.id);
                      const subjectProgress = progress ? progress.value : 0;*/
                    //new progress
                    const newProgress =
                      newsubjectsProgress &&
                      newsubjectsProgress.length > 0 &&
                      newsubjectsProgress.find((p) => p.subject_id == subject.id);
                    const newSubjectProgress = newProgress
                      ? Math.round((newProgress?.nbCompleted / subject?.subjectVideos) * 100)
                      : 0;
                    return (
                      <SubjectCard
                        subject={subject}
                        //progressValue={subjectProgress}
                        newSubjectProgress={newSubjectProgress}
                        isLoading={isLoading}
                      />
                    );
                  })}
              </div>
            ) : (
              <div>{t('Pas encore de sujets')}</div>
            )}
          </div>
        ) : (
          <div className="ta-column right-margin subjects-container">
            <div className="child-full subjects-list">
              {subjects &&
                subjects.map((subject) => <Skeleton variant="rect" width={179} height={149} />)}
            </div>
          </div>
        )}
        <div className="ta-column fixed right">
          <ProfileWidget mini />
          <PubsCards className="info" icon={ImP1} text={TxP1} modal path={`L36YrGMQGO0`} />
          <PubsCards className="warning" icon={ImP2} text={TxP2} modal path={`ESJGB-lqVMU&ab`} />
          <PubsCards className="success" icon={ImP3} text={TxP3} path={`/offres`} />
          {/* <PubsCards className="anniversary" modal icon={ImP4} text={TxP4} path={`6rtRNOA4PvA`} /> */}
          {permissions.takeTrialAdvantage && (
            <PubsCards className="trial" icon={ImP5} text={TxP5} bg={Bg5} path={`/offres`} />
          )}{' '}
        </div>
      </div>
    </div>
  );
};

export default Index;
