import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { sendVideoReport, getReportReasons } from '../../../redux/slices/courses';
import { useSelector, useDispatch } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import {enqueueSnackbar} from '../../../redux/slices/notifier';

const VideoReport = (props) => {
  const { t } = useTranslation();
  const { data } = props;
  const { reportError } = useSelector((state) => state.courses);
  const dispatch = useDispatch();
  const content = data.split('-')[0];
  const [reportReason, setReason] = useState();
  const [description, setDescription] = useState('');
  const [open, setOpen] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const {reasons} = useSelector((state) => state.courses);


  useEffect(() => {
    if(!reasons?.length){
      dispatch(getReportReasons());
    }
  }, []);
  const handleSubmit = () => {
    if (reportReason === '' || description === '') {
      dispatch(
          enqueueSnackbar({
            message: t('Remplir les champs raison et description'),
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'warning',
            },
          }),
      );
      return;
    }
    dispatch(sendVideoReport(content, reportReason, description));
    setOpenSnackBar(true);
    setOpen(false);
    setReason('');
    setDescription('');
  };
 
  return (
    <div>
      <FormControl fullWidth variant="outlined">
        <InputLabel id="discount-method-label">{t("Raison du signalement")}  </InputLabel>
        <Select
          fullWidth
          labelId="report-label"
          name="reportReason"
          id="reportReason"
          value={reportReason}
          onChange={(e) => setReason(e.target.value)}
          label={t("Raison du signalement")}
        >
           {
              reasons.map((reason)=>{
                return (
                  <MenuItem value={reason.id}>{t(reason.name)}</MenuItem>);
                })
            }
        </Select>
      </FormControl>
      <TextField
        fullWidth
        //margin="dense"
        id="description"
        value={description}
        label={t("Précisez le type exact d erreur ou de déficience, s il vous plait")}
        variant="outlined"
        onChange={(e) => setDescription(e.target.value)}
        style={{ marginTop: 10 }}
      />
      <div className="basic">
        <Button
          onClick={(event) => handleSubmit(event)}
          className="buttonAdd"
          style={{ left: 300 }}
        >
          {t("Reporter")}
        </Button>
      </div>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'Bottom',
          horizontal: 'center',
        }}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert
          severity={reportError ? 'warning' : 'success'}
          // severity={'success'}
          onClose={() => setOpenSnackBar(false)}
        >
          {reportError ? t(reportError) : t('success')}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default VideoReport;
