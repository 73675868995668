import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ViewTitle from '../../components/ViewTitle';
import { ProfileWidget, PubsCards } from '../../components';
import SubjectWidget from './components/SubjectWidget';
import SubjectTabs from './components/SubjectTabs';
import { getSubjects } from '../../redux/slices/subjects';
import { getChapters } from '../../redux/slices/chapters';
import ImP1 from '../../assets/img/icons/info.svg';
import ImP2 from '../../assets/img/icons/tinder.svg';
import ImP3 from '../../assets/img/icons/gift.svg';
import TxP1 from '../../assets/img/icons/text1.svg';
import TxP2 from '../../assets/img/icons/text2.svg';
import TxP3 from '../../assets/img/icons/text3.svg';
import { getSubjectChaptersProgress, getSubjectProgress } from '../../redux/slices/progress';
import {getNewSubjectChaptersProgress, getNewSubjectProgress} from "../../redux/slices/newProgress";

const SubjectDetail = () => {
  const { t } = useTranslation();
  let { subject_id } = useParams();
  const subjectSlug = subject_id;
  const subjectId = subjectSlug.split('-')[0];
  const dispatch = useDispatch();
  const { subjects } = useSelector((state) => state.subjects);
  const { chaptersSubject, chapters } = useSelector((state) => state.chapters);
  const [Subjects, setSubjects] = useState([]);
  const [Chapters, setChapters] = useState([]);
  const [Subject, setSubject] = useState(null);
  //const { chaptersProgress, subjectProgress } = useSelector((state) => state.progress);
  const { newchaptersProgress, newsubjectProgress } = useSelector((state) => state.newProgress);
  useEffect(() => {
    if (subjects) {
      //setSubject(subjects.find((subject) => subject.id === parseInt(subjectId)));
      setSubject(subjects.find((subject) => subject.slug === subjectSlug));
    }
    if (subjectSlug) {
      dispatch(getChapters(subjectSlug));
    }
    
  }, [subjectSlug]);

  useEffect(() => {
    if (!subjects) {
      dispatch(getSubjects());
    }
    if (!chapters) {
      if (subjectSlug) {
        dispatch(getChapters(subjectSlug));
      }
    }
  }, []);

  useEffect(() => {
    if (subjects) {
      setSubjects(subjects);
      //setSubject(subjects.find((subject) => subject.id === parseInt(subjectId)));
      setSubject(subjects.find((subject) => subject.slug === subjectSlug));

    }
  }, [subjects]);

  useEffect(() => {
    if (chapters) {
      setChapters(chapters);
    }
  }, [chapters]);

  useEffect(() => {
    if (Subject) {
      //dispatch(getSubjectChaptersProgress(subjectId));
      //dispatch(getSubjectProgress(subjectId));
      dispatch(getNewSubjectChaptersProgress(subjectId));
      dispatch(getNewSubjectProgress(subjectId));
    }
  }, [Subject]);
  return (
    <div className="full-page">
      <ViewTitle title={t('Matières')} />
      <div className="ta-row">
        <div className="ta-column childrens-inverse right-margin layout-subjects-details">
          <div className="child-left">
            <SubjectTabs chapters={Chapters} slug={subjectSlug}
                         //chaptersProgress={chaptersProgress}
                         newchaptersProgress={newchaptersProgress}/>
          </div>
          <div className="child-right">
            <SubjectWidget
              subject={Subject}
              subjects={Subjects}
              totalChapters={chapters && chapters.length}
              totalVideos={chaptersSubject && chaptersSubject.subjectVideos}
              //progress={subjectProgress}
              newsubjectProgress={newsubjectProgress}
            />
          </div>
        </div>
        <div className="ta-column fixed right">
          <div style={{ width: '100%', marginTop: '60px' }}></div>
          <ProfileWidget mini />
          <PubsCards className="info" icon={ImP1} text={TxP1} modal path={`L36YrGMQGO0`} />
          <PubsCards className="warning" icon={ImP2} text={TxP2} modal path={`ESJGB-lqVMU&ab`} />
          <PubsCards className="success" icon={ImP3} text={TxP3} path={`/offres`} />
        </div>
      </div>
    </div>
  );
};

export default SubjectDetail;
