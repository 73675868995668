import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { verifyEmail } from '../../../redux/slices/sessions';
import { useSelector, useDispatch } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { me } from '../../../redux/slices/user';

const EmailModal = (props) => {
  const { i18n,t } = useTranslation();
  const { onCloseModal, data } = props;
  const { code, codeError } = useSelector((state) => state.sessions);
  const { informations } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [value, setCode] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const successMessage = code && t(code?.message);
  const successCode = code && code?.code;
  useEffect(() => {
    if (successCode === 200 && !codeError) {
      setTimeout(function () {
        onCloseModal(true);
      }, 3000);
      dispatch(me());
    }
  }, [successMessage,  t(codeError)]);
  const handleSubmit = () => {
    dispatch(verifyEmail(value, data));
    setOpenSnackBar(true);
  };
  return (
    <div className= {i18n.language === 'ar' ? 'rtl' : 'base'}>
      <TextField
        autoFocus
        outlined
        id="code"
        //label={t("Entrez votre code")}
        placeholder={t("Entrez votre code")}
        type="string"
        value={value}
        margin="dense"
        variant="outlined"
        className="basic-fields"
        style={{ width: 350, height: 40 }}
        onChange={(e) => setCode(e.target.value)}
      />

      <div className="basic">
        <Button
          onClick={(event) => handleSubmit(event)}
          className="buttonAdd"
          style={{ left: 240 }}
        >
          {t("Soumettre")}
        </Button>
      </div>
      {
        successMessage || codeError &&
          <Snackbar
          open={openSnackBar}
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: 'Bottom',
            horizontal: 'center',
          }}
          onClose={() => setOpenSnackBar(false)}
        >
          <Alert
            severity={successCode === 200 ? 'success' : 'warning'}
            onClose={() => setOpenSnackBar(false)}
          >
            {successMessage ? t(successMessage) : t(codeError)}
          </Alert>
        </Snackbar>
     
      }
    </div>
  );
};

export default EmailModal;
