import Im_Accueil from '../assets/img/icons/home.svg';
import Im_Profile from '../assets/img/icons/user.svg';
import Im_Offres from '../assets/img/icons/gem.svg';
import Im_Matieres from '../assets/img/icons/book.svg';
import Im_Examens from '../assets/img/icons/graduation.svg';
import Im_Direct from '../assets/img/icons/cast.svg';
import Im_Forum from '../assets/img/icons/chat.svg';
import Im_Porte from '../assets/img/icons/wallet.svg';
import Im_Agent from '../assets/img/icons/agent.png';
import Im_Facebook from '../assets/img/icons/facebook.svg';
import Im_Instagram from '../assets/img/icons/instagram.svg';
import Im_Youtube from '../assets/img/icons/youtube.svg';
import Im_Money from '../assets/img/icons/revenu.png'

import Im_MoneyBag from '../assets/img/icons/money-bag.svg';
import Im_D17 from '../assets/img/icons/d17.png';
import Im_CreditCard from '../assets/img/icons/credit-card.svg';

import Im_Calculator from '../assets/img/icons/calculator.svg';
import Im_Plant from '../assets/img/icons/plant.svg';
import Im_Science from '../assets/img/icons/science.svg';
import Im_Lightbulb from '../assets/img/icons/lightbulb.svg';



export default {
  HEADERS_API: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
  DRAWER_WIDTH: 280,
  DRAWER_WIDTH_MINI: 80,
  SIDEBAR_LIST: [
    {
      id: 1,
      icon: Im_Accueil,
      name: 'Accueil',
      url: '/',
      link: false,
      permission: false,
    },
    {
      id: 2,
      icon: Im_Profile,
      name: 'Mon profil',
      url: '/mon-profil',
      link: false,
      permission: false,
    },
    {
      id: 3,
      icon: Im_Offres,
      name: 'Offres',
      url: '/offres',
      link: false,
      permission: false,
    },
    {
      id: 4,
      icon: Im_Matieres,
      name: 'Matières',
      url: '/subjects',
      link: false,
      permission: false,
    },
    {
      id: 5,
      icon: Im_Examens,
      name: 'Examens',
      url: '/exams',
      link: false,
      permission: true,
      permission_key: 'HAS_EXAM',
    },
    {
      id: 6,
      icon: Im_Direct,
      name: 'En direct',
      url: '/sessions',
      link: false,
      permission: false,
    },
    {
      id: 7,
      icon: Im_Forum,
      name: 'Le forum',
      url: 'https://forum.takiacademy.com',
      link: true,
      permission: true,
      permission_key: 'HAS_FORUM',
    },
    {
      id: 8,
      icon: Im_Porte,
      name: 'Porte monnaie',
      url: '/porte-monnaie',
      link: false,
      permission: false,
    },
    {
      id: 9,
      icon: Im_Agent,
      name: 'Assistance',
      url: '/assistance',
      link: false,
      permission: false,
    },
    {
      id: 10,
      icon: Im_Money,
      name: 'Revenu',
      url: '/revenu',
      link: false,
      permission: true,
      permission_key:'IS_INFLUENCER',
    }
  ],
  SOCIALS_LIST: [
    {
      name: 'Facebook',
      img: Im_Facebook,
      url: 'https://www.facebook.com/takiacademy',
    },
    {
      name: 'Instagram',
      img: Im_Instagram,
      url: 'https://www.instagram.com/takiacademy',
    },
    {
      name: 'Youtube',
      img: Im_Youtube,
      url: 'https://www.youtube.com/takiacademy',
    },
  ],
  BANK_ACCOUNTS: [
    {
      name: 'Banque zitouna',
      details: [
        {
          owner: 'TakiAcademy',
          number: '25 006 0000000317041 86',
        },
      ],
    },
    {
      name: 'Banque biat',
      details: [
        {
          owner: 'TakiAcademy',
          number: '08 139 0310110000790 88',
        },
      ],
    },
    {
      name: 'La poste tunisienne',
      details: [
        {
          owner: 'TakiAcademy',
          number: '1750 3000 0003 1179 2828',
        },
      ],
    },
  ],
  POINTS: 78,
  PAYMENT_METHODS: [
    {
      id: 'transfer',
      name: 'Versement bancaire',
      img: Im_MoneyBag,
      color: 'color-1',
      modal: null,
    },
    {
      id: 'd17',
      name: 'D17',
      img: Im_D17,
      color: 'color-2',
      modal: null,
    },
    {
      id: 'online',
      name: 'Paiement en ligne',
      img: Im_CreditCard,
      color: 'color-3',
      modal: null,
    },
  ],
  POINTS_HISTORY: {
    cols: [
      { accessor: 'code', label: 'Code' },
      { accessor: 'service', label: 'Mode de paiement' },
      { accessor: 'amount', label: 'Montant en dinars' },
      { accessor: 'desc', label: 'Description' },
      { accessor: 'date', label: 'Date' },
      { accessor: 'status', label: 'Statut' },
    ],
    rows: [
      {
        code: 'TT0000001',
        service: 'Banque Zitouna',
        amount: 80,
        desc: 'null',
        date: '01/01/2020',
        status: 'Terminé',
      },
      {
        code: 'TT0000001',
        service: 'Banque Zitouna',
        amount: 80,
        desc: 'null',
        date: '01/01/2020',
        status: 'Terminé',
      },
      {
        code: 'TT0000001',
        service: 'Banque Zitouna',
        amount: 80,
        desc: 'null',
        date: '01/01/2020',
        status: 'Terminé',
      },
      {
        code: 'TT0000001',
        service: 'Banque Zitouna',
        amount: 80,
        desc: 'null',
        date: '01/01/2020',
        status: 'Terminé',
      },
      {
        code: 'TT0000001',
        service: 'Banque Zitouna',
        amount: 80,
        desc: 'null',
        date: '01/01/2020',
        status: 'Terminé',
      },
      {
        code: 'TT0000001',
        service: 'Banque Zitouna',
        amount: 80,
        desc: 'null',
        date: '01/01/2020',
        status: 'Terminé',
      },
      {
        code: 'TT0000001',
        service: 'Banque Zitouna',
        amount: 80,
        desc: 'null',
        date: '01/01/2020',
        status: 'Terminé',
      },
      {
        code: 'TT0000001',
        service: 'Banque Zitouna',
        amount: 80,
        desc: 'null',
        date: '01/01/2020',
        status: 'Terminé',
      },
      {
        code: 'TT0000001',
        service: 'Banque Zitouna',
        amount: 80,
        desc: 'null',
        date: '01/01/2020',
        status: 'Terminé',
      },
      {
        code: 'TT0000001',
        service: 'Banque Zitouna',
        amount: 80,
        desc: 'null',
        date: '01/01/2020',
        status: 'Terminé',
      },
      {
        code: 'TT0000001',
        service: 'Banque Zitouna',
        amount: 80,
        desc: 'null',
        date: '01/01/2020',
        status: 'Terminé',
      },
      {
        code: 'TT0000001',
        service: 'Banque Zitouna',
        amount: 80,
        desc: 'null',
        date: '01/01/2020',
        status: 'Terminé',
      },
    ],
  },
  TODAY_EVENTS: [
    {
      title: null,
      description:
        "Contrairement à une opinion répandue, le Lorem Ipsum n'est pas simplement du texte aléatoire.",
      teacher: 'Ben Khalifa Skander',
      date: null,
      time: '15:00',
    },
    {
      title: null,
      description:
        "Contrairement à une opinion répandue, le Lorem Ipsum n'est pas simplement du texte aléatoire.",
      teacher: 'Hachem Charfi',
      date: null,
      time: '18:00',
    },
  ],
  MY_PROGRESS: [
    {
      icon: Im_Calculator,
      color: 'bg-orange',
      label: 'Maths',
      progress: 47,
    },
    {
      icon: Im_Science,
      color: 'bg-green',
      label: 'Physiqueee',
      progress: 85,
    },
    {
      icon: Im_Plant,
      color: 'bg-purple',
      label: 'SVT',
      progress: 67,
    },
    {
      icon: Im_Lightbulb,
      color: 'bg-orange',
      label: 'Technique',
      progress: 32,
    },
    // {
    //   icon: Im_Arabic,
    //   color: 'bg-green',
    //   label: 'Arabe',
    //   progress: 90,
    // },
  ],
};
export const calenderTimes = [
  { time: '' },
  { time: '08:00' },
  { time: '09:00' },
  { time: '10:00' },
  { time: '11:00' },
  { time: '12:00' },
  { time: '13:00' },
  { time: '14:00' },
  { time: '15:00' },
  { time: '16:00' },
  { time: '17:00' },
  { time: '18:00' },
  { time: '19:00' },
  { time: '20:00' },
  { time: '21:00' },
  { time: '22:00' },
  { time: '23:00' },
];
export const days = [
  { index: 0 },
  { index: 1 },
  { index: 2 },
  { index: 3 },
  { index: 4 },
  { index: 5 },
  { index: 6 },
  { index: 7 },
];
export const cardDefaultHour = 1.6;

export const calenderMobileWidth = 599;
export const offerCardMaxWidth = 463.25;
export const offerCardMaxHeight = 850;
export const recGeneralRec="Général"
