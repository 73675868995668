import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Link } from 'react-router-dom';
import BlueButton from '../../components/BlueButton';
import ExamButton from './ExamButton';
import PropTypes from 'prop-types';
import * as dayjs from 'dayjs';

const ExamCard = (props) => {
  const { exam } = props;
  const { i18n,t } = useTranslation();
  return (
    <Card className="examCard">
      <CardContent className= {"examCardContent"} >
        <BlueButton text={exam?.subject_exams[0]?.subject?.name} width={100} fontSize={12} />
        <Typography className="examTitle">{exam.content.name}</Typography>
        <div className="examDetails">
          <Typography className="teacherName">{t("Par")} {exam?.content?.instructor?.name+' '+exam?.content?.instructor?.last_name}</Typography>
          <Typography className="examDateExamCard">{t("Date de fin")} : {dayjs(exam?.due_date).format('DD/MM/YYYY HH:mm')}</Typography>
        </div>
        <Link to={`/exams/${exam.id}`} className="textLink">
          <ExamButton text={t("Voir details")} width={225} height={41} fontSize={14} float="right" />
        </Link>
      </CardContent>
    </Card>
  );
};

ExamCard.propTypes = {
  exam: PropTypes.object,
};

export default ExamCard;
