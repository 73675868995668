import React from 'react';
import { useTranslation } from 'react-i18next';
import ViewTitle from '../../components/ViewTitle';
import { SecurityWidget, EditProfileWidget, PubsCards } from '../../components';
import { useSelector } from 'react-redux';

import ImP1 from '../../assets/img/icons/info.svg';
import ImP2 from '../../assets/img/icons/tinder.svg';
import ImP3 from '../../assets/img/icons/gift.svg';
import TxP1 from '../../assets/img/icons/text1.svg';
import TxP2 from '../../assets/img/icons/text2.svg';
import TxP3 from '../../assets/img/icons/text3.svg';

const Index = () => {
  const { t } = useTranslation();
  const { informations } = useSelector((state) => state.user);
  const { permissions } = useSelector((state) => state.user);
  const profileCompleted = informations && informations.completed;
  const hasEditDivision = permissions && permissions?.hasEditDivision;
  const isTest = informations && informations.isTest;
  const admin = localStorage.getItem('admin_user_id');

  return (
    <div className="full-page">
      <ViewTitle title={profileCompleted === 1 ? t('Modifier mon profil') : t('Compléter vos données')} />
      <div className="ta-row">
        <div className="ta-column right-margin layout-profile">
          <div className="child-left">
          <EditProfileWidget hasEditDivision={hasEditDivision} profileCompleted={profileCompleted} admin={admin}/>
          </div>
          <div className="child-right">
            {/* {profileCompleted == true && */}
             <SecurityWidget isTest={isTest} admin={admin} />
             {/* } */}
          </div>
        </div>
        <div className="ta-column fixed right">
          <div style={{ width: '100%', marginTop: '60px' }}></div>
          <PubsCards className="info" icon={ImP1} text={TxP1} modal path={`L36YrGMQGO0`} />
          <PubsCards className="warning" icon={ImP2} text={TxP2} modal path={`ESJGB-lqVMU&ab`} />
          <PubsCards className="success" icon={ImP3} text={TxP3} path={`/offres`} />
        </div>
      </div>
    </div>
  );
};

export default Index;
