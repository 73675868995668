import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getSocialLoginUrls, login, getGeoInfo } from '../../redux/slices/auth';
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import fbIcon from '../../assets/img/icons/facebook2.svg';
import googlePlus from '../../assets/img/icons/google-plus.svg';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import InputCard from '../../components/AuthComponents/InputCard';
import InfoCard from '../../components/AuthComponents/InfoCard';
import { loginValidation } from '../../components/AuthComponents/AuthValidation';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link, useLocation } from 'react-router-dom';
import  {formatMessages}  from '../../utilities/translateHelper';
import TA_Logo from '../../assets/img/TxLogoWhite.svg';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Language from '../../components/Language';

const Index = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [validationResult, setValidationResult] = useState(authResult);
  const [validationStatus, setValidationStatus] = useState(success);
  const [values, setValues] = useState({});
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const recaptchaRef = useRef();
  const { authResult, success, geoIp ,isLoading} = useSelector((state) => state.auth);
  useEffect(() => {
    setValidationResult(authResult);
  }, [authResult]);
  const location=useLocation()
  window.history.pushState( null, null, location.state!==undefined?"?"+location.state:"")
  useEffect(() => {
    setValidationStatus(success);
  }, [success]);
  // useEffect(() => {
  //   dispatch(getSocialLoginUrls());
  // }, [dispatch]);

  // useEffect(() => {
  //   dispatch(getGeoInfo());
  // }, [dispatch]);

  /*useEffect(() => {
     const handleKeyPress = (e) => {
        const {keyCode} = e;
        if( keyCode === 13) {
          handleSubmit(e);
        }
      }
      window.addEventListener('keydown',handleKeyPress);
    return () => {
      window.removeEventListener('keydown',handleKeyPress);
    }
  },);*/

  const handleKeyPress = (e) => {
    const {keyCode} = e;
    if( keyCode === 13) {
      handleSubmit(e);
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validation = loginValidation(values);
    if (validation) {
      setValidationResult(validation.message);
      setValidationStatus(validation.success);
      return setOpenSnackBar(true);
    }
    recaptchaRef.current.execute();
  };
  const handleSocialLogin = (provider) => {
    window.open(`${process.env.REACT_APP_API_URL}/auth/connect/${provider}`, '_self');
  };
  const handleChange = (e) => {
    setValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const onRecaptchaChange = async (value) => {
    let data = values;
    data['g-recaptcha-response'] = value;
    await dispatch(login(data));
    setOpenSnackBar(true);
  };
  return (
    <div className="login-card"
         onKeyDown={handleKeyPress}
         tabIndex="0"
    >
      <div className="centered-box">
        <span className="title">{t("TakiAcademy")}</span>
        <InputCard
          value={values.username}
          handleFunction={handleChange}
          placeholder={
            // (geoIp !== null && geoIp?.country === t('TN')) || !geoIp ?
            // t('Numéro de téléphone')
            // : t('Email')
        t( 'Numéro de téléphone ou email')
          }
          typeInput={'email'}
          icon={
           
              <VpnKeyIcon className="icon-input" />
          
          }
          name="username"
        />
        <InputCard
          value={values.password}
          handleFunction={handleChange}
          placeholder={t('Mot de passe minimum 8 caractères')}
          typeInput={'password'}
          name="password"
          icon={<LockIcon className="icon-input" />}
        />
        <Button
          variant="outlined"
          disableElevation
          onClick={(e) => handleSubmit(e)}
          className="submit-login"
          disabled={isLoading}
        >
          {t("Se connecter")}
        </Button>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={process.env.REACT_APP_RECAPATCHA_KEY}
          size="invisible"
          onChange={onRecaptchaChange}
        />
      <Link to={{pathname:"/create/reset-password" ,state:window.location.href.split("?")[1] }} className="reset-password">
          {t("MOT DE PASSE OUBLIÉ")}
        </Link>
        <div className="language-inscription">
        <span className="inscription">{t("Vous n'avez pas de compte")} <Link to="/register" className="reset-password">
          {t("Inscription")}
        </Link></span>
        <Language WhiteBox className="Language"/>
        </div>
        {/*<p className="text-muted">{t("Ou utilisez votre compte gmail")}</p>
        {
          <div className="social-login">
            <Button
              variant="outlined"
              disableElevation
              onClick={() => handleSocialLogin('google')}
              className="login-google"
            >
              <img src={googlePlus} className="google-icon" />
              Google
            </Button>
          </div>
        }*/}
      </div>

      <InfoCard
        img alt="logo" src={TA_Logo}
        title={t("Bienvenue")}
        text={t("Entrez vos informations personnelles et commencez le voyage avec nous")}
        buttonText={t('Inscription')}
        link="/register"/>

      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'Bottom',
          horizontal: 'center',
        }}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert
          severity={validationStatus ? 'success' : 'error'}
          onClose={() => setOpenSnackBar(false)}
        >
          {t(formatMessages(validationResult))}
        </Alert>
      </Snackbar>
    </div>
  );
};

Index.propTypes = {
  dispatch: PropTypes.func,
  email: PropTypes.string,
  setEmail: PropTypes.func,
  password: PropTypes.string,
  setPassword: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default Index;
