import { createSlice } from '@reduxjs/toolkit';
import api, { headers } from '../../services/api';
import { ord } from '../../utilities/methods';
import Cookies from 'js-cookie';
import axios from 'axios';
import queryString from 'query-string';

const slice = createSlice({
  name: 'user',
  initialState: {
    isLoading: false,
    authResult: null,
    loginSocialLinks: null,
    geoIp: null,
    divisions: null,
    states: null,
    success: false,
    silverOffers: null,
    digitalVergoResult:null,
    statusResend : "idle",
    statusReset : "idle",
  },
  reducers: {
    startLoading: (state, action) => {
      state.isLoading = true;
    },
    loginSuccess: (state, action) => {
      localStorage.setItem('token_type', 'Bearer');
      localStorage.setItem('token', action.payload.payload.token);
      state.authResult = action.payload.message;
      state.isLoading = false;
      state.success = true;
    },
    authError: (state, action) => {
      state.authResult = action.payload;
      state.isLoading = false;
      state.success = false;
      state.error= true;
    },

    logoutSuccess: (state, action) => {
      state.user = null;
      state.informations = null;
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('token_type');
      localStorage.removeItem('expires_at');
    },
    switchSuccess: (state, action) => {
      const query = queryString.parse(window.location.search);
      if (query.token) {
        localStorage.clear();
        localStorage.setItem('token', query.token);
        localStorage.setItem('token_type', 'Bearer');
        localStorage.setItem('admin_user_id', query.adminId);
        localStorage.setItem('user', query.userId);
        window.location = '/';
        state.isLoading = false;
        // window.location.href =  window.location.href.split("switch?")[0]
      }
    },
    socialLoginUrl: (state, action) => {
      state.isLoading = false;
      state.loginSocialLinks = action.payload.data;
    },
    divisionsSuccess: (state, action) => {
      const sorted_divisions = action.payload.data.payload
        .filter((division) => division?.is_public === true)
        .sort((a, b) => (parseInt(a.position) > parseInt(b.position) ? 1 : -1));
      const options = [
        { label: 'id', accessor: 'id' },
        { label: 'is_public', accessor: 'is_public' },
        { label: 'name', accessor: 'name' },
        { label: 'lastName', accessor: 'last_name' },
        { label: 'position', accessor: 'position' },
        { label: 'updated_at', accessor: 'updated_at' },
        { label: 'created_at', accessor: 'created_at' },
      ];
      state.divisions = ord(sorted_divisions, options);
    },
    divisionsByLevelSuccess:(state,action)=>{
      const sorted_divisions = action.payload.data.payload
      .filter((division) => division?.is_public === true)
      .sort((a, b) => (parseInt(a.position) > parseInt(b.position) ? 1 : -1));
     const options = [
       { label: 'id', accessor: 'id' },
       { label: 'is_public', accessor: 'is_public' },
       { label: 'name', accessor: 'name' },
       { label: 'lastName', accessor: 'last_name' },
       { label: 'position', accessor: 'position' },
       { label: 'updated_at', accessor: 'updated_at' },
       { label: 'created_at', accessor: 'created_at' },
     ];
     state.divisions = ord(sorted_divisions, options);
    },
    statesSuccess: (state, action) => {
      state.states = action.payload.data.payload;
      state.isLoading = false;
    },
    geoInfoSuccess: (state, action) => {
      state.geoIp = action.payload;
      Cookies.set('GeoIp', action.payload.country);
      state.isLoading = false;
    },
    registerSuccess: (state, action) => {
      state.authResult = action.payload.message;
      state.loading = false;
      state.success = true;
      state.error = false;


    },
    confirmSuccess: (state, action) => {
      state.authResult = action.payload.message;
      state.loading = false;
      state.success = true;
    },
    resendSuccess: (state, action) => {
      state.authResult = action.payload.message;
      state.success = true;
      state.statusResend = action.payload.code === 200 ?  "succeeded" : "rejected"
      state.isLoading = false;
    },
    resetSuccess: (state, action) => {
      state.authResult = action.payload.message;
      state.success = true;
      state.statusReset = action.payload.code === 200 ?  "succeeded" : "rejected"
      state.isLoading = false;
      state.isLoading=false;

    },
    checkoutDigitalVergoSuccess: (state, action) => {
      state.digitalVergoResult = action.payload.message;
      state.success = true;
      state.isLoading=false;

    },
    digitalVergoError: (state, action) => {
      state.digitalVergoResult = action.payload;
      state.isLoading = false;
      state.success = false;
    },
    silverOfferSuccess:(state,action) => {
      state.silverOffers = action.payload.data.payload;
      state.success = true;
      state.isLoading=false;

    },
    digitalVirgoUnsubscribeSuccess:(state,action) => {
      state.digitalVergoResult = action.payload.message;
      state.success = true;
      state.isLoading=false;

    },
    socialLoginCallbackSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
      Cookies.set('loggedIn', true, { domain: process.env.REACT_APP_COOKIE_DOMAIN, expires: 365 });
      localStorage.setItem('token', action.payload.access_token);
      localStorage.setItem('token_type', action.payload.token_type);
      localStorage.setItem('expires_at', action.payload.expires_at);
      localStorage.setItem('verified', action.payload.verified);
      if (action.payload.facebook_id)
        localStorage.setItem('facebook_id', action.payload.facebook_id);
      if (action.payload.google_id) localStorage.setItem('google_id', action.payload.google_id);
      window.location = '/';
    },
  },
});
const ipInfoToken = process.env.REACT_APP_IP_INFO_TOKEN;
export default slice.reducer;
const {
  loginSuccess,
  logoutSuccess,
  startLoading,
  switchSuccess,
  authError,
  socialLoginUrl,
  divisionsSuccess,
  statesSuccess,
  geoInfoSuccess,
  registerSuccess,
  confirmSuccess,
  resendSuccess,
  resetSuccess,
  socialLoginCallbackSuccess,
  divisionsByLevelSuccess,
  silverOfferSuccess,
  digitalVergoError,
  checkoutDigitalVergoSuccess,
  digitalVirgoUnsubscribeSuccess,
} = slice.actions;

export const login = (data) => async (dispatch) => {
  try {
    const res = await api.post('/auth/login_check', data);
    dispatch(loginSuccess(res.data));
    if (res.data.user_type === 'Teacher') {
      return (window.location = `${process.env.REACT_APP_TEACHER_URL}/auth/login/?access_token=Bearer ${res.data.payload.token}`);
    }
    window.location =
      window.location.search && window.location.search.includes('?nextPage=offres')
        ? '/offres'
        : '/';
  } catch (e) {
    if (e.response) {
      let message = '';
      let key;
      if (e.response.data.errors) {
        for (key in e.response.data.errors) {
          message = e.response.data.errors[key][0];
        }
      } else {
        message = e.response.data.message;
      }
      return dispatch(authError(message));
    }
    //   return dispatch(authError(e.response.data));
    // }
  }
};

export const switchUser = () => async (dispatch) => {
  try {
    //const response = await api.get(`/users/${token}/switch`);
    dispatch(startLoading());
    if (window.location.href.includes('switch')) {
      dispatch(switchSuccess());
    }
  } catch (e) {
    return console.error(e.message);
  }
};
export const logout = () => async (dispatch) => {
  try {
    const res = await api.get('/auth/logout');
    dispatch(logoutSuccess());
    window.location = '/login';
  } catch (e) {
    dispatch(logoutSuccess());
    window.location = '/login';
    return console.error(e.message);
  }
};

export const getSocialLoginUrls = () => (dispatch) => {
  dispatch(startLoading());
  return api
    .get(`/auth/social/urls`)
    .then((response) => {
      dispatch(socialLoginUrl(response.data));
    })
    .catch((error) => {
      throw error;
    });
};
export const socialLoginCallback =
  ({ provider, code }) =>
  (dispatch) =>
    axios
      .get(`${process.env.REACT_APP_API_URL}/auth/social/${provider}/callback?code=${code}`, {
        headers,
      })
      .then((response) => {
        dispatch(socialLoginCallbackSuccess(response.data));
        window.location = '/';
      })
      .catch((error) => {
        return console.error(error.message);
        throw error;
      });
export const getDivisions = () => (dispatch) => {
  dispatch(startLoading());
  return api
    .get(`/divisions`)
    .then((response) => {
      dispatch(divisionsSuccess(response));
    })
    .catch((error) => {
      throw error;
    });
};
export const getDivisionsByLevel = (level) => (dispatch) => {
  dispatch(startLoading());
  return api
    .get(`${level}/divisions`)
    .then((response) => {
      dispatch(divisionsByLevelSuccess(response));
    })
    .catch((error) => {
      throw error;
    });
};

export const getSilverOffer = (level) => (dispatch) => {
  dispatch(startLoading());
  return api
    .get(`offers`)
    .then((response) => {
      dispatch(silverOfferSuccess(response));
    })
    .catch((error) => {
      throw error;
    });
};

export const getStates = () => (dispatch) => {
  dispatch(startLoading());
  return api
    .get(`/countries/1/states`)
    .then((response) => {
      dispatch(statesSuccess(response));
    })
    .catch((error) => {
      throw error;
    });
};

export const getGeoInfo = () => (dispatch) => {
  return axios
    .get(`https://ipinfo.io/json?token=${ipInfoToken}`)
    .then((response) => {
      let data = response.data;
      dispatch(geoInfoSuccess(data));
    })
    .catch((error) => {
      console.log(error);
    });
};

export const register = (data) => (dispatch) => {
  dispatch(startLoading());
  return api
    .post(`auth/register`, data)
    .then((response) => {
      dispatch(registerSuccess(response.data));

      window.location = window.location.search.includes('?nextPage=offres')
        ? '/login' + '?nextPage=offres'
        : '/login';
    })
    .catch((e) => {
      if (e.response) {
        let message = '';
        let key;
        if (e.response.data.errors) {
          if (e.response.data.errors.plainPassword) {
            for (key in e.response.data.errors.plainPassword) {
              message = key.concat(' Password: ', e.response.data.errors.plainPassword[key][0]);
            }
          } else if (e.response.data.errors.birthDate) {
            for (key in e.response.data.errors.birthDate) {
              message = 'Birthdate: '.concat(e.response.data.errors.birthDate[0]);
            }
          } else if (e.response.data.errors.email) {
            for (key in e.response.data.errors.email) {
              message = e.response.data.errors.email[0];
            }
          } else if (e.response.data.errors.phone) {
            for (key in e.response.data.errors.phone) {
              message = 'Phone: '.concat(e.response.data.errors.phone);
            }
          }
        } else {
          message = e.response.data.message;
        }
        return dispatch(authError(message));
      }
    });
};
export const confirmCode = (data) => (dispatch) => {
  return api
    .get(`/auth/confirm-registration?token=${data}`)
    .then((response) => {
      dispatch(confirmSuccess(response.data));
      window.location = document.referrer.includes('?nextPage=offres') ? '/offres' : '/';
    })
    .catch((e) => {
      if (e.response) {
        let message = '';
        let key;
        if (e.response.data.errors) {
          for (key in e.response.data.errors) {
            message = e.response.data.errors[key][0];
          }
        } else {
          message = e.response.data.message;
        }
        return dispatch(authError(message));
      }
      //return dispatch(authError(e.response.data));
    });
};

export const resendConfirmCode = (data) => (dispatch) => {
  return api
    .get(`auth/resend-code`, data)
    .then((response) => {
      dispatch(resendSuccess(response.data));
    })
    .catch((e) => {
      if (e.response) {
        let message = '';
        let key;
        if (e.response.data.errors) {
          for (key in e.response.data.errors) {
            message = e.response.data.errors[key][0];
          }
        } else {
          message = e.response.data.message;
        }
        return dispatch(authError(message));
      }
      //   return dispatch(authError(e.response.data));
      // }
    });
};

export const createResetPasswordCode = (data) => (dispatch) => {
  return api

    .get(`/auth/request-password?credentiel=${data.credential}`, headers)
    .then((response) => {
      dispatch(resendSuccess(response.data));
      window.location =
        data.link !== undefined ? '/reset-password' + '?' + data.link : '/reset-password';
    })
    .catch((e) => {
      if (e.response) {
        let message = '';
        let key;
        if (e.response.data.errors) {
          for (key in e.response.data.errors) {
            message = e.response.data.errors[key][0];
          }
        } else {
          message = e.response.data.message;
        }
        return dispatch(authError(message));
      }
      //   return dispatch(authError(e.response.data));
      // }
    });
};
export const forceCreateResetPasswordCode = (data) => (dispatch) => {
  return api

    .get(`/auth/request-password?credentiel=${data.credential}`, headers)
    .then((response) => {
      dispatch(resendSuccess(response.data));
    })
    .catch((e) => {
      if (e.response) {
        let message = '';
        let key;
        if (e.response.data.errors) {
          for (key in e.response.data.errors) {
            message = e.response.data.errors[key][0];
          }
        } else {
          message = e.response.data.message;
        }
        return dispatch(authError(message));
      }
      //   return dispatch(authError(e.response.data));
      // }
    });
};
export const resetPasswordCode = (data) => (dispatch) => {
  return api
    .post(`/auth/reset-password`, data, headers)
    .then((response) => {
      dispatch(resetSuccess(response.data));
      window.location = '/login';
    })
    .catch((e) => {
      if (e.response) {
        let message = '';
        let key;
        if (e.response.data.errors) {
          for (key in e.response.data.errors) {
            message = e.response.data.errors[key][0];
          }
        } else {
          message = e.response.data.message;
        }
        return dispatch(authError(message));
      }
      //   return dispatch(authError(e.response.data));
      // }
    });
};

export const forceResetPasswordCode = (data) => (dispatch) => {
  return api
    .post(`/auth/reset-password`, data, headers)
    .then((response) => {
      dispatch(resetSuccess(response.data));
    })
    .catch((e) => {
      if (e.response) {
        let message = '';
        let key;
        if (e.response.data.errors) {
          for (key in e.response.data.errors) {
            message = e.response.data.errors[key][0];
          }
        } else {
          message = e.response.data.message;
        }
        return dispatch(authError(message));
      }
      //   return dispatch(authError(e.response.data));
      // }
    });
};


export const digitalVirgoUnsubscribe = () => (dispatch) => {
  return api
    .post(`digitalvirgo/unsubscribe`)
    .then((response) => {
      dispatch(digitalVirgoUnsubscribeSuccess(response.data));

    })
    .catch((e) => {
      if (e.response) {
        let message = '';
        let key;
        if (e.response.data.errors) {
          for (key in e.response.data.errors) {
            message = e.response.data.errors[key][0];
          }
        } else {
          message = e.response.data.message;
        }
        return dispatch(authError(message));
      }
      //   return dispatch(authError(e.response.data));
      // }
    });
};


export const checkoutDigitalVergo = (data) => (dispatch) => {
  dispatch(startLoading());
  return api
    .post(`/checkout`, data)
    .then((response) => {
      dispatch(checkoutDigitalVergoSuccess(response.data));
      window.location = `${response.data.payload.redirectUrl}`;
    })
    .catch((e) => {
      if (e.response) {
        let message = '';
        let key;
        if (e.response.data.errors) {
          for (key in e.response.data.errors) {
            message = e.response.data.errors[key][0];
          }
        } else {
          message = e.response.data.message;
        }
        return dispatch(digitalVergoError(message));
      }
      //   return dispatch(authError(e.response.data));
      // }
    });
};

