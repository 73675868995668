import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ViewTitle from '../../components/ViewTitle';
import { VideoView } from '../../components';
import { useSelector, useDispatch } from 'react-redux';
import { getLessonContents, getRecordingContents } from '../../redux/slices/courses';

const Index = ({ match }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const subjectSlug = match.params.subject_id;
  const chapterSlug = match.params.chapter_id;
  const lessonSlug = match.params.lesson_id;
  const lessonId = lessonSlug.split('-')[0];
  const { courses, isLoading } = useSelector((state) => state.courses);
  useEffect(() => {
      dispatch(getLessonContents(subjectSlug, chapterSlug));
  }, [dispatch]);
  return (
    <div>
      <ViewTitle title={t('Matières')} />
      <div className="ta-row">
        <div className="ta-column video-right-margin">
          <VideoView
            courses={courses}
            isLoading={isLoading}
            subjectSlug={subjectSlug}
            chapterSlug={chapterSlug}
            lessonSlug={lessonSlug}
            lessonId={lessonId}
            chapter={courses?.chapter}
          />
        </div>
      </div>
    </div>
  );
};

export default Index;
