import React, { useRef, useState } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { digitalVirgoUnsubscribe } from '../../redux/slices/auth';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  btn:{
      
      color:'blue',
      display:'flex',
      alignItems:'start',
      padding:'-10'
  }
}));

const UnsubscribeButton = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] =useState(false);
  const anchorRef = useRef(null);

const dispatch=useDispatch()

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleUnsubscribe=()=>{
    dispatch(digitalVirgoUnsubscribe())
    setOpen(false);
  }

  return (
    <div>
      <button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className="unsubscribe-btn"
      >
       
        <MoreVertIcon  style={{width:'25px',height:'25px'}} fontSize='larg'  className='unsubscribe-icon' />
      </button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement= 'right-start' transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'right-start'  }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <MenuItem onClick={handleUnsubscribe}>{t('Se désabonner')}</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default UnsubscribeButton;
