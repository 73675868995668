import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { transferPoints } from '../../../redux/slices/points';
import { useSelector, useDispatch } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { me } from '../../../redux/slices/user';
import {enqueueSnackbar} from '../../../redux/slices/notifier';
import  {formatMessages}  from '../../../utilities/translateHelper';
import jwt_decode from "jwt-decode";

const Index = () => {
  const { i18n , t } = useTranslation();
  const { transfer, error } = useSelector((state) => state.points);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [toUser, setUserId] = useState('');
  const [points, setPoints] = useState();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const successMessage = transfer && transfer.message;
   const token = localStorage.getItem('token');
    const decoded = jwt_decode(token);
    const admin = decoded.hasOwnProperty('adminId') ? decoded?.adminId : null
    useEffect(() => {
    if (successMessage && error === false) {
      dispatch(me());
    }
  }, [successMessage, error]);

  const handleSubmit = () => {
    if (!toUser  || !points) {
    dispatch(
        enqueueSnackbar({
          message: t('Remplir les champs'),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'warning',
          },
        }),
    );
    return;
  }
  if (points < 10 || points > 5000) {
    dispatch(
        enqueueSnackbar({
          message: t('Veuillez entrer un montant entre 10 et 5000'),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'warning',
          },
        }),
    );
    return;
  }
    dispatch(transferPoints(toUser, points, admin));
    setOpenSnackBar(true);
    setOpen(false);
    setUserId('');
    setPoints('');
  };
  
  return (
    <div className={i18n.language === 'ar' ? 'rtl' : 'base'}>
      <TextField
        outlined
        id="userId"
       // label={t("identifiant du bénéficiaire")}
        placeholder={t("identifiant du bénéficiaire")}
        type="string"
        value={toUser}
        margin="dense"
        variant="outlined"
        className="basic-fields"
        style={{ width: 350, height: 40 }}
        onChange={(e) => setUserId(e.target.value)}
      />
      <TextField
        autoFocus
        margin="dense"
        id="point"
        value={points}
        //label={t("montant")}
        placeholder={t("montant")}
        type="number"
        variant="outlined"
        className="basic-fields"
        style={{ width: 350, height: 40 }}
        onChange={(e) => setPoints(e.target.value)}
      />
      <div className="basic">
        <Button
          onClick={(event) => handleSubmit(event)}
          className="buttonAdd"
          style={{ left: 180 }}
        >
          {t("Ajouter des points")}
        </Button>
      </div>
      {
        (successMessage || error) &&
          <Snackbar
          open={openSnackBar}
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: 'Bottom',
            horizontal: 'center',
          }}
          onClose={() => setOpenSnackBar(false)}
          >
          <Alert
            severity={successMessage ? 'success' : 'warning'}
            onClose={() => setOpenSnackBar(false)}
          >
            {successMessage ? t(successMessage) : t(formatMessages(error))}
          </Alert>
          </Snackbar>
      }
     
    </div>
  );
};

export default Index;
