import React from 'react';
import Vector from '../../../assets/img/icons/Vector.png';

const SectionCard = ({ section, offerName, offerImage, priceDescription, text = [], OnClick }) => {
  return (
    <div className="ooredoo-card">
      <div className="offer-section"> {section}</div>
      <div className="offer-card-ooredoo">
        <div className="offer-card-imag">
          <img className="offer-imag" src={offerImage}></img>
          <div className="offer-img-title">{offerName}</div>
          <div className="offer-img-description"> تمتع بعرض سيلفر الآن </div>
        </div>
        <div className="offer-card-price">
          <div className="price-discription">{priceDescription}</div>
        </div>
        <div className="offer-card-description">
          {text.map((item, index) => {
            return (
              <div className="descripton-text" key={index}>
                <span className="text">{item}</span>
                <img className="vector" src={Vector}></img>
              </div>
            );
          })}
        </div>

        <button className="offerCard-btn" onClick={OnClick}>اشترك الآن</button>
      </div>
    </div>
  );
};

export default SectionCard;
