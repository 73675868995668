import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, IconButton } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import ooredoo from '../../../assets/img/ooredoo2.png';

const OoredooItem = (props) => {
  const { id, couponCode, offerId, check, icon, text,color,price,onClick,disabled, ...rest } = props;
  const { t, i18n } = useTranslation();
  const [offerCheck, setOfferCheck] = useState(false);
  const dispatch = useDispatch();

  
  return (
    <div className="Ooredoo-box">
     <div className="ooredoo-icon" >
        <img className='ooredooImg' src={ooredoo}/>
        <span className= {'ooreedoo-price'}>{price}</span>
     </div>
     
      <div className='text-ooreedoo'>{text}</div>
      <Button disabled={false} onClick={onClick} className='acheter-mini'>{'Acheter'}</Button>
    </div>
  );
};

export default OoredooItem;
