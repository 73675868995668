import {
  Button,
  Card,
  CardContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BenefitsHistoryModal, ViewTitle } from '../../components';
import { useSelector, useDispatch } from 'react-redux';
import { getBenefitsHistory, getInfluencerBenefits } from '../../redux/slices/points';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import dayjs from 'dayjs';
import { makeStyles } from '@material-ui/core/styles';
import HistoryIcon from '@material-ui/icons/History';

const Index = () => {
  const [open, setOpen] = useState(false);

  const useStyles = makeStyles({
    MuiTableCell_stickyHeader: {
      backgroundColor: '#ebebf3',
      borderBottom: '1px solid #d6d6da',
      padding: '2px 6px',
      paddingBottom: '12px',
      color: '#ababb0',
      fontSize: '18px',
      fontWeight: 400,
      textAlign: 'left',
    },
    tab_row: {
      color: '#fffff',
      fontSize: '16px !important',
      padding: '8px',
      '&:hover': {
        color: '#212121',
      },
    },
    tab_row_rtl: {
      direction: 'rtl',
      textAlign: 'right',
    },
    MuiTableCell_stickyHeader_rtl: {
      backgroundColor: '#ebebf3',
      borderBottom: '1px solid #d6d6da',
      padding: '2px 6px',
      paddingBottom: '12px',
      color: '#ababb0',
      fontSize: '18px',
      fontWeight: 400,
      direction: 'rtl',
      textAlign: 'right',
    },
  });

  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { benefitSuccess } = useSelector((state) => state.points);
  const headers = [
    { accessor: 'code', label: t('Code Coupon') },
    { accessor: 'createdAt', label: t('Créé le') },
    { accessor: 'expiredAt', label: t('Expiré le') },
    { accessor: 'usedBy', label: t('Utilisé par') },
    { accessor: 'statut', label: t('Statut'), type: '' },
    { accessor: 'solde', label: t('Solde') },
    { accessor: 'Payment_history', label: t('Historique de paiement') },
  ];
  const handleBenefitHistory = (id) => {
    dispatch(getBenefitsHistory(id));
    setOpen(true);
  };

  useEffect(() => {
    dispatch(getInfluencerBenefits());
  }, []);

  return (
    <div className="full-page revenue-view">
      <div className="ta-row">
        <ViewTitle title={t('Revenu')} />
        <Card className="ta-card">
          <CardContent>
            <TableContainer className="table-container">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {headers &&
                      headers.length > 0 &&
                      headers.map((col, index) => {
                        return (
                          <TableCell
                            className={
                              i18n.language === 'ar'
                                ? classes.MuiTableCell_stickyHeader_rtl
                                : classes.MuiTableCell_stickyHeader
                            }
                            key={index}
                          >
                            {col.label}
                          </TableCell>
                        );
                      })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {benefitSuccess ? (
                    benefitSuccess
                      .slice()
                      .sort((a, b) =>
                        a?.discountCode?.expired_at < b?.discountCode?.expired_at ? 1 : -1
                      )
                      .map((row, index) => {
                        const newRow = {
                          code: (
                            <div className="icon-with-text">
                              <FiberManualRecordIcon
                                className={row.discountCode.active ? ' success' : ' danger'}
                              />
                              {row.discountCode.code}
                            </div>
                          ),
                          createdAt: dayjs(row.discountCode.created_at).format('DD/MM/YYYY'),
                          expiredAt: dayjs(row.discountCode.expired_at).format('DD/MM/YYYY'),
                          usedBy: row.discountCode.nb_of_use,
                          statut: row.discountCode.active ? t('Activé') : t('Désactivé'),
                          solde: `${row.totalAmount} ${t('Pts')}`,
                          Payment_history: (
                            <div className="icon-info">
                              <IconButton onClick={() => handleBenefitHistory(row.discountCode.id)}>
                                <HistoryIcon className="payment-history-icon" />
                              </IconButton>
                            </div>
                          ),
                        };
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                            {headers &&
                              headers.length > 0 &&
                              headers.map((col, index) => {
                                return (
                                  <TableCell
                                    className={
                                      i18n.language === 'ar' ? classes.tab_row_rtl : classes.tab_row
                                    }
                                    key={index}
                                    align="left"
                                  >
                                    {newRow[col.accessor]}
                                  </TableCell>
                                );
                              })}
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell
                        className={i18n.language === 'ar' ? classes.tab_row_rtl : classes.tab_row}
                        colSpan={headers && headers.length}
                        style={{ textAlign: 'center' }}
                      >
                        <span style={{ color: '#a2a2a7' }}>{t('Aucun code coupon')}</span>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
        <BenefitsHistoryModal open={open} setOpen={setOpen} />
      </div>
    </div>
  );
};
export default Index;
