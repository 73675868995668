import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Im_Logo from '../../assets/img/logo.svg';
//import Flag_Logo from '../../assets/img/palestine.gif';
import Ramadhan_Logo from '../../assets/img/ramadhan.gif';
import imLogo from '../../assets/img/logo.svg';
//import Flag_Logo from '../../assets/img/palestine.gif';

const Index = ({ handleDrawerToggle, mobileOpen }) => {
  const { t, i18n } = useTranslation();
  return (
    <Link
      to="/"
      className={i18n.language === 'ar' ? 'logo rtl' : 'logo'}
      onClick={() => {
        if (mobileOpen !== true) {
          return;
        }
        handleDrawerToggle();
      }}
    >
    <img alt="logo" src={Im_Logo} />
      <span>{t('TakiAcademy')}</span>
            {/* <img alt="logo" src={Flag_Logo} /> */}
        {/* <img alt="logo" src={Ramadhan_Logo} /> */}

    </Link>
  );
};

export default Index;
