import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Divider from '@material-ui/core/Divider';
import Im_Wallet from '../../assets/img/icons/Wallet-img.svg';

const Index = ({ points, external }) => {
  const { t, i18n } = useTranslation();
  return (
    <div className="wallet-balance">
      <span className={!!!external ? 'label' : 'label muted'}>
        {t("votre solde actuel")} :
      </span>
      <Divider variant="middle" />
      {(points !== 1 && points <= 10 ) ? (
        <span className="points">{points} {t("Pts")}</span> ): (  
        <span className="points">{points} {t("PtsAr")}</span> )}
      <img alt="wallet-icon" src={Im_Wallet} className={!!external && 'mini-img'} />
    </div>
  );
};

Index.propTypes = {
  points: PropTypes.string,
  external: PropTypes.bool,
};

export default Index;
