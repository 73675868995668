import * as dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import ImAvatar from '../assets/img/icons/avatar.png';
import ImAvatarMale from '../assets/img/icons/avatar-male.png';
import ImAvatarFemale from '../assets/img/icons/avatar-female.png';

dayjs.extend(customParseFormat);

// Get percentage period between two dates
export const ptr = (start_date, end_date) => {
  const today = dayjs();
  const endDate = dayjs(end_date).format('DD/MM/YYYY');
  const startDate = dayjs(start_date).format('DD/MM/YYYY');
  const total_days = dayjs(endDate, 'DD/MM/YYYY').diff(dayjs(startDate, 'DD/MM/YYYY'), 'day');
  const still = dayjs(endDate, 'DD/MM/YYYY').diff(today, 'day');
  const passed = total_days - still;
  return Math.round((passed * 100) / total_days); // Number
};

// Get data from nested structure
export const nds = (item, req, sep) => {
  let separator = sep || '.';

  let accessors = req.split(separator);
 
  let res = item;
  //console.log('item1', accessors2)
  
  accessors.forEach((accessor,index) =>(res = res[accessor] ?? ''));
  return res;

};

// Get only required data from array
export const ord = (obj, options) => {
  if (Array.isArray(obj)) {
    let converted_data = [];
    return (
      obj &&
      obj.length > 0 &&
      obj.map((item) => {
        options.map((option) => {
          converted_data = {
            ...converted_data,
            [option.label]: nds(item, option.accessor) === '' ? null : nds(item, option.accessor),
          };
        });
        return converted_data;
      })
    ); // Array
  } else {
    let converted_data = {};
    options &&
      options.length > 0 &&
      options.map((option) => {
        converted_data = {
          ...converted_data,
          [option.label]: nds(obj, option.accessor) === '' ? null : nds(obj, option.accessor),
        };
      });
    return converted_data;
  }
};

// Get avatar by gender
export const avatarByGender = (avatar, gender) => {
  if (avatar !== null) {
    return avatar;
  }
  if (gender === 'male') {
    return ImAvatarMale;
  }
  if (gender === 'female') {
    return ImAvatarFemale;
  }
  return ImAvatar;
};

// Slice Long Strings
export const sliceLongStrings = (string, nbr_char, sep) => {
  if (!string || !nbr_char) {
    return string ? string : '';
  }
  if (string && string !== '') {
    if (string.length > 0 && string.length > nbr_char) {
      return `${string.slice(0, nbr_char)} ${sep || '...'}`;
    }
  }
  return string;
};

export const DivisionsReformat = (division) => {
  let res = division || '';
  if (division) {
    if (parseInt(division[0]) >= 1 && parseInt(division[0]) <= 9) {
      if (division.includes('ère') || division.includes('ème')) {
        res = `${division[0]}<sup>${
          division.includes('ère') ? 'ère' : 'ème'
        }</sup>${division.substr(5)}`;
      }
    }
  }
  return res;
};


