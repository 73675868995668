import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { forceCreateResetPasswordCode } from '../../../redux/slices/auth';
import Button from '@material-ui/core/Button';
import cadnat from '../../../assets/img/icons/cadnat.svg';
import EmailIcon from '@material-ui/icons/Email';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import InputCard from '../../../components/AuthComponents/InputCard';
import ReCAPTCHA from 'react-google-recaptcha';
import { useLocation } from 'react-router-dom';
import { ResetForm } from '../..';
import WindowDimensions from '../../../utilities/windowDimenssions';


const CreateResetCode = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {width} = WindowDimensions();
  const maxWidth = width >= 750 ? 750 : width - 50;
  const { authResult, success, statusResend } = useSelector((state) => state.auth);
  const { informations } = useSelector((state) => state.user);

  const [createResetTrue, setCreateResetTrue] = useState(false);

const location=useLocation()
window.history.pushState( null, null,location.state!==undefined?"?"+location.state:"")

  useEffect(() => {
    setValidationResult(authResult);
  }, [authResult]);
  useEffect(() => {
    setValidationStatus(success);
  }, [success]);
  useEffect(() => {
    if(statusResend === "succeeded")
    setCreateResetTrue(true);
    }, [statusResend]);

  const [validationResult, setValidationResult] = useState(authResult);
  const [credential, setCredential] = useState();
  const [validationStatus, setValidationStatus] = useState(success);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const recaptchaRef = useRef();

  const handleSubmitChangePassword = async (e) => {
   window.location = 'update-password';
  };

  const handleSubmit = async (e) => {
    setCredential(informations.phone ? informations.phone : informations.email)
    recaptchaRef.current.execute();
  };
  const onRecaptchaChange = async (value) => {
    await dispatch(
      forceCreateResetPasswordCode({ 'credential': credential , 'g-recaptcha-response': value ,link:location.state })
    )
    ;
    setOpenSnackBar(true);
  };
  return (
    <div className="login-card" style={{ margin:'30px 0px'}}>
      {createResetTrue ? (
        <ResetForm /> ):(
      <div className="centered-box" style={{ minHeight: 500 }}>
              <img src={cadnat}
                   width={170}
                   style={{margin: 10}}/>
            <span className="title-warning">{t("Warning")}</span>
            <span className="text-warning">{t("Your password expired For security reasons we force a password change from time to time Please change it below Thanks")}</span>
        <InputCard
          disabled
          value={informations.phone ? informations.phone : informations.email}
      //    handleFunction={(event) => setCredential(event.target.value)}
          placeholder={t('Numéro de téléphone e-mail ou nom d utilisateur')}
          icon={<EmailIcon className="icon-input" />}
          typeInput={'text'}
          name="credential"
        />
        <Button
          style={{width:width<750&&'100%'}}
          variant="outlined"
          disableElevation
          onClick={(e) => handleSubmit(e)}
          className="submit-reset"
        >
          {t('Réinitialiser le mot de passe')}
        </Button>
        <Button
          style={{width:width<750&&'100%'}}
          variant="outlined"
          disableElevation
          onClick={(e) => handleSubmitChangePassword(e)}
          className="submit-reset-alt"
        >
          {t('Changer le mot de passe')}
        </Button>
      </div> )}
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={process.env.REACT_APP_RECAPATCHA_KEY}
        size="invisible"
        onChange={onRecaptchaChange}
      />
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'Bottom',
          horizontal: 'center',
        }}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert
          severity={validationStatus ? 'success' : 'error'}
          onClose={() => setOpenSnackBar(false)}
        >
          {t(validationResult)}
        </Alert>
      </Snackbar> 
    </div>
  );
};

CreateResetCode.propTypes = {
  dispatch: PropTypes.func,
  email: PropTypes.string,
  setEmail: PropTypes.func,
  password: PropTypes.string,
  setPassword: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default CreateResetCode;