import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import user from '../../../assets/img/icons/user.svg';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import Pdf from '../../../assets/img/icons/pdf.svg';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import * as dayjs from 'dayjs';
import {extractHoursAndMinutes} from '../../../utilities/translateHelper';

const Index = (props) => {
  const { t } = useTranslation();
  const { lesson, coursesNumber, magNumber, duration } = props;
  const [isTrialTrue, setIsTrialTrue] = useState(false);
  const { informations} = useSelector((state) => state.user);

  const attachments = lesson?.attachments;
  const locked = lesson?.locked;
  
  const isTrial = () => {
    if(informations?.offers.length == 1 && (informations?.offers[0]?.group?.id === 84 ||informations?.offers[0]?.group?.id === 83)){
      setIsTrialTrue(true)    
    }
    else {
      setIsTrialTrue(false) 
    }
  }

  useEffect(() => {
    isTrial();
  },[]);

  const translateDuration = (duration) => {
    const durationArr = extractHoursAndMinutes(duration);
      if(durationArr){
       return durationArr[0] + t(durationArr[1]) + durationArr[2] + t(durationArr[3]);
      }
  };

  return (
    <div className="video-information">
      <Grid container spacing={2} className="container">
        <Grid item xs className="box">
          <Typography className="title">{t("a propos de l instructeur")}</Typography>
          <br />
          <div className="detailsTeacher">
            <Avatar alt="instructeur" src={user} className="avatar" />
            <div className="details">
              <Typography variant="h5" component="h2" className="instructorName">
                {lesson ?  (lesson?.content?.instructor?.last_name)?.charAt(0) +'.'+ lesson?.content?.instructor?.name: t('Prof à TakiAcademy')}
                <br />
                <Typography variant="h5" component="h2" className="description">
                  {lesson ? t(lesson?.content?.instructor?.description) : t('Prof à TakiAcademy')}
                </Typography>
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs className="box">
          <Typography className="title">{t("a propos du Chapitre")}</Typography>
          <br />
          <Typography variant="h5" component="h2" className="description">
            {t("Durée")}: {translateDuration(duration)} <br />
            {t("Vue générale")}: {coursesNumber} {t("cours")} - {magNumber} {t("Magazines")}
          </Typography>
          {lesson?.content?.content_videos[0]?.event?.start_date &&
          <Typography variant="h5" component="h2" className="description">
             {t("Date de la séance")} : {dayjs(lesson.content?.content_videos[0]?.event?.start_date).format('DD/MM/YYYY HH:mm')} <br/>
          </Typography>
          }
        </Grid>
        <Grid item xs className="box">
          <Typography className="title">{t("Pièces jointes")}</Typography>
          <br />
          {attachments && attachments.length > 0 && locked === false && !isTrialTrue ? (
            <div>
              {attachments.map((attachement) => {
                return (
                  <div>
                    <ButtonGroup className="group">
                      <Tooltip title={attachement.name} aria-label="add">
                        <Button className="buttonUpload">
                          <Icon>
                            <img src={Pdf} />
                          </Icon>
                          <Typography className="textUpload">{attachement.name}</Typography>
                        </Button>
                      </Tooltip>
                      <Button
                        className="buttonDownload"
                        component={'a'}
                        startIcon={<GetAppRoundedIcon className="iconDownload" />}
                        href={attachement.url}
                        target="_blank"
                        download={attachement.url}
                      >
                        {t("Télécharger")}
                      </Button>
                    </ButtonGroup>
                  </div>
                );
              })}
            </div>
          ) : (
            <p>{t("Aucune pièce jointe dans cette lecon")}</p>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Index;
