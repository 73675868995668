import React, { useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import * as dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { convertSecondDuration } from '../../utilities/timeMethods';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import history from '../../assets/img/icons/history.png';
import { Empty } from '../../components';

const TransactionsResponsiveTable = ({
  cols,
  rows,
  bookmark,
  emptyComponent,
  setRows,
  isSessionTable,
  sort,
  handleSort,
  sortName,
  rowsCount,
}) => {
  const { t } = useTranslation();
  const { informations } = useSelector((state) => state.user);
  const respoContEl = useRef(null);
  const tableContEl = useRef(null);
  const paginContEl = useRef(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [tableContHeight, setTableContHeight] = useState(0);
  const [paginContHeight, setPaginContHeight] = useState(0);
  const userId = informations && informations.id;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRows(+event.target.value);
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const updateTableDemonsions = (taH, PaH) => {
    respoContEl.current.style.height = `${taH + PaH - 16}px`;
    paginContEl.current.style.marginTop = `${taH}px`;
  };

  useLayoutEffect(() => {
    setTableContHeight(tableContEl.current.clientHeight);
    setPaginContHeight(paginContEl.current.clientHeight);
    updateTableDemonsions(tableContHeight, paginContHeight);
  });

  return (
    <div className="table-responsive" ref={respoContEl}>
      <TableContainer className="table-container" ref={tableContEl} component={Paper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {cols &&
                cols.map((header, index) => {
                  return (
                    <TableCell key={index + 1}>
                      {isSessionTable ? (
                        <TableSortLabel
                          active={sortName === header.accessor}
                          direction={sort}
                          onClick={() => handleSort(header.accessor, sort)}
                        >
                          {header.label}
                        </TableSortLabel>
                      ) : (
                        header.label
                      )}
                    </TableCell>
                  );
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows && rows.length > 0 ? (
              rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {cols &&
                      cols.length > 0 &&
                      cols.map((col, index) => {
                        return col.accessor === 'status' ? (
                          <TableCell key="5">
                            <div>
                              {parseInt(row.status) === userId ? t('Transféré') : t('Reçu')}
                            </div>
                          </TableCell>
                        ) : (
                          <TableCell key={index}>
                            <div>
                              {col.type && col.type === 'date'
                                ? dayjs(row[col.accessor]).format('DD/MM/YYYY HH:mm')
                                : col.type === 'time'
                                ? convertSecondDuration(row[col.accessor])
                                : col.accessor === 'sender_id' && row['sender_id'] == null
                                ? row['sender_parent_id']
                                : col.accessor === 'sender_name' && row['sender_name'] == null
                                ? row['sender_parent_name']
                                : row[col.accessor]}
                            </div>
                          </TableCell>
                        );
                      })}
                  </TableRow>
                );
              })
            ) : (
              <TableRow hover role="checkbox" tabIndex={-1}>
                <TableCell colSpan={(cols && cols.length) || 1} align={'center'}>
                  {
                    (emptyComponent = (
                      <Empty label={t('pas d historique')} icon={history} className="no-borders" />
                    ))
                  }
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        ref={paginContEl}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rowsCount || rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage={t('Rows per page')}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('of')}  ${count}`}
      />
    </div>
  );
};

TransactionsResponsiveTable.propTypes = {
  page: PropTypes.number,
  setPage: PropTypes.func,
  rowsPerPage: PropTypes.number,
  setRowsPerPage: PropTypes.func,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
};

export default TransactionsResponsiveTable;
