import React from 'react';
import {
  Assistance,
  Bookmark,
  Confirm,
  Courses,
  Recordings,
  CreateResetPassword,
  EditProfile,
  Events,
  ExamDetails,
  Exams,
  Home,
  Inbox,
  Login,
  Offers,
  Profile,
  Register,
  ResetPassword, SocialLogin,
  Subjects,
  SubscriptionHistory,
  Wallet,
  Revenue,
  NotFound,
  UpdatePassword,
  DigitalVergo
} from '../pages';
import subjectDetail from '../pages/Subjects/subjectDetail';
import {Route, Switch} from 'react-router-dom';
import SwitchUser from '../components/SwitchUser/SwitchUser';
import {AuthRoute, PrivateRoute} from './RoutesMiddleware';

const Routes = () => {
  return (
      <Switch>
        <Route exact path="switch" component={SwitchUser}/>
        <PrivateRoute exact path="/" component={Home}/>
        <PrivateRoute exact path="/mon-profil" component={Profile}/>
        <PrivateRoute exact path="/mon-profil/edit" component={EditProfile}/>
        <PrivateRoute exact path="/offres" component={Offers}/>
        <PrivateRoute exact path="/subjects" component={Subjects}/>
        <PrivateRoute exact path="/courses" component={Courses}/>
        <PrivateRoute exact path="/subjects/:subject_id/details"
                      component={subjectDetail}/>
        <PrivateRoute
            exact
            path="/subjects/:subject_id/chapters/:chapter_id/lessons/:lesson_id/content"
            component={Courses}
        />
         <PrivateRoute
            exact
            path="/subjects/:subject_id/chapters/:chapter_id/recordings/:lesson_id/content"
            component={Recordings}
        />
        <PrivateRoute exact path="/exams" component={Exams}/>
        <PrivateRoute exact path="/exams/:id" component={ExamDetails}/>
        <PrivateRoute exact path="/sessions" component={Events}/>
        <PrivateRoute exact path="/porte-monnaie" component={Wallet}/>
        <PrivateRoute exact path="/liste-de-favoris" component={Bookmark}/>
        <PrivateRoute exact path="/historique"
                      component={SubscriptionHistory}/>
        <PrivateRoute exact path="/assistance" component={Assistance}/>
        <PrivateRoute exact path="/revenu" component={Revenue}/>
        <PrivateRoute exact path="/inbox/:id" component={Inbox}/>
        <PrivateRoute exact path="/confirm" component={Confirm}/>
        <PrivateRoute exact path="/update-password" component={UpdatePassword}/>
        <AuthRoute exact path="/login" component={Login}/>

        <AuthRoute exact path="/offers/ooredoo" component={DigitalVergo}/>

        <AuthRoute exact path="/register" component={Register}/>
        <AuthRoute exact path="/create/reset-password"
                   component={CreateResetPassword}/>
        <AuthRoute exact path="/reset-password" component={ResetPassword}/>
        <AuthRoute
            exact
            path="/login/:provider/callback"
            component={SocialLogin}
        />
        <PrivateRoute path="*" component={NotFound}/>
        {/* <PrivateRoute
        exact
        path="/"
        component={Home}
        allowedAuthorities={['canAccessHomePage']}
        authorityKey="permissions"
        // roles example :
        // allowedAuthorities={['student']}
        // authorityKey="roles"
      /> */}
      </Switch>
  );
};

export default Routes;
