import React from 'react';
import PropTypes from 'prop-types';
import LockIcon from '@material-ui/icons/Lock';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

SelectCard.propTypes = {};

function SelectCard({ handleFunction, placeholder, icon, options, name }) {
  return (
    <div className="select-input-card">
      {icon}
      <Autocomplete
        underlineShow={false}
        id="combo-box-demo"
        className="register-select"
        options={options}
        onChange={(e, newValue) => handleFunction(e, newValue, name)}
        getOptionLabel={(option) => option.name}
        style={{ width: 300 }}
        renderInput={(params) => (
          <TextField
            className="input"
            {...params}
            placeholder={placeholder}
            InputProps={{ ...params.InputProps, disableUnderline: true }}
          />
        )}
      />
    </div>
  );
}

export default SelectCard;
