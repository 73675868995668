import { createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';
import { me } from './user';
import { getJoinResult } from './sessions';
// import { examsSuccess } from './exams';

const slice = createSlice({
  name: 'offers',
  initialState: {
    offers: [],
    isLoading: true,
    error: false,
    buyResult: '',
    redirectData:{
      loading:false,
      error:'',
      data:''
    }
  },
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.loadingId = null;
    },
    offersSuccess: (state, action) => {
      state.offers = action.payload;
      state.isLoading = false;
    },
    loadingBuyOffer: (state, action) => {
      state.loadingId = action.payload;
    },
    buySuccess: (state, action) => {
      state.loadingId = null;
      state.isLoading = true;
      state.buyResult = action.payload;
      state.error = '';
    },
    RedirectLoading:(state, action) => {
      state.redirectData.loading = true;
      state.error = false;
    },
    RedirectSuccess:(state, action) => {
      state.redirectData.loading = null;
      state.redirectData.error = false;
      state.redirectData.data = action.payload;
      
    },
    RedirectEchec:(state, action) => {
      state.redirectData.loading = null;
      state.redirectData.error = true;
      state.redirectData.data = action.payload;
  }}
});
export const { startLoading, hasError, offersSuccess, buySuccess, loadingBuyOffer,RedirectLoading,RedirectEchec,RedirectSuccess } = slice.actions;

export default slice.reducer;

export const getOffers = () => async (dispatch) => {
  dispatch(startLoading());
  try {
    await api.get('student/offers').then((response) => dispatch(offersSuccess(response.data?.payload)));
  } catch (e) {
    if(e?.response?.status === 401){
      localStorage.clear();
      window.location = '/login';
    }
    return dispatch(hasError(e.message));
  }
};
export const buyOffer = (offerDivisionDetail, quantity, chapter, content_id,offer_subject_details=null) => async (
  dispatch
) => {
  dispatch(loadingBuyOffer(offerDivisionDetail));
  try {
    quantity = quantity === 'Par mois' ? '6' : quantity;
    await api
      .post('student/checkout/buy', { offerDivisionDetail, quantity, chapter, content_id, offer_subject_details })
      .then((response) => {
        
        dispatch(buySuccess(response.data));
        dispatch(me());
        window.location='/subjects'
    
        if (chapter) {
          dispatch(getJoinResult(0, 0, response.data));
        }
      });
  } catch (e) {
    if (e.response) {
      dispatch(hasError(e.response.data));
      if(e.response.status === 402 || e.response.status === 401) {
        window.location = '/porte-monnaie';
      }
    }
  }
};

export const digitalvirgoRedirect = (recurrence) => async (dispatch) => {
  dispatch(RedirectSuccess());
  try {
    await api
      .post(`digitalvirgo/checkout`, {recurrence})
      .then((response) => {
        dispatch(RedirectSuccess(response.data));
 window.location=  response.data.payload.redirectUrl
      });
  } catch (e) {
    if (e.response) {
      let message = '';
      let key;
      if (e.response.data.errors) {
        for (key in e.response.data.errors) {
          message = e.response.data.errors[key][0];
        }
      } else {
        message = e.response.data.message;
      }
      return console.log('errorr');
    }
  }
};


export const digitalVirgoSuccess = (query) => async (dispatch) => {
 dispatch(RedirectLoading());
  try {
    
    await api.get(`digitalvirgo/success${query}&checkFromFront=checkFromFront`)
      .then((response) => {
        dispatch(RedirectSuccess(response.data));
        window.location = '/';
      });
  } catch (e) {
    if (e.response) {
      dispatch(hasError(e.response.data));
      let message = '';
      let key;
      if (e.response.data.errors) {
        for (key in e.response.data.errors) {
          message = e.response.data.errors[key][0];
        }
      } else {
        message = e.response.data.message;
      }
      return console.log('errorq');
    }
  }
};


export const digitalVirgoEchec = (query) => async (dispatch) => {
  try {
    
    await api
      .post(`/digitalvirgo/echec${query}`)
      .then((response) => {
        dispatch(RedirectEchec(response.data));
        //dispatch(clickSuccess(response.message));
       
        window.location = '/';
      })
  } catch (e) {
    return console.log(e.message);
  }
}
