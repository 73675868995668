import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const OfferCardNewPeriod = (props) => {
  const { i18n,t } = useTranslation();
  const { color, setPeriod, setPricingIndex, selectedPeriod ,classCard, offerLength, isNewPeriod, } = props;

  const newPeriods = [
    { label: "Par jour", value: "daily" },
    { label: "Par mois", value: "monthly" },
    { label: "reste de l'année", value: "reste" },
  ]

  const normalPeriods = [
    { label: "Par mois", value: "monthly" },
    { label: "reste de l'année", value: "reste" },
  ]

  return (
    <div className={`offer-periods ${classCard}`}>
      {newPeriods && isNewPeriod ? (
        newPeriods.map((period, index) => {
          return (
            <div
                onClick={() => {
                  setPeriod(period.label);
                  setPricingIndex(period.label ==="reste de l'année" ? offerLength  : "");
                }}
              className={
                selectedPeriod === period.label
                ? period.label
                ? 'period selected-period'
                : 'last-period selected-period'
              : period.label
              ? 'period'
              : 'last-period'
              }
              style={{
                backgroundColor:
                  selectedPeriod === period.label || selectedPeriod === period.label
                    ? color
                    : null,
                borderColor: color,
                width: 70
              }}
            >
              {(period.label)  === "reste de l'année" ? t("reste de l année") :t(period.label)} {   
              //!period.quantity 
              !isNaN 
              (period) && <div>
                {period }
                  {/* { ((i18n.language === 'ar' || 'en') && (period)>=3) ? t("trois ou six mois"): t("mois")} */}
                </div>}
            </div>
          );
        }) ):(
          normalPeriods.map((period, index) => {
            return (
              <div
                  onClick={() => {
                    setPeriod(period.label);
                    setPricingIndex(period.label ==="reste de l'année" ? offerLength : "");
                  }}
                className={
                  selectedPeriod === period.label
                  ? period.label
                  ? 'period selected-period'
                  : 'last-period selected-period'
                : period.label
                ? 'period'
                : 'last-period'
                }
                style={{
                  backgroundColor:
                    selectedPeriod === period.label || selectedPeriod === period.label
                      ? color
                      : null,
                  borderColor: color,
                  width: 70
                }}
              >
                {(period.label)  === "reste de l'année" ? t("reste de l année") :t(period.label)} {   
                //!period.quantity 
                !isNaN 
                (period) && <div>
                  {period }
                    {/* { ((i18n.language === 'ar' || 'en') && (period)>=3) ? t("trois ou six mois"): t("mois")} */}
                  </div>}
              </div>
            );
          })
        ) } 
    </div>
  );
};

OfferCardNewPeriod.propTypes = {
  color: PropTypes.string,
  newPeriods: PropTypes.any,
  setPeriod: PropTypes.func,
  setPricingIndex: PropTypes.func,
  selectedPeriod: PropTypes.any,
};

export default OfferCardNewPeriod;
