import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import transferIcon from '../../assets/img/icons/transferIcon.png';
import Modal from '../../components/Modal/Modal';
import { TransferPointsModal} from '..';

const Index = () => {
  const { i18n , t } = useTranslation();
  const [open, openModal] = useState(false);
  const openTransferModal = () => {
    openModal(true);
  };
  const onCloseModal = () => {
    openModal(false);
  };
  return (
    <>
     
      <div className='transferLink' onClick={openTransferModal}>
      <span className='transferDescription'>{t('Vous pouvez transférer des points à un autre compte')}: </span>
        <Link className='link' to='#'>{t('Transférer')}</Link>
      </div>
      <Modal
        onCloseModal={onCloseModal}
        component={TransferPointsModal}
        openModal={open}
        title={t('Transférer des points')}
        modalWidth={'xs'}
      />
    </>
    
  );
};

Index.propTypes = {
  open: PropTypes.bool,
  openModal: PropTypes.func,
  openTransferModal: PropTypes.func,
  onCloseModal: PropTypes.func,
};

export default Index;
