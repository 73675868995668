import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Modal from '../Modal/Modal';
import { VideoModal, CouponModal } from '../../components';

const Index = ({ className, icon, text, modal, path, isDicountModal, bg }) => {
  const [src, setSrc] = useState('');
  const [open, openModal] = useState(false);
  const [openDiscount, setOpenDiscount] = useState(false);
  const openIframe = () => {
    if (isDicountModal) {
      setOpenDiscount(true);
    } else {
      openModal(true);
      setSrc(
        `https://www.youtube.com/embed/${path}?version=3&loop=1&playlist=${path}&rel=0?modestbranding=1&playsinline=1&iv_load_policy=3`
      );
    }
  };
  const onCloseModal = () => {
    openModal(false);
    setSrc('');
  };
  function renderCard() {
    return (
      <div className={`pubs-cards${className ? ' ' + className : ''}`}>
        <img src={icon} alt="img" className="img" />
        <img src={text} alt="text" className="text" />
        <img src={bg || icon} alt="bg" className="bg" />
      </div>
    );
  }
  return (
    <>
      {!!modal ? (
        <div className="pubs-main" onClick={openIframe}>
          {renderCard()}
        </div>
      ) : (
        <div className="pubs-main">
          <Link to={path}>{renderCard()}</Link>
        </div>
      )}
      <Modal
        onCloseModal={onCloseModal}
        component={VideoModal}
        className="youtube-video-modal"
        data={src}
        openModal={open}
        modalWidth={'sm'}
      />
    </>
  );
};

Index.propTypes = {};

export default Index;
