import { createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';
import { ord } from '../../utilities/methods';

// Slice
const slice = createSlice({
  name: 'userHistory',
  initialState: {
    bookmarkList: [],
    subscriptionList: [],
    editDivisionList: [],
    isBookmarkLoading: false,
    isSubscriptionLoading: false,
    isEditDivisionLoading: false,
    bookmarkError: false,
    subscriptionError: false,
    editDivisionError: false,

  },
  reducers: {
    startBookmarkLoading: (state) => {
      state.isBookmarkLoading = true;
    },
    hasBookmarkError: (state, action) => {
      state.bookmarkError = action.payload;
      state.isBookmarkLoading = false;
    },
    bookmarkListSuccess: (state, action) => {
      const options = [
        { label: 'id', accessor: 'id' },
        { label: 'name', accessor: 'content.name' },
        { label: 'subject_name', accessor: 'chapter.subject.name' },
        { label: 'marked_bookmarked_at', accessor: 'created_at' },
        { label: 'subjectSlug', accessor: 'chapter.subject.slug' },
        { label: 'chapterSlug', accessor: 'chapter.slug' },
        { label: 'lessonSlug', accessor: 'content.slug' },
      ];
      state.bookmarkList = ord(action?.payload?.data?.payload, options);
      state.isBookmarkLoading = false;
    },
    startSubscriptionLoading: (state) => {
      state.isSubscriptionLoading = true;
    },
    hasSubscriptionError: (state, action) => {
      state.subscriptionError = action.payload;
      state.isSubscriptionLoading = false;
    },
    subscriptionListSuccess: (state, action) => {
      const sorted_data = action.payload.data?.payload
      .sort((date1, date2) => (new Date(date1).setHours(0,0) < new Date(date2).setHours(0,0) ? 1 : -1));
      const options = [
        { label: 'id', accessor: 'id' },
        { label: 'name', accessor: 'group.name' },
        { label: 'price', accessor: 'amount_paid' },
        { label: 'start_date', accessor: 'start_date' },
        { label: 'end_date', accessor: 'end_date' },
      ];
      state.subscriptionList = ord(sorted_data, options);
      state.isSubscriptionLoading = false;
    },

    startEditDivisionLoading: (state) => {
      state.isEditDivisionLoading = true;
    },
    hasEditDivisionError: (state, action) => {
      state.editDivisionError = action.payload;
      state.isEditDivisionLoading = false;
    },
    editDivisionListSuccess: (state, action) => {
      const options = [
        { label: 'id', accessor: 'id' },
        { label: 'classe actuelle', accessor: 'old_division.name' },
        { label: 'classe demandée', accessor: 'new_division.name' },
        { label: 'raison', accessor: 'reason2.reason' },
        { label: 'autre raison', accessor: 'reason' },
        { label: 'statut', accessor: 'status' },
      ];
      state.editDivisionList = ord(action?.payload?.data?.payload, options);
      state.isEditDivisionLoading = false;
    },
  },
});
export default slice.reducer;
// Actions
const {
  bookmarkListSuccess,
  startBookmarkLoading,
  hasBookmarkError,
  subscriptionListSuccess,
  startSubscriptionLoading,
  hasSubscriptionError,
  editDivisionListSuccess,
  startEditDivisionLoading,
  hasEditDivisionError,
} = slice.actions;

export const getBookmarkList = () => async (dispatch) => {
  dispatch(startBookmarkLoading());
  try {
    await api
      .get(`/student/bookmarked-contents`)
      .then((response) => dispatch(bookmarkListSuccess(response)));
  } catch (e) {
    if(e?.response?.status === 401){
      localStorage.clear();
      window.location = '/login';
    }
    return dispatch(hasBookmarkError(e.message));
  }
};

export const subscriptionsHistory = () => async (dispatch) => {
  dispatch(startSubscriptionLoading());
  try {
    await api
      .get(`/student/history/subscriptions`)
      .then((response) => dispatch(subscriptionListSuccess(response)));
  } catch (e) {
    if(e?.response?.status === 401){
      localStorage.clear();
      window.location = '/login';
    }
    return dispatch(hasSubscriptionError(e.message));
  }
};

export const editDivisionHistory = () => async (dispatch) => {
  dispatch(startEditDivisionLoading());
  try {
    await api
      .get(`/edit-division`)
      .then((response) => dispatch(editDivisionListSuccess(response)));
  } catch (e) {
    if(e?.response?.status === 401){
      localStorage.clear();
      window.location = '/login';
    }
    return dispatch(hasEditDivisionError(e.message));
  }
};