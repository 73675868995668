import { createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';
import { ord } from '../../utilities/methods';
import { closeSnackbar, enqueueSnackbar } from './notifier';
import { me } from './user';


// Slice
const slice = createSlice({
  name: 'points',
  initialState: {
    transfer: '',
    isPointLoading: false,
    isAddingDiscountLoading: false,
    error: false,
    point: '',
    pointError: '',
    isAddingPointLoading: null,
    points_history: null,
    isCardLoading: false,
    cardError: '',
    transactions: null,
    isTransactionLoading: false,
    clickSuccessMessage: '',
    clickErrorMessage: '',
    discountError: '',
    discount: '',
    QrCode:null,
    QrCodeError:'',
    isQrCodeLoading:false,
    QrCodeCheck:null,
    discountCodes:'',
    isDiscountCodesLoading:false,
    discountCodesError:'',
    deleteDiscountLoading:false,
    deletedDiscountCode:''

  },
  reducers: {
    startPointLoading: (state) => {
      state.transfer = false;
      state.error = false;
      state.isPointLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isPointLoading = false;
    },
    TransferSuccess: (state, action) => {
      state.transfer = action.payload;
      state.isPointLoading = false;
      state.error = false;
    },
    startAddPointLoading: (state) => {
      state.point = '';
      state.pointError = '';
      state.isAddingPointLoading = true;
    },
    hasPointError: (state, action) => {
    
      state.pointError = action.payload;
      state.point = '';
      state.isAddingPointLoading = false;
    },
    AddPointsSuccess: (state, action) => {
      state.point = action.payload?.payload;
      state.pointError = ''
      state.isAddingPointLoading = false;
    },
    AddPointsD17Success: (state, action) => {
      state.point = action.payload;
      state.pointError = ''
    
    },
    addPointsHistorySuccess: (state, action) => {
      
      const sorted_data = action.payload?.data?.payload?.codes
       .sort((date1, date2) => (new Date(date1).setHours(0,0) < new Date(date2).setHours(0,0) ? 1 : -1));
      const options = [
        { label: 'id', accessor: 'id' },
        { label: 'code', accessor: 'reference' },
        { label: 'method', accessor: 'payment_method.description' },
        { label: 'amount', accessor: 'amount' },
        { label: 'description', accessor: 'comment' },
        { label: 'status', accessor: 'status' },
        { label: 'date', accessor: 'created_at' },
      ];
      state.points_history = ord(sorted_data, options);
    },
    startCardLoading: (state) => {
      state.isCardLoading = true;
    },
    hasCardError: (state, action) => {
      state.cardError = action.payload;
      state.isCardLoading = false;
    },
    transactionSuccess: (state, action) => {
      const sorted_data = action.payload.payload
      .sort((date1, date2) => (new Date(date1).setHours(0,0) < new Date(date2).setHours(0,0) ? 1 : -1));
      const options = [
        { label: 'id', accessor: 'id' },
        { label: 'sender_id' , accessor:'from_user.id'},
        { label: 'receiver_id', accessor: 'to_user.id'},
        { label: 'sender_name', accessor: 'from_user.name'},
        { label: 'receiver_name', accessor: 'to_user.name' },
        { label: 'points', accessor: 'points' },
        { label: 'updated_at', accessor: 'updated_at' },
        { label: 'status', accessor: 'from_user.id' },
        { label: 'sender_parent_id', accessor:   'from_parent_user.id' },
        { label: 'sender_parent_name', accessor:  'from_parent_user.name'},

      ];
      state.transactions = ord(sorted_data, options);
      state.isTransactionLoading = false;
    },
    startTransactionsLoading: (state) => {
      state.isTransactionLoading = true;
    },
    clickSuccess: (state, action) => {
      state.clickSuccessMessage = action.payload;
      // (state.pointError = ''), (state.isAddingPointLoading = false);
    },
    clickEchec: (state, action) => {
      state.clickErrorMessage = action.payload;
      // (state.pointError = ''), (state.isAddingPointLoading = false);
    },
    startDiscountLoading: (state) => {
      state.discount = '';
      state.discountError = '';
      state.isAddingDiscountLoading = true;
    },
    hasDiscountError: (state, action) => {
      state.discountError = action.payload;
      state.discount = '';
      state.isAddingDiscountLoading = false;
    },
    discountSuccess: (state, action) => {
      state.discount = action?.payload?.message;
      state.discountError = ''
      state.isAddingDiscountLoading = false;
    },
    startDiscountCodesLoading:(state,action)=>{
      state.discountCodes='';
      state.isDiscountCodesLoading=true;
      state.discountCodesError='';
    },
    discountCodesSuccess:(state,action)=>{
      state.discountCodes=action.payload;
      state.discountCodesError='';
      state.isDiscountCodesLoading=false;
    },
    hasDiscountCodesError:(state,action)=>{
      state.discountCodes='';
      state.isDiscountCodesLoading=false;
      state.discountCodesError=action.payload;
    },
    deleteDiscountCodesLoading:(state,action)=>{
    state.deleteDiscountLoading=true;
    state.discountCodesError=''
    },
    deletedDiscountCodesSuccess:(state,action)=>{
      state.deleteDiscountLoading=false;
      state.deletedDiscountCode=action.payload;
      state.discountCodesError=''
     
    },

    QrCodeLoading:(state,action)=>{
      state.QrCodeError='';
      state.isQrCodeLoading=true
    },
    QrCodeSuccess:(state,action)=>{
      state.QrCode=action.payload;
    },
    hasQrCodeError:(state,action)=>{
      state.QrCodeError=action.payload;
      state.isQrCodeLoading=false
    },
    QrCodeCheckLoading:(state,action)=>{
      state.QrCodeCheckError='';
      state.isQrCodeCheckLoading=true
    },
    QrCodeCheckSuccess:(state,action)=>{
      state.QrCodeCheck=action.payload;
    },
    hasQrCodeCheckError:(state,action)=>{
      state.QrCodeCheckError=action.payload;
      state.isQrCodeCheckLoading=false
    },startBenefitsLoading:(state,action)=>{
      state.benefitsLoading=true;
      state.benefitSucess=''
    },benefitsSuccess:(state,action)=>{
      state.benefitsLoading=false;
      state.benefitSuccess=action.payload
    },hasBenefitsError:(state,action)=>{
      state.benefitsLoading=false;
      state.benefitSucess='';
      state.benefitError=action.payload
    }
    ,startBenefitsHistoryLoading:(state,action)=>{
      state.benefitsHistoryLoading=true;
      state.benefitHistorySucess=''
    },benefitsHistorySuccess:(state,action)=>{
      state.benefitsHistoryLoading=false;
      state.benefitHistorySuccess=action.payload
    },hasBenefitsHistoryError:(state,action)=>{
      state.benefitsHistoryLoading=false;
      state.benefitHistorySucess='';
      state.benefitHistoryError=action.payload
    }
  },
});
export default slice.reducer;
// Actions
const {
  TransferSuccess,
  startPointLoading,
  hasError,
  hasPointError,
  AddPointsSuccess,
  startAddPointLoading,
  addPointsHistorySuccess,
  startCardLoading,
  hasCardError,
  startTransactionsLoading,
  transactionSuccess,
  clickSuccess,
  clickEchec,
  discountSuccess,
  hasDiscountError,
  startDiscountLoading,
  AddPointsD17Success,
  QrCodeLoading,
  QrCodeSuccess,
  hasQrCodeError,
  QrCodeCheckLoading,
  QrCodeCheckSuccess,
  hasQrCodeCheckError,
  startBenefitsLoading,
  benefitsSuccess,
  hasBenefitsError,
  startBenefitsHistoryLoading,
  benefitsHistorySuccess,
  hasBenefitsHistoryError,
  startDiscountCodesLoading,
  discountCodesSuccess,
  hasDiscountCodesError,
  deleteDiscountCodesLoading,
  deletedDiscountCodesSuccess
} = slice.actions;

export const transferPoints = (toUser, points, admin) => async (dispatch) => {
  dispatch(startPointLoading());
  try {
    await api
      .post(`/student/transfer-points`, {
        toUser,
        points,
        admin,
      })
      .then((response) => dispatch(TransferSuccess(response.data)));
  } catch (e) {
    if (e.response) {
      let message = '';
      let key;
      if (e.response.data.errors) {
        for (key in e.response.data.errors) {
          message = e.response.data.errors[key][0];
        }
      } else {
        message = e.response.data.message;
      }
      return dispatch(hasError(message));
    }
  }
};

export const addPoints = (reference, amount, comment, paymentMethod) => async (dispatch) => {
  dispatch(startAddPointLoading());
  try {
    await api
      .post(`/student/codes`, {
        reference,
        amount,
        comment,
        paymentMethod,
      })
      .then((response) => {
        dispatch(AddPointsSuccess(response.data));

        dispatch(getPointsHistory());
      });
  } catch (e) {
    if (e.response) {
      let message = '';
      let key;
      if (e.response.data.errors) {
        for (key in e.response.data.errors) {
          message = e.response.data.errors[key][0];
        }
      } else {
        message = e.response.data.message;
      }
      return dispatch(hasPointError(message));
    }
  }
};
export const addPointsD17 = (reference, amount, comment, paymentMethod) => async (dispatch) => {
  dispatch(startAddPointLoading());
  try {
    await api
      .post(`/student/codes`, {
        reference,
        amount,
        comment,
        paymentMethod,
      })
      .then((response) => {
        dispatch(AddPointsD17Success(response.data.payload));
        dispatch(getPointsHistory());
      });
  } catch (e) {
    if (e.response) {
      let message = '';
      let key;
      if (e.response.data.errors) {
        for (key in e.response.data.errors) {
          message = e.response.data.errors[key][0];
        }
      } else {
        message = e.response.data.message;
      }
      return dispatch(hasPointError(message));
    }
  }
};

export const getPointsHistory = () => async (dispatch) => {
  try {
    const res = await api.get('/codes');
    dispatch(addPointsHistorySuccess(res));
  } catch (e) {
    // dispatch(
    //   enqueueSnackbar({
    //     message: e.message,
    //     options: {
    //       key: new Date().getTime() + Math.random(),
    //       variant: 'error',
    //     },
    //   })
    // );
    return console.error(e.message);
  }
};
 export const getQrCode=(codeId)=>async(dispatch)=>{
   dispatch(QrCodeLoading());
   
   try { await api.get(`/payment/d17/${codeId}`).then((response)=>{
     const data=response.data.payload
     dispatch(QrCodeSuccess(data))
   })
     
   } catch (e) {
    if (e.response) {
      let message = '';
      let key;
      if (e.response.data.errors) {
        for (key in e.response.data.errors) {
          message = e.response.data.errors[key][0];
        }
      } else {
        message = e.response.data.message;
      }
      return dispatch(hasQrCodeError(message));
    }
  }
 }

 export const getQrCodeChek=(codeId)=>async(dispatch)=>{
  dispatch(QrCodeCheckLoading());
  
  try { await api.get(`/payment/d17/check/${codeId}`).then((response)=>{
    const data=response
    dispatch(QrCodeCheckSuccess(data))
  })
    
  } catch (e) {
   if (e.response) {
     let message = '';
     let key;
     if (e.response.data.errors) {
       for (key in e.response.data.errors) {
         message = e.response.data.errors[key][0];
       }
     } else {
       message = e.response.data.message;
     }
     return dispatch(hasQrCodeCheckError(message));
   }
 }
}


export const creditCardRedirect = (codeId) => async (dispatch) => {
  dispatch(startCardLoading());
  try {
    await api.get(`/payment/redirect/${codeId}`).then((response) => {
      const formData = response.data;

      const form = document.createElement('form');
      form.action = formData.url;
      form.method = 'POST';
      let key;
      for (key in formData.data) {
        if (formData.data.hasOwnProperty(key)) {
          const input = document.createElement('input');
          input.type = 'hidden';
          input.name = key;
          input.value = formData.data[key];

          form.appendChild(input);
        }
      }

      document.body.appendChild(form);
      form.submit();
    });
  } catch (e) {
    return dispatch(hasCardError(e.message));
  }
};

export const getClicToPaySuccess = (orderId) => async (dispatch) => {
  try {
    const new_key = new Date().getTime() + Math.random();
    await api
      .post(`${process.env.REACT_APP_API_URL}/payment/success?orderId=${orderId}&lang=fr`)
      .then((response) => {
        dispatch(clickSuccess(response.message));
        dispatch(closeSnackbar(new_key));
        dispatch(
          enqueueSnackbar({
            message: response?.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
        window.location = '/offres';
      })
  } catch (e) {
    return console.log(e.message);
  }
};

export const getClicToPayEchec = (orderId) => async (dispatch) => {
  try {
    const new_key = new Date().getTime() + Math.random();
    await api
      .post(`${process.env.REACT_APP_API_URL}/payment/echec?orderId=${orderId}&lang=fr`)
      .then((response) => {
        dispatch(clickEchec(response.message));
        dispatch(closeSnackbar(new_key));
        dispatch(
          enqueueSnackbar({
            message: response?.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
        window.location = '/porte-monnaie'
      })
      .then((response) => dispatch(clickEchec(response.message)));
  } catch (e) {
    return console.log(e.message);
  }
};

export const allTransactions = () => async (dispatch) => {
  dispatch(startTransactionsLoading());
  try {
    await api.get(`/student/transfer-points-history`)
      .then((response) => dispatch(transactionSuccess(response.data)));
  } catch (e) {
    return console.log(e.message);
  }
};


export const pointsTransfered = () => async (dispatch) => {
  dispatch(startTransactionsLoading());
  try {
    await api.get(`/user/points-transfered`)
      .then((response) => dispatch(transactionSuccess(response.data)));
  } catch (e) {
    return console.log(e.message);
  }
};


export const pointsReceived = () => async (dispatch) => {
  dispatch(startTransactionsLoading());
  try {
    await api.get(`/user/points-received`)
      .then((response) => dispatch(transactionSuccess(response.data)));
  } catch (e) {
    return console.log(e.message);
  }
};

export const addDiscountCode = (code,offerId,quantity,allYear) => async (dispatch) => {
  dispatch(startDiscountLoading());
  try {
    quantity = quantity === 'Par mois' ? '6' : quantity;
    await api
      .post(`/student/discount-code`, {
        "code":code,
        "offerId":offerId,
        "quantity":quantity,
        'allYear':allYear
      })
      .then((response) => {
        dispatch(discountSuccess(response.data));
      }).then(()=>dispatch(getDiscountCodes()));
  } catch (e) {
    if (e.response) {
      let message = '';
      let key;
      if (e.response.data.errors) {
        for (key in e.response.data.errors) {
          message = e.response.data.errors[key][0];
        }
      } else {
        message = e.response.data.message;
      }
      return dispatch(hasDiscountError(message));
    }
  }
};

export const getDiscountCodes = () => async (dispatch) => {
  dispatch(startDiscountCodesLoading());
  try {
    await api
      .get(`/student/discount-code`
      )
      .then((response) => {
        dispatch(discountCodesSuccess(response.data.payload));
      });
  } catch (e) {
    if (e.response) {
      let message = '';
      let key;
      if (e.response.data.errors) {
        for (key in e.response.data.errors) {
          message = e.response.data.errors[key][0];
        }
      } else {
        message = e.response.data.message;
      }
      return dispatch(hasDiscountCodesError(message));
    }
  }
};

export const deleteDiscountCodes = (id) => async (dispatch) => {
  dispatch(deleteDiscountCodesLoading());
  try {
    await api
      .delete(`/student/discount-code/${id}`
      )
      .then((response) => {
        dispatch(deletedDiscountCodesSuccess(response.data));
        dispatch(getDiscountCodes());
      });
  } catch (e) {
    if (e.response) {
      let message = '';
      let key;
      if (e.response.data.errors) {
        for (key in e.response.data.errors) {
          message = e.response.data.errors[key][0];
        }
      } else {
        message = e.response.data.message;
      }
      return dispatch(hasDiscountCodesError(message));
    }
  }
};

export const getInfluencerBenefits = () => async (dispatch) => {
     dispatch(startBenefitsLoading());
  try {
    await api
      .get(`/student/profit/discount-code`
      )
      .then((response) => {
        dispatch(benefitsSuccess(response.data.payload));
      });
  } catch (e) {
    if (e.response) {
      let message = '';
      let key;
      if (e.response.data.errors) {
        for (key in e.response.data.errors) {
          message = e.response.data.errors[key][0];
        }
      } else {
        message = e.response.data.message;
      }
      return dispatch(hasBenefitsError(message));
    }
  }
};

export const getBenefitsHistory = (id) => async (dispatch) => {
  dispatch(startBenefitsHistoryLoading());
try {
 await api
   .get(`/student/profit/discount-code-histories/${id}`
   )
   .then((response) => {
     dispatch(benefitsHistorySuccess(response.data.payload));
   });
} catch (e) {
 if (e.response) {
   let message = '';
   let key;
   if (e.response.data.errors) {
     for (key in e.response.data.errors) {
       message = e.response.data.errors[key][0];
     }
   } else {
     message = e.response.data.message;
   }
   return dispatch(hasBenefitsHistoryError(message));
 }
}
};
