import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { addEmail } from '../../../redux/slices/sessions';
import { useSelector, useDispatch } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Modal from '../../../components/Modal/Modal';
import VerifyEmailModal from '../../../components/common/SessionModal/VerifyEmailModal';
import { TransferPointsModal } from '../..';
import  {formatMessages}  from '../../../utilities/translateHelper';


const EmailModal = ({ onCloseModal }) => {
  const { i18n,t } = useTranslation();
  const { email, emailError, isEmailError } = useSelector((state) => state.sessions);
  const { informations } = useSelector((state) => state.user);
  const user_id = informations && informations.email;
  const dispatch = useDispatch();
  const [mail, setMail] = useState('');
  const [open, openModal] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [message, setMessage] = useState();
  const [severity, setSeverity] = useState();
  const [validation, setValidaion] = useState(emailError);

  const successMessage = email && email.message;
  useEffect(() => {
    if (successMessage && isEmailError === false) {
      openModal(true);
    }
  }, [email, isEmailError]);


  const mailValidation = (mail) =>  {
    if (!mail || /\s/g.test(mail) || !isNaN(mail)) {
      return { success: false, 'message': t('Please enter a valid email') };
    }
  }
  const handleSubmit = () => {
    const validation = mailValidation(mail);
    if (validation) {
      setMessage(validation.message);
      setSeverity('warning');
      setValidaion(true);
      setOpenSnackBar(true);
    }else{
      dispatch(addEmail(mail));
      setOpenSnackBar(true);
      setValidaion(false);
      setMessage('');

    }
  };

  return (
    <div className= {i18n.language === 'ar' ? 'rtl' : 'base'}>
      <TextField
        autoFocus
        outlined
        id="email"
        //label={t("Entrez votre email")}
        placeholder={t("Entrez votre email")}
        type="string"
        value={mail}
        margin="dense"
        variant="outlined"
        className="basic-fields"
        style={{ width: 350, height: 40 }}
        onChange={(e) => setMail(e.target.value)}
      />
      <div className="basic">
        <Button
          onClick={(event) => handleSubmit(event)}
          className="buttonAdd"
          style={{ left: 240 }}
        >
          {t("Soumettre")}
        </Button>
      </div>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'Bottom',
          horizontal: 'center',
        }}
        onClose={() => setOpenSnackBar(false)}
      >
       {i18n.language === 'ar' ? (
        <Alert
          severity={!validation && !isEmailError ? 'success' : 'warning'}
          onClose={() => setOpenSnackBar(false)}
        >
          {!validation && !isEmailError ? `${mail} ${t("Veuillez entrer le code reçu à")}` : validation ? message  : isEmailError ? t(formatMessages(emailError)) : null }      
        </Alert>
      ):(
        <Alert
          severity={!validation && !isEmailError ? 'success' : 'warning'}
          onClose={() => setOpenSnackBar(false)}
        >
          {!validation && !isEmailError ? `${t("Veuillez entrer le code reçu à")} ${mail}` : validation ? message  : isEmailError ? t(formatMessages(emailError)) : null }      
        </Alert>
      )}
      </Snackbar>
      <Modal
        onCloseModal={onCloseModal}
        component={VerifyEmailModal}
        title={t('Entrez le code reçu par email')}
        data={mail}
        openModal={open}
        modalWidth={'xs'}
      />
    </div>
  );
};

export default EmailModal;