import React, { useEffect, useRef, useState } from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { resetPasswordCode } from '../../redux/slices/auth';
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import InputCard from '../../components/AuthComponents/InputCard';
import InfoCard from '../../components/AuthComponents/InfoCard';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import LockIcon from '@material-ui/icons/Lock';
import { resetPasswordValidation } from '../../components/AuthComponents/AuthValidation';
import ReCAPTCHA from 'react-google-recaptcha';
import { useHistory } from 'react-router-dom';

const ResetCode = () => {
  const dispatch = useDispatch();
  const { authResult, success } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const [values, setValues] = useState({});
  useEffect(() => {
    setValidationResult(authResult);
  }, [authResult]);
  useEffect(() => {
    setValidationStatus(success);
  }, [success]);

  //Validate with key press Enter
  useEffect(() => {
    const handleKeyPress = (e) => {
      const {keyCode} = e;
        if( keyCode === 13) {
          handleSubmit(e);
        }
    }
    window.addEventListener('keydown',handleKeyPress);
    return () => { 
       window.removeEventListener('keydown',handleKeyPress);
    }
  },);

  const [validationResult, setValidationResult] = useState(authResult);
  const [validationStatus, setValidationStatus] = useState(success);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const recaptchaRef = useRef();

  const handleChange = (e) => {
    setValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validation = resetPasswordValidation(values);
    if (validation) {
      setValidationResult(validation.message);
      setValidationStatus(validation.status);
      return setOpenSnackBar(true);
    }
    recaptchaRef.current.execute();
  };
  let history = useHistory();

  const onRecaptchaChange = async (value) => {
    let data = values;
    data['g-recaptcha-response'] = value;
    await dispatch(resetPasswordCode(data))
  
    setOpenSnackBar(true);
  };
  return (
    <div className="login-card">
      <div className="centered-box" style={{ minHeight: 500 }}>
        <span className="title" style={{ fontWeight: 400, fontSize: '1.75rem' }}>
          {t("Réinitialiser le mot de passe")}
        </span>
        <p className="text-muted">
          {t("Veuillez entrer le code de vérification reçu dans votre email ou votre téléphone")}
        </p>
        <InputCard
          value={values.token}
          handleFunction={handleChange}
          placeholder={t('Code de vérification')}
          typeInput={'text'}
          icon={<VpnKeyIcon className="icon-input" />}
          name="token"
        />
        <InputCard
          value={values.credential}
          handleFunction={handleChange}
          placeholder={t('Email ou numéro de téléphone')}
          typeInput={'text'}
          icon={<EmailIcon className="icon-input" />}
          name="credential"
        />
        <InputCard
          value={values.newPassword}
          handleFunction={handleChange}
          placeholder={t('Mot de passe minimum 8 caractères')}
          typeInput={t('password')}
          icon={<LockIcon className="icon-input" />}
          name="newPassword"
        />
        <InputCard
          value={values.retypePassword}
          handleFunction={handleChange}
          placeholder={t('Confirmez le mot de passe')}
          typeInput={t('password')}
          icon={<LockIcon className="icon-input" />}
          name="retypePassword"
        />
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={process.env.REACT_APP_RECAPATCHA_KEY}
          size="invisible"
          onChange={onRecaptchaChange}
        />
        <Button
          variant="outlined"
          disableElevation
          onClick={(e) => handleSubmit(e)}
          className="submit-login"
        >
          {t("Réinitialiser le mot de passe")}
        </Button>
      </div>
      <InfoCard
        title={t("Bienvenue")}
        text={t("Entrez vos informations personnelles et commencez le voyage avec nous")}
        buttonText={t('Inscription')}
        link="/register"
      />
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'Bottom',
          horizontal: 'center',
        }}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert
          severity={validationStatus ? 'success' : 'error'}
          onClose={() => setOpenSnackBar(false)}
        >
          {t(validationResult)}
        </Alert>
      </Snackbar>
    </div>
  );
};

ResetCode.propTypes = {
  dispatch: PropTypes.func,
  email: PropTypes.string,
  setEmail: PropTypes.func,
  password: PropTypes.string,
  setPassword: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default ResetCode;
