import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { createResetPasswordCode } from '../../redux/slices/auth';
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import InputCard from '../../components/AuthComponents/InputCard';
import InfoCard from '../../components/AuthComponents/InfoCard';
import ReCAPTCHA from 'react-google-recaptcha';
import { useLocation } from 'react-router-dom';
const CreateResetCode = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { authResult, success } = useSelector((state) => state.auth);
 
const location=useLocation()
window.history.pushState( null, null,location.state!==undefined?"?"+location.state:"")

  useEffect(() => {
    setValidationResult(authResult);
  }, [authResult]);
  useEffect(() => {
    setValidationStatus(success);
  }, [success]);
  //Validate with key press Enter
  useEffect(() => {
    const handleKeyPress = (e) => {
      const { keyCode } = e;
      if (keyCode === 13) {
        handleSubmit(e);
      }
    };
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  });

  const [validationResult, setValidationResult] = useState(authResult);
  const [credential, setCredential] = useState();
  const [validationStatus, setValidationStatus] = useState(success);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const recaptchaRef = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!credential) {
      setValidationResult(t('invalid credential'));
      setValidationStatus(false);
      return setOpenSnackBar(true);
    }

    recaptchaRef.current.execute();
  };

  const onRecaptchaChange = async (value) => {
    await dispatch(
      createResetPasswordCode({ 'credential': credential, 'g-recaptcha-response': value ,link:location.state })
    )
    ;
    setOpenSnackBar(true);
  };
  return (
    <div className="login-card">
      <div className="centered-box" style={{ minHeight: 500 }}>
        <span className="title" style={{ fontWeight: 400, fontSize: '1.75rem' }}>
          {t('Réinitialiser le mot de passe')}
        </span>
        <p className="text-muted">
          {t('Veuillez entrer votre email ou votre numéro de téléphone associé à votre compte')}
        </p>
        <InputCard
          value={credential}
          handleFunction={(event) => setCredential(event.target.value)}
          placeholder={t('Numéro de téléphone e-mail ou nom d utilisateur')}
          typeInput={'text'}
          icon={<EmailIcon className="icon-input" />}
          name="credential"
        />

        <Button
          variant="outlined"
          disableElevation
          onClick={(e) => handleSubmit(e)}
          className="submit-login"
        >
          {t('Réinitialiser le mot de passe')}
        </Button>
      </div>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={process.env.REACT_APP_RECAPATCHA_KEY}
        size="invisible"
        onChange={onRecaptchaChange}
      />
      <InfoCard
        title={t('Bienvenue')}
        text={t('Entrez vos informations personnelles et commencez le voyage avec nous')}
        buttonText={t('Inscription')}
        link="/register"
      />
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'Bottom',
          horizontal: 'center',
        }}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert
          severity={validationStatus ? 'success' : 'error'}
          onClose={() => setOpenSnackBar(false)}
        >
          {t(validationResult)}
        </Alert>
      </Snackbar>
    </div>
  );
};

CreateResetCode.propTypes = {
  dispatch: PropTypes.func,
  email: PropTypes.string,
  setEmail: PropTypes.func,
  password: PropTypes.string,
  setPassword: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default CreateResetCode;
