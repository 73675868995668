import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import uploadCloud from '../assets/img/icons/upload-cloud.svg';

function FileDropeZone(props) {
  const { setFiles } = props;
  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
  }, []);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ onDrop });
  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));
  return (
    <section className="container">
      <div {...getRootProps({ className: 'dropzone' })} className="dropzone">
        <input {...getInputProps()} />
        <p className="dropZoneText">Drop your file here or browse </p>
        <img src={uploadCloud} className="dropzoneIcon" />
      </div>
      <aside>
        <ul>{files}</ul>
      </aside>
    </section>
  );
}

export default FileDropeZone;
