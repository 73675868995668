import { configureStore } from '@reduxjs/toolkit';
import api from '../services/api';
import user from './slices/user';
import notifier from './slices/notifier';
import today_events from './slices/today_events';
import places from './slices/places';
import subjects from './slices/subjects';
import chapters from './slices/chapters';
import progress from './slices/progress';
import courses from './slices/courses';
import exams from './slices/exams';
import sessions from './slices/sessions';
import points from './slices/points';
import offers from './slices/offers';
import forum from './slices/forum';
import userHistory from './slices/userHistory';
import assistance from './slices/assistance';
import messages from './slices/messages';
import popups from './slices/popups';
import free_videos from './slices/free_videos';
import latest_courses from './slices/latest_courses';
import search from './slices/search';
import auth from './slices/auth';
import newProgress from './slices/newProgress';

export default configureStore({
  reducer: {
    user,
    notifier,
    today_events,
    places,
    subjects,
    chapters,
    progress,
    courses,
    exams,
    sessions,
    points,
    offers,
    forum,
    userHistory,
    assistance,
    messages,
    popups,
    free_videos,
    latest_courses,
    search,
    auth,
    newProgress,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: api,
      },
      serializableCheck: false,
    }),
  devTools: process.env.ENABLE_REDUX_DEV_TOOLS,

});
