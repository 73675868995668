import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ExamDownloadButton from './ExamDownloadButton';
import FileDropeZone from '../../components/FileDropeZone';
import BlueButton from '../../components/BlueButton';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {getExam, removeStudentHomework, uploadFiles} from '../../redux/slices/exams';
import Skeleton from '@material-ui/lab/Skeleton';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import WindowDimensions from '../../utilities/windowDimenssions';
import * as dayjs from 'dayjs';

const Index = () => {
  const { i18n,t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const { exam, isLoading, alertMessage } = useSelector((state) => state.exams);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const {width} = WindowDimensions();
  useEffect(() => {
    dispatch(getExam(id));
  }, [dispatch]);
  const handleSubmitFiles = async () => {
    await dispatch(uploadFiles(id, files));
    setOpenSnackBar(true);
    setFiles([]);
  };
  const handleDelete = async (contentId, fileId) => {
    await dispatch(removeStudentHomework(contentId, fileId));
    dispatch(getExam(id));
    setOpenSnackBar(true);
  };
  if (isLoading) {
    return (
      <div>
        <Skeleton variant="rect" height={500} />
      </div>
    );
  }
  return (
    <Card className={ i18n.language === 'ar' ? 'ExamDetailsContainer rtl' : 'ExamDetailsContainer'} >
      <CardContent className="cardContent" >
        <div className="cardHeader">
          <Typography style={{ color: '#2ba7df' }}> {t("Détails de l&apos;examen")} </Typography>
        </div>
        <div className="card">
          <Card className="customCard">
            {exam?.contentExam ? (
                <CardContent style={{width:width-100}}>
                  <BlueButton text={exam.contentExam?.subject_exams[0]?.subject?.name} width={100} fontSize={12} />
                  <Typography className="examTitle">{exam.contentExam?.content?.name}</Typography>
                  <Typography className="teacherName">{t("Par")} : {exam.contentExam?.content?.instructor?.name+' '+exam.contentExam?.content?.instructor?.last_name}</Typography>
                  <Typography className="examDate">{t("Date de fin")} : {dayjs(exam.contentExam?.due_date).format('DD/MM/YYYY HH:mm')}</Typography>
                  <Typography className="text">{t("Examen")}</Typography>
                  {exam.fileExam &&
                    exam.fileExam.map((homework, index) => {
                      return (
                        <ExamDownloadButton text={homework?.file?.name} link={homework?.file?.url} key={homework.id} />
                      );
                    })}
                  {!exam?.expired && (
                    <div>
                      <Typography className="text">Travail à faire</Typography>
                      <FileDropeZone setFiles={setFiles} />
                    </div>
                  )}
                  {files.length !== 0 && (
                    <div className="submitFiles" onClick={handleSubmitFiles}>
                      <BlueButton text="soumettre" width='25%' float='center' />
                    </div>
                  )}
                  {exam.fileHomework && exam.fileHomework.length !== 0 && (
                    <>
                      <Typography className="text">Mon Travail </Typography>
                      {exam.fileHomework.map((studentHomework) => {
                        return <ExamDownloadButton homework={!exam?.expired} onClick={() => handleDelete(id,studentHomework.id)} text={studentHomework?.file?.name} link={studentHomework?.file?.url} key={studentHomework?.file?.id} />;
                      })}
                    </>
                  )}
                  {exam.fileHomeWorkCorrection && exam.fileHomeWorkCorrection.length !== 0 && (
                    <>
                      <Typography className="text">{t("Correction")} </Typography>
                      {exam.fileHomeWorkCorrection.map((studentSolution, index) => {
                        return <ExamDownloadButton text={studentSolution?.file?.name} link={studentSolution?.file?.url} key={index} />;
                      })}
                    </>
                  )}
                  {exam.fileExamCorrection && exam.fileExamCorrection.length !== 0 && exam?.expired && (
                    <div>
                      <Typography className="text">{t('Correction général')}</Typography>
                      {exam.fileExamCorrection.map((solution, index) => {
                        return (
                          <ExamDownloadButton text={solution?.file?.name} link={solution.file?.url} key={index} />
                        );
                      })}
                    </div>
                    )}
                </CardContent>
            ) : (
                null
            )}
          </Card>
          <Snackbar
            open={openSnackBar}
            autoHideDuration={3000}
            anchorOrigin={{
              vertical: 'Bottom',
              horizontal: 'center',
            }}
            onClose={() => setOpenSnackBar(false)}
          >
            <Alert severity='success' onClose={() => setOpenSnackBar(false)}>
              {t(alertMessage)}
            </Alert>
          </Snackbar>
        </div>
      </CardContent>
    </Card>
  );
};

export default Index;
