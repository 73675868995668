import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

const QcmFrame = (props) => {
  const { data } = props;
  // useEffect(() => {
  //   axios
  //     .post(`${process.env.REACT_APP_QCM_API_URL}/login?token=${localStorage?.getItem('token')}`)
  //     .then((response) => localStorage?.setItem('tokenQuiz', response?.data?.data?.token))
  //     .catch((error) => error);
  // }, []);

  return (
    <div>
      {data && (
        <div
          className="interact_responsive_padding"
          style={{
            width: '100%',
            padding: '68.8% 0 0 0',
            position: 'relative',
            marginBottom: '5px',
          }}
        >
          <div
            className="interact_responsive_wrapper"
            style={{
              height: '100%',
              left: '0',
              position: 'absolute',
              top: '0',
              width: '100%',
            }}
          >
            <iframe
              id={`interactApp${data.content_qcm_id}`}
              width="100%"
              height="100%"
              style={{ border: 'none', maxWidth: '100%', margin: '0' }}
              allowTransparency="true"
              frameBorder="0"
              src={`${process.env.REACT_APP_QCM_URL}/student/quiz/${
                data.content_qcm_id
              }?token=${localStorage.getItem('tokenQuiz')}&&id=${localStorage.getItem('UserQuizId')}`}
            />
          </div>
        </div>
      )}
    </div>
  );
};

QcmFrame.propTypes = {
  data: PropTypes.object,
};

export default QcmFrame;
