import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { CardTitle, Dialog, VariantButton } from '../../components';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import imAvatar from '../../assets/img/icons/avatar.png';
import imAvatarMale from '../../assets/img/icons/avatar-male.png';
import imAvatarFemale from '../../assets/img/icons/avatar-female.png';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { editProfile, me, editDivision, getReasons } from '../../redux/slices/user';
import {
  getCountries,
  getDelegations,
  getInstitutes,
  getStates,
} from '../../redux/slices/places';
import { getDivisions } from '../../redux/slices/auth';
import { completeRegister } from '../../redux/slices/user';
import { enqueueSnackbar } from '../../redux/slices/notifier';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import * as dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { avatarByGender } from '../../utilities/methods';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { serialize } from 'object-to-formdata';
import { getOptionalSubjects } from '../../redux/slices/subjects';


const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

const Index = ({ profileCompleted, admin,hasEditDivision }) => {
  dayjs.extend(customParseFormat);
  const { i18n , t } = useTranslation();
 
  const { informations } = useSelector((state) => state.user);
  const { divisions } = useSelector((state) => state.auth);
  const { optional_subjects } = useSelector((state) => state.subjects);
  const { reasons } = useSelector((state) => state.user);
  const { institutes, countries, states, delegations } = useSelector(
    (state) => state.places);
  const dispatch = useDispatch();
  const [imageFile, setAvatar] = useState(imAvatar);
  const [name, setName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [birthDate, setBirthDate] = useState(null);
  const [gender, setGender] = useState(null);
  const [country, setCountry] = useState(null);
  const [state, setState] = useState(null);
  const [delegation, setDelegation] = useState(null);
  const [institute, setInstitute] = useState(null);
  const [division, setDivision] = useState(null);
  const [divisionId, setDivisionId] = useState(null);
  const [optionalSubject, setOptionalSubject] = useState(null);
  const [hideOptionalSubject, setHideOptionalSubject] = useState(false);

  const [divisionEdit, setEditDivision] = useState(null);
  const [reason, setReason] = useState(null);
  const [otherReason, setOtherReason] = useState(null);

  const [isEmpty, setIsEmpty] = useState(true);
  const [canEdit1, setCanedit1] = useState(false);
  const [canEdit2, setCanedit2] = useState(false);

  const [open, setOpen] = useState(false);
  const [upImg, setUpImg] = useState();
  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 1 / 1 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [img, setImg] = useState(null);

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);

  const [isAvatarChanged, setIsAvatarChanged] = useState(false);

  const [openEdit, setOpenEdit] = React.useState(false);

  const handleClickOpen = () => {
    setOpenEdit(true);
  };
  const handleClose = () => {
    setOpenEdit(false);
  };

  useEffect(() => {
    if (!informations) {
      dispatch(me());
    }
    if (!countries) {
      dispatch(getCountries());
    }
 }, []);

  useEffect(() => {
   if(optionalSubject !== null){
     setHideOptionalSubject(true);}
  },[optional_subjects]);

  useEffect(() => {
    if (informations) {
      setAvatar(avatarByGender(informations.avatar, informations.gender));
      setName(informations.first_name || null);
      setLastName(informations.last_name || null);
      setPhone(informations.phone || null);
      setEmail(informations.email || null);
      setBirthDate(informations.birth_date || null);
      setGender(informations.gender || null);
      setCountry(informations.country_id || null);
      setState(informations.state_id || null);
      setDelegation(informations.delegation_id || null);
      setInstitute(informations.institute_id || null);
      setDivision(informations.division || null);
      setDivisionId(informations.division_id || null);
      setOptionalSubject(informations.optionalSubject_id || null);
      setIsEmpty(false);
    }
  }, [informations]);

  useEffect(() => {
    if(divisionId){
     dispatch(getOptionalSubjects(divisionId));
   }
 }, [divisionId]);

  useEffect(() => {
    dispatch(getDivisions());
    dispatch(getReasons());
  }, [dispatch]);


  useEffect(() => {
    // need to check country for tunisians
    if (country !== null) {
      dispatch(getStates({ countryId: country }));
    }
  }, [country]);

  useEffect(() => {
    if (state !== null) {
      dispatch(getDelegations({ stateId: state }));
    }
  }, [state]);

  useEffect(() => {
    if (state !== null) {
      dispatch(getInstitutes({ stateId: state }));
    }
  }, [state]);

  useEffect(() => {
    if (informations) {
      if (
        imageFile !== informations.avatar &&
        imageFile !== null &&
        imageFile !== imAvatar &&
        imageFile !== imAvatarMale &&
        imageFile !== imAvatarFemale
      ) {
        setIsAvatarChanged(true);
      } else {
        setIsAvatarChanged(false);
      }
    }
  }, [imageFile]);
  
  useEffect(() => {

    if (!isEmpty) {
      if (
        isAvatarChanged ||
        informations.first_name !== name ||
        informations.last_name !== lastName ||
        informations.birth_date !== dayjs(birthDate).format('YYYY-MM-DD') ||
        informations.gender !== gender ||
        informations.email !== email ||
        informations.phone !== phone ||
        profileCompleted == false
      ) {
        if (name !== '' || lastName !== '' || birthDate !== null || gender !== null || phone !== '' || email !== '') {
          setCanedit1(true);
        } else {
          setCanedit1(false);
        }
      } else {
        setCanedit1(false);
      }
    }
  }, [isEmpty, isAvatarChanged, name, lastName, birthDate, gender, phone, email]);

  useEffect(() => {
    // need to check country for tunisians
    if (!isEmpty) {
      if (
        informations.country_id != country ||
        informations.state_id != state ||
        informations.delegation_id != delegation ||
        informations.institute_id != institute
      ) {
        if (country!== 1||( country !== null ) 
         ) {
          setCanedit2(true);
        } else {
          setCanedit2(false);
        }
      } else {
        setCanedit2(false);
      }
    }
  }, [isEmpty, country, state, delegation, institute]);
  const handleResetAvatar = () => {
    setImg(null)
    document.querySelector('input[type=file]').value = null;
    document.getElementsByClassName('MuiAvatar-img')[0].src = avatarByGender(
      informations.avatar,
      informations.gender,
    );
    setAvatar(avatarByGender(informations.avatar, informations.gender));
    setIsAvatarChanged(false);
    setCanedit1(false);
  };

  const handleEditDivisionSubmit = () => {
    if (!reason) {
      dispatch(
        enqueueSnackbar({
          message: t('Remplir le champ raison'),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'warning',
          },
        }),
      );
      return;
    }
    if (!divisionEdit) {
      dispatch(
        enqueueSnackbar({
          message: t('Remplir le champ classe'),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'warning',
          },
        }),
      );
      return;
    }
    dispatch(editDivision(divisionEdit, reason, otherReason));
    setOpenEdit(false)
  }
  const handlePersoSubmit = () => {
    if (name === '' || lastName === '') {
      dispatch(
        enqueueSnackbar({
          message: t('Remplir les champs nom et prénom'),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'warning',
          },
        }),
      );
      return;
    }
    if (name.length < 2 || name.length > 20 || !/^[ ً ُ ٌ ِ ٍ ّء-يa-zA-Z]{2,20}(\s[ ً ُ ٌ ِ ٍ ّء-يa-zA-Z]{2,20}(\s?))?$/.test(name)) {
      dispatch(
        enqueueSnackbar({
          message: t('Le champ prénom est invalide'),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'warning',
          },
        }),
      );
      return;
    }
    if (lastName.length < 2 || lastName.length > 20 || !/^[ ً ُ ٌ ِ ٍ ّء-يa-zA-Z]{2,20}(\s[ ً ُ ٌ ِ ٍ ّء-يa-zA-Z]{2,20}(\s?))?$/.test(lastName)) {
      dispatch(
        enqueueSnackbar({
          message: t('Le champ nom est invalide'),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'warning',
          },
        }),
      );
      return;
    }
    if (!phone) {
      dispatch(
        enqueueSnackbar({
          message: t('Remplir le champ téléphone'),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'warning',
          },
        }),
      );
      return;
    }
    if (!phone || phone.length != 8 || isNaN(phone)) {
      dispatch(
        enqueueSnackbar({
          message: t('Le champ numéro de téléphone est invalide'),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'warning',
          },
        }),
      );
      return;
    }
    if (!division) {
      dispatch(
        enqueueSnackbar({
          message: t('Remplir le champ classe'),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'warning',
          },
        }),
      );
      return;
    }
    if (!birthDate) {
      dispatch(
        enqueueSnackbar({
          message: t('Remplir le champ date de naissance'),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'warning',
          },
        }),
      );
      return;
    }
    if(optionalSubject){
      setHideOptionalSubject(true);
    }
    let newAvatar = null;
    if (imageFile !== imAvatar && imageFile !== imAvatarMale && imageFile !==
      imAvatarFemale) {
      newAvatar = imageFile;
    }
    if (informations && informations.division_id) {
      let req = '';
      if (isAvatarChanged) {
        req = {
          imageFile: newAvatar,
          name: name,
          lastName: lastName,
          birthDate: dayjs(birthDate).format('YYYY-MM-DD'),
          division: informations.division_id,
          email,
          phone,
          gender,
          country: country,
          state: state,
          delegation: delegation,
          institute: institute,
        };

      } else {
        req = {
          name: name,
          lastName: lastName,
          birthDate:dayjs(birthDate).format('YYYY-MM-DD'),
          division: informations.division_id,
          email,
          phone,
          gender,
          country: country,
          state: state,
          delegation: delegation,
          institute: institute,
          optionalSubject: optionalSubject,
        };
      }
      dispatch(editProfile(serialize(req)));

    }
    else {
      const req = {
        name: name,
        lastName: lastName,
        birthDate: dayjs(birthDate).format('YYYY-MM-DD'),
        phone,
        email,
        division: division,
        country: country,
        state: state,
        delegation: delegation,
        institute: institute,
      };
      dispatch(completeRegister(req));
    }

  };

  const handleStatusSubmit = () => {
    // need to check country for tunisians
    if ((country === null || state === null )&& (country===1 && state === null )
) {
      dispatch(
        enqueueSnackbar({
          message: t('Remplir les champs pays gouvernorat'),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'warning',
          },
        }),
      );
      return;}
    
    const req = {
      country: country,
      state: state,
      delegation: delegation,
      institute: institute,
      name: name,
      lastName: lastName,
      birthDate: dayjs(birthDate).format('YYYY-MM-DD'),
      phone,
      email,
      division: informations?.division_id,
      gender,
      optionalSubject: optionalSubject,
    };
    dispatch(editProfile(req));
  };
  const dataURItoBlob = (dataURI) => {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }
  const submitAvatar = (canvas, crop) => {

    if (!crop || !canvas) {
      return;
    }
    const dataURL = canvas.toDataURL();
    var blob = dataURItoBlob(dataURL);
    setCanedit1(true);
    setIsAvatarChanged(true);
    setImg(dataURL);
    setAvatar(blob);
    setOpen(false);
  };
  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.addEventListener('load', () => setOpen(true));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );
  }, [completedCrop]);

  return (
    <>
      <Card className="ta-card edit-profile-widget">
        <CardContent>
          <div className="child-full">
            <div className="row">
              <StyledBadge
                overlap="circle"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                variant="dot"
              >
                <Avatar alt={name} src={img !== null ? img : imageFile} id="img-preview" />
              </StyledBadge>
              <input
                accept="image/*"
                multiple
                type="file"
                className="hidden"
                id="customFile"
                // onChange={() => previewFile()}
                onChange={onSelectFile}
                disabled={profileCompleted == true ? false : true}

              />
              <label htmlFor="customFile">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  startIcon={<AddAPhotoIcon />}
                  size="small"
                  className="upload-btn blue"
                  disabled={profileCompleted == true ? false : true}
                >
                  {t("Changer votre photo")}
                  </Button>
              </label>
              {isAvatarChanged && (
                <VariantButton
                  label={t("Supprimer")}
                  className="red"
                  onClickAction={() => handleResetAvatar()}
                />
              )}
            </div>
            <CardTitle title={t("Informations personnelles")} />
            <div className="ta-form">
              <FormControl variant="outlined">
                <InputLabel htmlFor="lastname" className="fix-display">
                  {t("Nom")}
                  </InputLabel>
                <TextField
                  id="lastname"
                  variant="outlined"
                  size="small"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </FormControl>
              <FormControl variant="outlined">
                <InputLabel htmlFor="name" className="fix-display">
                  {t("Prénom")}
                  </InputLabel>
                <TextField
                  id="name"
                  variant="outlined"
                  size="small"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>
              <FormControl variant="outlined">
                <InputLabel htmlFor="phone" className="fix-display">
                  {t("Numéro de téléphone")}
                  </InputLabel>
                <TextField
                  id="phone"
                  variant="outlined"
                  size="small"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  disabled={informations?.phone ? true : false}
                />
              </FormControl>
              <FormControl variant="outlined">
                <InputLabel htmlFor="email" className="fix-display">
                  {t("Email")}
                  </InputLabel>
                <TextField
                  id="email"
                  variant="outlined"
                  size="small"
                  value={email}
                  disabled={informations?.email ? true : false}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <InputLabel htmlFor="birth-date" className="fix-display">
                  {t("Date de naissance")}
                  </InputLabel>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    disableFuture
                    openTo="year"
                    format="dd/MM/yyyy"
                    views={['year', 'month', 'date']}
                    inputVariant="outlined"
                    variant="outlined"
                    size="small"
                    value={birthDate}
                    onChange={setBirthDate}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
              {
                profileCompleted == true &&
                <FormControl variant="outlined" size="small">
                  <InputLabel id="gender-label" className="fix-display">
                    {t("Sexe")}
                  </InputLabel>
                  <Select
                    labelId="gender-label"
                    id="gender"
                    value={gender || ''}
                    onChange={(e) => setGender(e.target.value)}
                  // label="Sexe"
                  >
                    <MenuItem value={''}>{/* <em>None</em> */}</MenuItem>
                    <MenuItem value="male">{t("masculin")}</MenuItem>
                    <MenuItem value="female">{t("féminin")}</MenuItem>
                  </Select>
                </FormControl>
              }

              {
                profileCompleted == true ?
                  (
                    <FormControl>
                      <InputLabel htmlFor="division" className="fix-display">
                        {t("Classe")}
                        </InputLabel>
                      <TextField
                        style={{direction:'ltr'}}
                        id="division"
                        variant="outlined"
                        size="small"
                        value={division}
                        // onChange={(e) => setPhone(e.target.value)}
                        disabled
                      />
                    </FormControl>
                  ) :
                  (
                    <FormControl variant="outlined" size="small"
                    >
                      <InputLabel htmlFor="division" className="fix-display">
                        {t("Classe")}
                        </InputLabel>
                      <Select
                        id="division"
                        value={division || ''}
                        onChange={(e) => setDivision(e.target.value)}
                      >
                        {
                          divisions && divisions.map((division) => {
                            return (
                              <MenuItem value={division.id}>{division.name}</MenuItem>);
                          })
                        }
                      </Select>
                    </FormControl>
                  )
              }
              <div style={{ padding: "11px 0px" }}>
                <VariantButton
                  label={t("Changer votre classe")}
                  className="red-outlined mt"
                  size="small"
                  onClickAction={handleClickOpen}
                  disabled={(informations?.isTest && !admin) || hasEditDivision ? true : false }
                />
              </div>
              {!hideOptionalSubject && optional_subjects && optional_subjects.length > 0 ? (
              <FormControl variant="outlined" size="small"
                    >
                      <InputLabel htmlFor="optionalSubject" className="fix-display">
                        {t("Optional subject")}
                        </InputLabel>
                      <Select
                        id="optionalSubject"
                        value={optionalSubject || ''}
                        onChange={(e) => setOptionalSubject(e.target.value)}
                      >
                        {
                          optional_subjects && optional_subjects.map((optionalSubject) => {
                            return (
                              <MenuItem value={optionalSubject.id}>{optionalSubject.name}</MenuItem>);
                          })
                        }
                      </Select>
                    </FormControl>):(
                      <></>)}
            </div>
            <VariantButton
              label={t("Sauvegarder les changements")}
              className="blue mb right"
              onClickAction={handlePersoSubmit}
              disabled={!canEdit1}
            />
            <CardTitle title={t("Adresse")} />
            <div className="ta-form">
              <FormControl>
                <InputLabel htmlFor="countries" className="fix-display">
                  {t("Pays")}
                  </InputLabel>
                <Autocomplete
                  id="countries"
                  options={countries || []}
                  value={
                    country !== null && countries !== null
                      ? countries.find((item) => item.id == country)
                      : country || ''
                  }
                  autoHighlight
                  noOptionsText={ t('No options') }
                  getOptionLabel={(option) => option.name || ''}
                  renderOption={(option) => <>{option.name}</>}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                      }}
                    />
                  )}
                  disabled={false}
                  onChange={(e, newValue, r) => {
                    if (r !== 'clear') {
                      setCountry(newValue?.id);
                      setState(null);
                      setDelegation(null);
                      setInstitute(null);
                    } else {
                      setCountry(null);
                      setState(null);
                      setDelegation(null);
                      setInstitute(null);
                    }
                  }}
                />
              </FormControl>

              <FormControl>
                <InputLabel htmlFor="gouvernorat" className="fix-display">
                  {t("Gouvernorat")}
                  </InputLabel>
                <Autocomplete
                  id="gouvernorat"
                  options={states || []}
                  value={
                    state !== null && states !== null
                      ? states.find((item) => item.id == state)
                      : state || ''
                  }
                  autoHighlight
                  noOptionsText={ t('No options') }
                  getOptionLabel={(option) => option.name || ''}
                  renderOption={(option) => <>{option.name}</>}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                      }}
                    />
                  )}
                  disabled={country === null || country !== 1}
                  onChange={(e, newValue, r) => {
                    if (r !== 'clear') {
                      setState(newValue?.id);
                      setDelegation(null);
                      setInstitute(null);
                    } else {
                      setState(null);
                      setDelegation(null);
                      setInstitute(null);
                    }
                  }}
                />
              </FormControl>

              <FormControl>
                <InputLabel htmlFor="delegation" className="fix-display">
                  {t("Délégation")}
                  </InputLabel>
                <Autocomplete
                  id="delegation"
                  options={delegations || []}
                  value={
                    delegation !== null && delegations !== null
                      ? delegations.find((item) => item.id == delegation)
                      : delegation || ''
                  }
                  autoHighlight
                  noOptionsText={ t('No options') }
                  getOptionLabel={(option) => option.name || ''}
                  renderOption={(option) => <>{option.name}</>}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                      }}
                    />
                  )}
                  disabled={state === null}
                  onChange={(e, newValue, r) => {
                    if (r !== 'clear') {
                      setDelegation(newValue?.id);
                      setInstitute(null);
                    } else {
                      setDelegation(null);
                      setInstitute(null);
                    }
                  }}
                />
              </FormControl>

              <FormControl>
                <InputLabel htmlFor="institute" className="fix-display">
                  {t("Institut")}
                  </InputLabel>
                <Autocomplete
                  id="institute"
                  options={institutes || []}
                  value={
                    institute !== null && institutes !== null
                      ? institutes.find((item) => item.id == institute)
                      : institute || ''
                  }
                  autoHighlight
                  noOptionsText={ t('No options') }
                  getOptionLabel={(option) => option.name || ''}
                  renderOption={(option) => <>{option.name}</>}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                  disabled={delegation === null}
                  onChange={(e, newValue, r) => {
                    if (r !== 'clear') {
                      setInstitute(newValue?.id);
                    } else {
                      setInstitute(null);
                    }
                  }}
                />
              </FormControl>
            </div>
            <VariantButton
              label={t("Sauvegarder les changements")}
              className="blue mb right"
              onClickAction={handleStatusSubmit}
              disabled={!canEdit2}
            />
          </div>
        </CardContent>
      </Card>
      <Dialog
        open={open}
        handleClose={() => console.log('close')}
        onSubmitAction={() => submitAvatar(previewCanvasRef.current,
          completedCrop)}
        disabled={!completedCrop?.width || !completedCrop?.height}
      >
        <>
          <ReactCrop
            src={upImg}
            onImageLoaded={onLoad}
            crop={crop}
            onChange={(c) => setCrop(c)}
            onComplete={(c) => setCompletedCrop(c)}
          />
          <div style={{ display: 'none' }}>
            <canvas
              ref={previewCanvasRef}
              // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
              style={{
                width: Math.round(completedCrop?.width ?? 0),
                height: Math.round(completedCrop?.height ?? 0),
              }}
            />
          </div>
        </>
      </Dialog>
      <Dialog
        open={openEdit}
        handleClose={handleClose}
        title={t('Changer votre classe')}
        component={''}
        onSubmitAction={handleEditDivisionSubmit}
      >
        <div className={ i18n.language === 'ar' ? 'ta-form rtl' : 'ta-form' }>
          <FormControl variant="outlined" size="small">
            <InputLabel htmlFor="division" className="fix-display">
              {t("Classe")}
            </InputLabel>
            <Select
              id="division"
              value={divisionEdit}
              onChange={ (e) =>{(e.target?.value===informations?.division_id) ? setEditDivision(null) : setEditDivision(e.target.value)}}
            >
              {
                divisions && divisions.map((division) => {
                  return (
                    <MenuItem value={division.id}>{division.name}</MenuItem>);
                })
              }
            </Select>
          </FormControl>
          <FormControl variant="outlined" size="small">
            <InputLabel htmlFor="division" className="fix-display">
              {t("Raison")}
            </InputLabel>
            <Select
              id="raison"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            >
              {
                reasons && reasons.map((reason) => {
                  return (
                    <MenuItem value={reason.id}>{t(reason.reason)}</MenuItem>);
                })
              }
            </Select>
          </FormControl>
          {
            reason === 4 && <FormControl>
              <InputLabel htmlFor="division" className="fix-display">
                {t("Autre")}
              </InputLabel>
              <TextField
                id="division"
                variant="outlined"
                size="small"
                value={otherReason}
                onChange={(e) => setOtherReason(e.target.value)}

              />
            </FormControl>

          }
        </div>
      </Dialog>
    </>
  );
};

Index.propTypes = {
  informations: PropTypes.object,
  institutes: PropTypes.array,
  countries: PropTypes.array,
  states: PropTypes.array,
  delegations: PropTypes.array,
  dispatch: PropTypes.func,
  avatar: PropTypes.any,
  setAvatar: PropTypes.func,
  name: PropTypes.string,
  setName: PropTypes.func,
  lastName: PropTypes.string,
  setLastName: PropTypes.func,
  phone: PropTypes.string,
  setPhone: PropTypes.func,
  email: PropTypes.string,
  setEmail: PropTypes.func,
  birthDate: PropTypes.any,
  setBirthDate: PropTypes.func,
  gender: PropTypes.string,
  setGender: PropTypes.func,
  country: PropTypes.string,
  setCountry: PropTypes.func,
  state: PropTypes.string,
  setState: PropTypes.func,
  delegation: PropTypes.string,
  setDelegation: PropTypes.func,
  institute: PropTypes.string,
  setInstitute: PropTypes.func,
  division: PropTypes.string,
  setDivision: PropTypes.func,
  isAvatarChanged: PropTypes.bool,
  setIsAvatarChanged: PropTypes.func,
  handleResetAvatar: PropTypes.func,
  handlePersoSubmit: PropTypes.func,
  handleStatusSubmit: PropTypes.func,
  previewFile: PropTypes.func,
};

export default Index;
