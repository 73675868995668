import { createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const slice = createSlice({
  name: 'subjects',
  initialState: {
    exams: [],
    subjects: [],
    isLoading: false,
    error: false,
    exam: [],
    alertMessage: null,
  },
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    examsSuccess: (state, action) => {
      state.exams = action.payload.payload.contents;
      state.subjects = action.payload.payload.subjects;
      state.isLoading = false;
    },
    examSuccess: (state, action) => {
      state.exam = action.payload.data.payload;
      state.isLoading = false;
      state.alertMessage = "Success";
    },
    uploadFilesSuccess: (state, action) => {
      state.alertMessage = action.payload.data.message;
    },
  },
});
export const {
  startLoading,
  hasError,
  examsSuccess,
  examSuccess,
  uploadFilesSuccess,
} = slice.actions;
export default slice.reducer;
export const getExams = () => async (dispatch) => {
  dispatch(startLoading());
  try {
    await api.get('/student/content-exam').then((response) => dispatch(examsSuccess(response.data)));
  } catch (e) {
    return dispatch(hasError(e.message));
  }
};
export const getExam = (id) => async (dispatch) => {
  dispatch(startLoading());
  try {
    await api
      .get(`/student/content-exam/${id}`)
      .then((response) => dispatch(examSuccess(response)));
  } catch (e) {
    return dispatch(hasError(e.message));
  }
};

export const uploadFiles = (examId, data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const formData = new FormData();
    data.map((file, index) => {
      formData.append(`contentFiles[${index}][file]`, file);
    });
    await api.post(`student/content-exam/${examId}`, formData, config).then((response) => {
      dispatch(uploadFilesSuccess(response));
      dispatch(getExam(examId));
    });
  } catch (e) {
    return dispatch(hasError(e.message));
  }
};

export const removeStudentHomework = (contentId, fileId) => async (dispatch) => {
  dispatch(startLoading());
  try {
    await api.delete(`student/content-exam/${contentId}/${fileId}`).then((response) => {
      dispatch(examSuccess(response.data.content));
    });
  } catch (e) {
    return dispatch(hasError(e.message));
  }
};
