import React from 'react';
import Button from '@material-ui/core/Button';
import TA_Logo from '../../assets/img/TxLogoWhite.svg';
import Language from '../Language';
import { Link } from 'react-router-dom';


InfoCard.propTypes = {};

function InfoCard({ title, text, buttonText, link }) {
  // const openLink = (link) => {
  //   window.location.href = link;
  // };
  return (
    <div className="login-info">
      <img alt="logo" src={TA_Logo}/>
      <div className="login-description">
        <div className="description-title">{title}</div>
        <p className="description-text"> {text}</p>
    <Link to={{pathname:link,state:window.location.href.split('?')[1]}}>
        <Button
          variant="outlined"
          disableElevation
          //onClick={() => openLink(link)}
          className="nav-button"
        >
          {buttonText}
        </Button></Link>
        <Language/>
      </div>
    </div>
  );
}

export default InfoCard;
