import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { addPoints, addPointsD17, creditCardRedirect, getPointsHistory, getQrCode } from '../../../redux/slices/points';
import { useSelector, useDispatch } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FormControl from '@material-ui/core/FormControl';
import  {formatMessages}  from '../../../utilities/translateHelper';
import CircularProgress from '@material-ui/core/CircularProgress';

const Index = (props) => {
  const { i18n , t } = useTranslation();
  const { type, onCloseModal } = props;
  const dispatch = useDispatch();
  const [reference, setReference] = useState('');
  const [amount, setAmount] = useState();
  const [comment, setComment] = useState();
  const [message, setMessage] = useState();
  const [severity, setSeverity] = useState();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [update, setUpdate] = useState(false);
  const {isQrCodeLoading, isAddingPointLoading, point, pointError } = useSelector((state) => state.points);
  const successMessage = point && point.message;
  const success = point && point.success;
  const codeId = point && point.id;

  useEffect(() => {
    if (type === '2' && success) {
      dispatch(creditCardRedirect(codeId));
    }
    if (type ==='3' && success){
      dispatch(getQrCode(codeId))
    }

    if (type === '1' && success){
      dispatch(getPointsHistory())
    }
  }, [point, pointError, update]);


   const codeValidation = (reference, amount) =>  {
    if (!amount) {
      return { success: false, 'message': t('Le champ montant est obligatoire') };
    }
    if(type == '1' && !reference){
      return { success: false, 'message': t('Le champ référence est obligatoire') };
    }
    if (amount < 0) {
      return { success: false, 'message': t('Le champ montant doit être positif') };
    }
     if(/\s/g.test(reference)){
      return { success: false, 'message': t('Le champ référence est invalide') };
    }
  }
  const handleSubmit = () => {
    const validation = codeValidation(reference, amount);
    if (validation) {
      setMessage(validation.message);
      setSeverity('warning');
      setOpenSnackBar(true);
    }
    else {
    setUpdate(false);
    dispatch(type===('1'||'2')?addPoints(reference, amount, comment,type):addPointsD17(reference, amount, comment,type));
    setOpenSnackBar(true);
    setReference('');
    setAmount('');
    setComment('');
    setMessage('')
    setSeverity('')
    }
  };
  return (
    <div className={i18n.language === 'ar' ? 'rtl' : 'base'}>
      {type === '1' ? (
        <div>
          <FormControl variant="outlined">
            <TextField
              autoFocus
              outlined
              id="code"
              value={reference}
             // label={t("Entrez votre code")}
              placeholder={t("Entrez votre code")}
              type="string"
              margin="dense"
              variant="outlined"
              className="basic-fields"
              style={{ width: 350, height: 40 }}
              onChange={(e) => setReference(e.target.value)}
            />
          </FormControl>
          <FormControl variant="outlined">
            <TextField
              margin="dense"
              id="montant"
              value={amount}
             // label={t("Montant en dinar")}
              placeholder={t("Montant en dinar")}
              type="number"
              variant="outlined"
              className="basic-fields"
              style={{ width: 350, height: 40 }}
              onChange={(e) => setAmount(e.target.value)}
            />
          </FormControl>
          <FormControl variant="outlined">
            <TextField
              margin="dense"
              id={t("description")}
              value={comment}
              //label={t("Description")}
              placeholder={t("Description")}
              type="string"
              variant="outlined"
              className="basic-fields"
              style={{ width: 350, height: 40 }}
              onChange={(e) => setComment(e.target.value)}
            />
          </FormControl>
        </div>
      ) : (
        <div style={{alignItems:'center',display:'flex',justifyItems:'center',flexDirection:'column'}}>
         { (!isQrCodeLoading)?<TextField
            autoFocus
            margin="dense"
            id="montant"
            value={amount}
            //label={t("Montant en dinar")}
            placeholder={t("Montant en dinar")}
            type="number"
            variant="outlined"
            className="basic-fields"
            style={{ width: 350, height: 40 }}
            onChange={(e) => setAmount(e.target.value)}
          /> :  <div style={{alignItems:'center',display:'flex',justifyItems:'center',flexDirection:'column'}}>
            <CircularProgress style={{color:'#50bf88',height:'70px',width:'70px'}}/><span className='Qrloader'>{t('Creation de Qr Code')}</span></div>}
        </div>
      
      )}

     <div className="basic">
      <Button 
        disabled={isAddingPointLoading || isQrCodeLoading} 
        onClick={(event) => handleSubmit(event)} 
        className="buttonAdd" 
        style={{ left: 200 }}
        >
        {t("Ajouter des points")}
      </Button>
      </div>
      {
        (successMessage || pointError || message) &&
          <Snackbar
            open={openSnackBar}
            autoHideDuration={3000}
            anchorOrigin={{
              vertical: 'Bottom',
              horizontal: 'center',
            }}
            onClose={() => setOpenSnackBar(false)}
          >
            <Alert
              severity={successMessage && !message ? 'success' : 'warning'}
              onClose={() => setOpenSnackBar(false)}
            >
              {message ? message: successMessage ? t(formatMessages(successMessage)) : t(formatMessages(pointError))}
            </Alert>
        </Snackbar>
      }
     
    </div>
  );
};

Index.propTypes = {
  type: PropTypes.string,
  onCloseModal: PropTypes.func,
};

export default Index;
