import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import ViewTitle from '../../components/ViewTitle';
import OfferCard from './OfferCard';
import Typography from '@material-ui/core/Typography';
import {useDispatch, useSelector} from 'react-redux';
import {getOffers} from '../../redux/slices/offers';
import {Skeleton} from '@material-ui/lab';
import WindowDimensions from '../../utilities/windowDimenssions';
import { getDiscountCodes } from '../../redux/slices/points';
import moveContent from '../../assets/img/icons/moveContent.png';



const Index = () => {
  const { i18n,t } = useTranslation();
  const {offers} = useSelector((state) => state.offers);
  const { informations } = useSelector((state) => state.user);
  const {width} = WindowDimensions();
  const dispatch = useDispatch();
  const [updateOffers, setUpdateOffers] = useState(false);
  const [division, setDivision] = useState(null);
  const offersList = [...offers];
  const prepaDivisionsId = [34,33,30,29,28,27,26,25,24,23];

  useEffect(() => {
    dispatch(getOffers());
    dispatch(getDiscountCodes())
  }, [dispatch, updateOffers]);

  useEffect(() => {
  if (informations) {
    setDivision(informations.division_id || null);
  }
}, [informations]);

  if (offers?.length < 0) {
    return (
        <div>
          <Skeleton variant="text" width={'10%'}/>
          <br/>
          <br/>
          <div className="ta-row">
            <div className="offers-card">
              {width <= 750 ?
                  <Skeleton className="offer-card" variant="rect"
                            width={260} height={'750px'}/>
                  :
                  <>
                    <Skeleton className="offer-card" variant="rect"
                              width={width / 5} height={'750px'}/>
                    <Skeleton className="offer-card" variant="rect"
                              width={width / 5} height={'750px'}/>
                    <Skeleton className="offer-card" variant="rect"
                              width={width / 5} height={'750px'}/>
                  </>
              }

            </div>
          </div>
        </div>
    );
  } else {
    return (
        <div>
          <ViewTitle title={t('Offres')}/>
          {(prepaDivisionsId.indexOf(division) !== -1) &&
          <div className='prepa-Annonce' style={{marginLeft:"540px"}}>
                <Typography gutterBottom variant="h5" component="h2">
                <a href='https://ostedhy.com/' target="_blank" color="inherit">
                    <span style={{marginLeft:"20px", fontWeight: 'bold', fontSize: "18px"}}>  https://ostedhy.com/ </span></a>
                  <span style={{fontSize: "16px", color:"black"}}>
                  : تمّ تحويل كامل المحتوى إلى منصّة "أستاذي" التّعليميّة
                  </span>
                </Typography>
                <img className="moveContent" style={{height:"273px", width:"600px"}} alt="moveContent" src={moveContent} />
          </div>}
          <div className="ta-row">
            <div className="offers-card ">
              {
              offersList.sort((a, b) => (a.offer?.position > b.offer?.position) ? 1 : -1)
              .map((offer, index) => {
                return (
                    <OfferCard
                        offer={offer}
                        updateOffer={updateOffers}
                        setUpdateOffer={setUpdateOffers}
                        width={width}
                        offerBookMark={offers?.length - 1 === index}
                        offersLength={offers?.length}
                    />
                );
              })}
            </div>
          </div>
     
        </div>
    );
  }
};

export default Index;
