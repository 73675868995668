import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDivisions,
  getGeoInfo,
  getSocialLoginUrls,
  getStates,
  register,
} from '../../redux/slices/auth';
import { getOptionalSubjects } from '../../redux/slices/subjects';
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import LockIcon from '@material-ui/icons/Lock';
import PersonIcon from '@material-ui/icons/Person';
import fbIcon from '../../assets/img/icons/facebook2.svg';
import googlePlus from '../../assets/img/icons/google-plus.svg';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import InputCard from '../../components/AuthComponents/InputCard';
import DateRangeIcon from '@material-ui/icons/DateRange';
import SelectInput from '../../components/AuthComponents/SelectInput';
import HomeIcon from '@material-ui/icons/Home';
import SchoolIcon from '@material-ui/icons/School';
import InfoCard from '../../components/AuthComponents/InfoCard';
import { Link } from 'react-router-dom';
import { registerValidation } from '../../components/AuthComponents/AuthValidation';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHelperText } from '@material-ui/core';
import  {formatMsgBackend}  from '../../utilities/translateHelper';
import Language from '../../components/Language';
import Cookies from 'js-cookie';
import { getCountries } from '../../redux/slices/places';
import { useLocation } from 'react-router-dom';

const Index = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
const location=useLocation()
 window.history.pushState( null, null, location.state!==undefined?"?"+location.state:"")
  const { authResult, success, loginSocialLinks,isLoading, geoIp, divisions, states, error } = useSelector(
    (state) => state.auth
  );
  const { countries } = useSelector((state) => state.places);
  const { optional_subjects } = useSelector((state) => state.subjects);
  const [validationResult, setValidationResult] = useState(authResult);
  const [validationStatus, setValidationStatus] = useState(success);
  const [values, setValues] = useState({});
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openButton, setOpenButton] = useState(false);

  const recaptchaRef = useRef();
  const divisionId = values && values.division;
  useEffect(()=>{
  if(error){
  setOpenButton(false)
  }

  },[error])
  const geoCookie=Cookies.get('GeoIp')

  useEffect(() => {
    setValidationResult(authResult);
  }, [authResult]);
  useEffect(() => {
    setValidationStatus(success);
  }, [success]);
  useEffect(() => {
    //geoIp &&
      setValues((prevState) => ({
        ...prevState,
        'country': (((geoIp && geoIp.country === 'TN')|| geoCookie && geoCookie === 'TN') || (!geoIp && !geoCookie) ) ? 1 : '',
        //'country':  1 ,
      }));
  }, [geoIp,geoCookie]);
  useEffect(() => {
    if(!geoCookie){
    dispatch(getGeoInfo())};
    //dispatch(getSocialLoginUrls());
    dispatch(getDivisions());
    dispatch(getStates());
    dispatch(getCountries());
  }, [dispatch]);
  useEffect(() => {
    if (divisionId && !isNaN(divisionId)) {
      dispatch(getOptionalSubjects(divisionId));
    }
  }, [divisionId]);
  useEffect(() => {
    if (authResult) {
     setOpenSnackBar(true)
    }
   
  }, [authResult]);

  const handleSubmit = async (e) => {
    setOpenButton(true)
    const validation = registerValidation(values, geoCookie ? geoCookie : geoIp, optional_subjects);
    if (validation) {
      setValidationResult(validation.message);
      setValidationStatus(validation.success);
      setOpenButton(false)
      return setOpenSnackBar(true);
    }
    e.preventDefault();
    recaptchaRef.current.execute();

  };

  // const handleSocialLogin = (e, provider) => {
  //   if (loginSocialLinks) {
  //     window.open(loginSocialLinks[provider], '_self');
  //   }
  // };

  const handleSocialLogin = (provider) => {
    window.open(`${process.env.REACT_APP_API_URL}/auth/connect/${provider}`, '_self');
  };
  const handleChange = (e) => {
    setValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const handlePasswordChange = (e) => {
    setValues((prevState) => ({
      ...prevState,
      plainPassword: {
        ...prevState.plainPassword,
        [e.target.name]: e.target.value,
      },
    }));
  };
  const handleSelect = (e, value, name) => {
    setValues((prevState) => ({
      ...prevState,
      [name]: value && value.id,
    }));
  };
  const onRecaptchaChange = async (value) => {
    let data = values;
    data['g-recaptcha-response'] = value;
    dispatch(register(data));
    //setOpenSnackBar(true);
  };
  return (
    <div className="login-card">
      <div className="centered-box">
        <span className="title">{t("Créer un compte")}</span>
        <InputCard
          typeInput={'text'}
          value={values.name}
          name="name"
          placeholder={t("Prénom de l élève")}
          handleFunction={handleChange}
          icon={<PersonIcon className="icon-input" />}
        />
        <InputCard
          typeInput={'text'}
          value={values.lastName}
          name="lastName"
          placeholder={t("Nom de l élève")}
          handleFunction={handleChange}
          icon={<PersonIcon className="icon-input" />}
        />
        {(((geoIp && geoIp.country === 'TN')|| geoCookie && geoCookie === 'TN') || (!geoIp && !geoCookie) ) ? (
          <>
            <InputCard
              typeInput={'text'}
              value={values.phone}
              name="phone"
              placeholder={t("Numéro de téléphone de l élève")}
              handleFunction={handleChange}
              icon={<PhoneIphoneIcon className="icon-input" />}
            />
            <p>{t("Vous allez recevoir un SMS sur ce numéro, soyez sûr qu'il est correct")}</p>
          </>
        ) : (
            <InputCard
              typeInput={'text'}
              value={values.email}
              name="email"
              placeholder={t("Email de l élève")}
              handleFunction={handleChange}
              icon={<EmailIcon className="icon-input" />}
            />
          )}
        <InputCard
          typeInput={'date'}
          value={values.birthDate}
          name="birthDate"
          placeholder={t("Date de naissance de l élève")}
          handleFunction={handleChange}
          icon={<DateRangeIcon className="icon-input" />}
        />
        <FormHelperText><span style={{ color: 'red' }}>* </span>{t("Date de naissance")}</FormHelperText>
        <InputCard
          typeInput={'password'}
          value={values.plainPassword?.first}
          name="first"
          placeholder={t("Mot de passe minimum 8 caractères")}
          handleFunction={handlePasswordChange}
          icon={<LockIcon className="icon-input" />}
        />
        <InputCard
          typeInput={'password'}
          value={values.plainPassword?.second}
          name="second"
          placeholder={t('Confirmez le mot de passe')}
          handleFunction={handlePasswordChange}
          icon={<LockIcon className="icon-input" />}
        />
        {(geoIp && geoIp.country !== 'TN' || geoCookie && geoCookie !== 'TN') ? (
        <>
        <SelectInput
            name="country"
            placeholder={t('Choisissez votre pays')}
            handleFunction={handleSelect}
            icon={<HomeIcon className="icon-input" />}
            options={countries || []}
        />
        </>
        ) : (
          <SelectInput
            name="state"
            placeholder={t('Choisissez votre état')}
            handleFunction={handleSelect}
            icon={<HomeIcon className="icon-input" />}
            options={states || []}
         />
        )}
        <SelectInput
          placeholder={t('Choisissez votre classe')}
          icon={<SchoolIcon className="icon-input" />}
          options={divisions || []}
          handleFunction={handleSelect}
          //onChange={(e) => setDivision(values && values.division_id)}
          name="division"
        />
        {optional_subjects && optional_subjects.length > 0 && (
          <SelectInput
            placeholder={t('Choisissez votre option')}
            icon={<SchoolIcon className="icon-input" />}
            options={optional_subjects}
            handleFunction={handleSelect}
            //onChange={(e) => setDivision(values && values.division_id)}
            name="optionalSubject"
          />
        )}
        <Button
          variant="outlined"
          disableElevation
          onClick={(e) => handleSubmit(e)}
          className="submit-login"
          disabled={openButton}
        >
          {t("Inscription")}
        </Button>
        <div className="language-inscription">
        <span className="inscription">{t("Vous avez déjà un compte")} <Link to="/login" className="reset-password">
          {t("Se connecter")}
        </Link></span>
        <Language WhiteBox className="Language"/>
        </div>

        {/* <p className="text-muted">{t('Ou utilisez votre compte gmail')}</p>
        {
          //loginSocialLinks && (
          <div className="social-login">
            <Button
              variant="outlined"
              disableElevation
              onClick={(event) => handleSocialLogin('google')}
              className="login-google"
            >
              <img src={googlePlus} className="google-icon" />
              Google
            </Button>
          </div>
          //)
        } */}
      </div>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={process.env.REACT_APP_RECAPATCHA_KEY}
        size="invisible"
        onChange={onRecaptchaChange}
      />
      <InfoCard
        text={t("Pour rester en contact avec nous, veuillez vous connecter avec vos informations personnelles")}
        title={t("Bon retour parmi nous")}
        buttonText={t('Se connecter')}
        link="/login"
      />
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'Bottom',
          horizontal: 'Center',
        }}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert
          severity={success === false ? 'error' : 'success'}
          onClose={() => setOpenSnackBar(false)}
        >
          {t(formatMsgBackend(validationResult))}
        </Alert>
      </Snackbar>
    </div>
  );
};

Index.propTypes = {
  dispatch: PropTypes.func,
  email: PropTypes.string,
  setEmail: PropTypes.func,
  password: PropTypes.string,
  setPassword: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default Index;
