import { AppBar, fade, IconButton, makeStyles, Toolbar } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import TakiacademyLogo from '../../../assets/img/TakiacademyLogo.png';

const useStyles = makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.up('sm')]: {
      // width: `calc(100% - ${drawerWidth}px)`,
      // marginLeft: drawerWidth,
      width: '100%',
      marginLeft: 0,
      zIndex: 2000,
    },
    background: '#ffffff',
    color: '#000000',
    boxShadow: '0px 1px 2px 0px rgba(0,0,0,0.2) !important',
    zIndex: 2000,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  menuButton2: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  grow: {
    flexGrow: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    border: '1px solid #d6d6da',
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
},
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
}));

const Index = ({ handleDrawerToggle, mobileOpen }) => {
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <div className="navContent">
          <a href="https://www.takiacademy.com/">
            <img
              className=""
              style={{ width: '80px', height: '70px', margin: '5px' }}
              src={TakiacademyLogo}
            ></img>
          </a>
        </div>
        <div className="navigation">
          <a className="navigation-login" href={process.env.REACT_APP_DIGITAL_VIRGO_LOGIN}>
            Connexion
          </a>
          <a className="navigation-register" href={process.env.REACT_APP_DIGITAL_VIRGO_REGISTER}>
            Inscription
          </a>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Index;
