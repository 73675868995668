import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/api';
import { ord } from '../../utilities/methods';
import { closeSnackbar, enqueueSnackbar } from './notifier';
import { formatMessages } from '../../utilities/translateHelper';
import imAvatar from '../../assets/img/icons/avatar.png';
import axios from 'axios';

const initialUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;


export const me = () => async (dispatch) => {
  try {
    return await api.get('/auth/me').then((res) => {
      dispatch(meSuccess(res.data.payload));
      if (res && res.data?.payload) {
        let offers = null;
        if (res.data?.payload?.user?.affiliations.length >0) {
          offers = res.data?.payload?.user?.affiliations.map((offer) => offer.group.name).join(', ');
        }
        const idUser = res.data?.payload.user?.id;
        const email = res.data?.payload.user?.email;
        window.$takiChat.push({
          id: idUser.toString(),
          name: res.data?.payload?.user?.last_name+' '+res.data?.payload?.user?.name,
          email: email ? email.toString() : 'no-email@gmail.com',
          phone: res.data?.payload.user?.phone,
          optional: [
            {
              key: "classe",
              value: res.data?.payload.user?.division ? res.data?.payload.user.division?.name : 'no division',
            },
            {
              key: "offers",
              value: offers !== null ? offers : 'No offer',
            },
            /*  {
                key: "avatar",
                value: res.data?.payload?.user?.avatar,
              },*/
            {
              key: "createdAt",
              value: res.data?.payload?.user?.created_at,
            },
          ],
        });
      /*  window.$crisp.push([
          'set',
          'user:nickname',
          res.data?.payload?.user?.last_name + ' ' + res.data?.payload?.user?.name,
        ]);
        if (res.payload?.user?.avatar) {
          window.$crisp.push(['set', 'user:avatar', res.data?.payload?.user?.avatar]);
        }
        if (res.data?.payload.user?.phone) {
          window.$crisp.push(['set', 'user:phone', res.data?.payload.user?.phone]);
        }
        if (res.data?.payload.user?.email) {
          window.$crisp.push(['set', 'user:email', res.data?.payload.user?.email]);
        }
        //$crisp.push(["set", "user:phone", res.data.phone ? res.data.phone : '11111111'])
        // window.$crisp.push(["set", "user:email", res.data.email ? res.data.email : 'crisp-guest-mail@gmail.com']);
        window.$crisp.push([
          'set',
          'session:data',
          [
            [
              ['name', res.data?.payload?.user?.last_name + ' ' + res.data?.payload?.user?.name],
              ['id', res.data?.payload.user?.id],
              [
                'classe',
                res.data?.payload.user?.division
                  ? res.data?.payload.user.division?.name
                  : 'no division',
              ],
              ['offers', offers !== null ? offers : 'No offer'],
              ['createdAt', res.data?.payload?.user?.created_at],
            ],
          ],
        ]);*/
      }
    });
  } catch (e) {
    if (e?.response?.status === 401) {
      dispatch(logoutSuccess());
      window.location = '/login';
    }

    return console.error(e.message);
  }
};

export const editProfile = (data) => async (dispatch) => {
  const new_key = new Date().getTime() + Math.random();
  try {
    dispatch(
      enqueueSnackbar({
        message: 'En traitement...',
        options: {
          key: new_key,
          variant: 'info',
          persist: true,
        },
      })
    );
    const res = await api.post('user/update', data);
    dispatch(me());
    dispatch(closeSnackbar(new_key));
    dispatch(
      enqueueSnackbar({
        message: res.data.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
        },
      })
    );
    if (res.data.credential) {
      window.location = '/confirm';
      localStorage.setItem('credential', res.data.credential);
    }
  } catch (e) {
    if (e.response) {
      let message = '';
      let key;
      if (e.response.data.errors) {
        if (e.response.data.errors.birthDate) {
          for (key in e.response.data.errors.birthDate) {
            message = 'Birthdate: '.concat(e.response.data.errors.birthDate[0]);
          }
        } else if (e.response.data.errors.name) {
          for (key in e.response.data.errors.name) {
            message = 'Name: '.concat(e.response.data.errors.name[0]);
          }
        } else if (e.response.data.errors.lastName) {
          for (key in e.response.data.errors.lastName) {
            message = 'Last Name: '.concat(e.response.data.errors.lastName[0]);
          }
        } else {
          for (key in e.response.data.errors) {
            message = e.response.data.errors[key][0];
          }
        }
      } else {
        message = e.response.data.message;
      }
      dispatch(closeSnackbar(new_key));
      dispatch(
        enqueueSnackbar({
          message: formatMessages(message),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
    }
    return console.error(e.message);
  }
};

export const editStatus =
  ({
    country,
    state,
    delegation,
    institute,
    name,
    lastName,
    birthDate,
    phone,
    email,
    division,
    optionalSubject,
    gender,
  }) =>
  async (dispatch) => {
    const new_key = new Date().getTime() + Math.random();
    try {
      dispatch(
        enqueueSnackbar({
          message: 'En traitement...',
          options: {
            key: new_key,
            variant: 'info',
            persist: true,
          },
        })
      );
      const res = await api.put('/user/update', {
        country,
        state,
        delegation,
        institute,
        name,
        lastName,
        birthDate,
        phone,
        email,
        division,
        optionalSubject,
        gender,
      });
      dispatch(me());
      dispatch(closeSnackbar(new_key));
      dispatch(
        enqueueSnackbar({
          message: res.data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        })
      );
    } catch (e) {
      dispatch(closeSnackbar(new_key));
      dispatch(
        enqueueSnackbar({
          message: e.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      if (e?.response?.status === 401) {
        dispatch(logoutSuccess());
        window.location = '/login';
      }
      return console.error(e.message);
    }
  };

export const editPassword =
createAsyncThunk('editPassword',
  async ({ oldPassword, newPassword, new_password_confirmation }, thunkAPI) => {
    const new_key = new Date().getTime() + Math.random();
    try {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: 'En traitement...',
          options: {
            key: new_key,
            variant: 'info',
            persist: true,
          },
        })
      );
      const res = await api.put('/user/change-password', {
        oldPassword,
        newPassword,
        //new_password_confirmation,
      });
      thunkAPI.dispatch(closeSnackbar(new_key));
      if(res.status === 200){
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: 'Le Mot de Passe est Modifié',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        })
      );
      return await res.data;
      }
      throw new Error(res.statusText);
    } catch (e) {
      if (e.response) {
        let message = '';
        let key;
        if (e.response.data.errors) {
          for (key in e.response.data.errors) {
            message = e.response.data.errors[key][0];
          }
        } else {
          message = e.response.data.message;
        }
        thunkAPI.dispatch(closeSnackbar(new_key));
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error',
              anchorOrigin: {
                vertical: 'Bottom',
                horizontal: 'center',
              },
            },
          })
        );
      }

      return console.error(e.message);
    }
  });
export const getSubscriptionWarning = () => (dispatch) => {
  return api
    .get(`alerts`)
    .then((response) => {
      dispatch(warningSuccess(response.data));
    })
    .catch((e) => {});
};

export const completeRegister = (data) => async (dispatch) => {
  const new_key = new Date().getTime() + Math.random();
  try {
    const res = await api.post('user/update', data);
    dispatch(me());
    dispatch(closeSnackbar(new_key));
    dispatch(
      enqueueSnackbar({
        message: res.data.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
        },
      })
    );
    if (res.data) {
      window.location = '/confirm';
    }
  } catch (e) {
    if (e.response) {
      let message = '';
      let key;
      if (e.response.data.errors) {
        for (key in e.response.data.errors) {
          message = e.response.data.errors[key][0];
        }
      } else {
        message = e.response.data.message;
      }
      dispatch(closeSnackbar(new_key));
      dispatch(
        enqueueSnackbar({
          message: message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
    }
    return console.error(e.message);
  }
};

export const getReasons = () => async (dispatch) => {
  try {
    await api.get(`/edit-division-reasons`).then((response) => dispatch(reasonsSuccess(response)));
  } catch (error) {
    throw error;
  }
};

export const editDivision = (newDivision, reason2, reason) => async (dispatch) => {
  try {
    const res = await api.post(`/edit-division`, {
      newDivision,
      reason2,
      reason,
    });
    dispatch(me());
    dispatch(
      enqueueSnackbar({
        message: res.data.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
        },
      })
    );
  } catch (e) {
    if (e.response) {
      let message = '';
      let key;
      if (e.response.data.errors) {
        for (key in e.response.data.errors) {
          message = e.response.data.errors[key][0];
        }
      } else {
        message = e.response.data.message;
      }
      return console.error(message);
    }
  }
};


// Slice
const slice = createSlice({
  name: 'user',
  initialState: {
    user: initialUser,
    informations: null,
    isLoading: false,
    permissions: {},
    loginSocialLinks: null,
    geoIp: null,
    divisions: null,
    states: null,
    success: null,
    digitalVirgo:false,
    editPasswordData : {
      status : 'idle',
      data: [],
      error: null,
    }
  },
  reducers: {
    startLoading: (state, action) => {
      state.isLoading = true;
    },

    meSuccess: (state, action) => {
      const options = [
        { label: 'id', accessor: 'user.id' },
        { label: 'name', accessor: 'user.name' },
        { label: 'first_name', accessor: 'user.name' },
        { label: 'last_name', accessor: 'user.last_name' },
        { label: 'email', accessor: 'user.email' },
        { label: 'phone', accessor: 'user.phone' },
        { label: 'gender', accessor: 'user.gender' },
        { label: 'birth_date', accessor: 'user.birth_date' },
        { label: 'avatar', accessor: 'user.avatar' },
        { label: 'points', accessor: 'user.points' },
        { label: 'division_id', accessor: 'user.division.id' },
        { label: 'division', accessor: 'user.division.name' },
        { label: 'optionalSubject', accessor: 'user.optional_subject.name' },
        { label: 'optionalSubject_id', accessor: 'user.optional_subject.id' },
        { label: 'country', accessor: 'user.county.name' },
        { label: 'state_id', accessor: 'user.state.id' },
        { label: 'state', accessor: 'user.state.name' },
        { label: 'delegation_id', accessor: 'user.delegation.id' },
        { label: 'delegation', accessor: 'user.delegation.name' },
        { label: 'institute_id', accessor: 'user.institute.id' },
        { label: 'institute', accessor: 'user.institute.name' },
        { label: 'offers', accessor: 'user.affiliations' },
        { label: 'completed', accessor: 'user.completed' },
        { label: 'verified', accessor: 'user.verified' },
        { label: 'country_id', accessor: 'user.country.id' },
        { label: 'google_id', accessor: 'user.google_id' },
        { label: 'facebook_id', accessor: 'user.facebook_id' },
        { label: 'hasSession', accessor: 'user.hasSession' },
        { label: 'freeHours', accessor: 'user.freeHours' },
        { label: 'isTest', accessor: 'user.istest' },
        { label: 'hasEditDivision', accessor: 'user.hasEditDivision' },
        { label: 'businessId', accessor: 'user.business.id' },
        { label: 'takeTrialAdvantage', accessor: 'user.takeTrialAdvantage' },
      ];
      state.permissions = {
        'forum': action.payload.permissions.has_forum,
        'exam': action.payload.permissions.has_exam,
        'isProfSon': action.payload.permissions.isProfSon,
        'versPilote': action.payload.permissions.versPilote,
        'freeHours': action.payload.permissions.freeHours,
        'discount': action.payload.discountCode,
        'discountType': action.payload.discountType,
        'discountAmount': action.payload.discountAmount,
        'hasEditDivision': action.payload.permissions.hasEditDivision,
        'influencer': action.payload.permissions.influencer,
        'takeTrialAdvantage': action.payload.permissions.takeTrialAdvantage,
        'changePassword': action.payload.permissions.changePassword,
      };
      state.informations = ord(action.payload, options);
      state.digitalVirgoClient=action.payload.digitalVirgoClient
      state.success = true;
    },
    warningSuccess: (state, action) => {
      state.warning = action.payload.payload;
    },
    logoutSuccess: (state, action) => {
      state.user = null;
      state.informations = null;
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('token_type');
      localStorage.removeItem('expires_at');
    },
    reasonsSuccess: (state, action) => {
      state.reasons = action.payload.data.payload;
    },
    EditDivisionSuccess: (state, action) => {
      state.editSuccess = action.payload.data;
    },
  },
  extraReducers: {
    [editPassword.pending]: (state) => {
        state.editPasswordData.status = 'loading';
    },
    [editPassword.fulfilled]: (state, action) => {
        state.editPasswordData.status = 'succeeded';
        state.editPasswordData.data = action.payload;
    },
    [editPassword.rejected]: (state) => {
        state.editPasswordData.status = 'failed';
    },
  },
});
export default slice.reducer;
// Actions
const { meSuccess, startLoading, warningSuccess, logoutSuccess, reasonsSuccess } = slice.actions;