import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import './App.scss';
import { useDarkMode } from './utilities/useDarkMode';
import { useTranslation } from 'react-i18next';
import Routes from './routes/Routes';
import { Header, Notifier, Sidebar } from './layouts';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { isAuthenticated } from './utilities/auth';
import { useSelector } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import Login from './pages/Login';
import SwitchUser from './components/SwitchUser/SwitchUser';
import WindowDimensions from './utilities/windowDimenssions';
import { calenderMobileWidth } from './utilities/constants';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { switchUser } from './redux/slices/auth';
import { Card } from '@material-ui/core';
import { getClicToPaySuccess, getClicToPayEchec } from './redux/slices/points';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import useSettings from './hooks/useSettings';
import { ValidationModal } from './components';
import { digitalVirgoEchec, digitalVirgoSuccess } from './redux/slices/offers';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function App() {
  const { i18n } = useTranslation();
  const { settings } = useSettings();
  const { width } = WindowDimensions();
  const user = localStorage.getItem('token');
  const { clickErrorMessage, clickSuccessMessage } = useSelector((state) => state.points);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [open, openModal] = useState(false);
  const [digitalVergoResponse,setDigitalVergoResponse]=useState(false)
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [severity, setAlertSeverity] = useState('success');
  const [message, setAlertMessage] = useState('');
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  useEffect(() => {
    if (window.location.href.includes('switch')) {
      dispatch(switchUser());
    }
  }, [dispatch]);

  useEffect(() => {
    if (window.location.href.includes('payment/success')) {
      const search = window.location.search;
      const query = queryString.parse(search);
      const orderId = query.orderId;
      dispatch(getClicToPaySuccess(orderId));
      setAlertSeverity('success');
      setAlertMessage(clickSuccessMessage);
    }
    if (window.location.href.includes('payment/echec')) {
      const search = window.location.search;
      const query = queryString.parse(search);
      const orderId = query.orderId;
      setAlertSeverity('warning');
      setAlertMessage('Payment Error');
      dispatch(getClicToPayEchec(orderId));
    }
  }, [dispatch]);
  useEffect(() => {
    if (window.location.href.includes('digitalvirgo/success')) {
   
      const search = window.location.search;
      dispatch(digitalVirgoSuccess(search));
      openModal(true)
      setDigitalVergoResponse(true)
    }
    if (window.location.href.includes('digitalvirgo/echec')) {
      const search = window.location.search;
      const query = queryString.parse(search);
      //const orderId = query.orderId;
      setAlertSeverity('warning');
      setAlertMessage('Payment Error');
      dispatch(digitalVirgoEchec(search));
      openModal(true)
    }
  }, [dispatch]);


  const [theme, toggleTheme, componentMounted] = useDarkMode();
  if (!componentMounted) {
    return <div />;
  }
  const wrapperClass = ` wrapper${theme === 'dark' ? ' dark' : ''}${
    i18n.language === 'ar' ? ' rtl' : ''
  }${settings.sidebar === 'full' ? ' sidebar-full' : ' sidebar-mini'}`;
  const previousUrl = document.referrer;
  if (previousUrl.includes('?nextPage=offres')) {
    const url = new URL(window.location);
    url.searchParams.set('nextPage', 'offres');
    window.history.pushState({}, '', url);
  }
  return (
    <div className={classes.root + wrapperClass}>
      <CssBaseline />
      <SnackbarProvider
        maxSnack={12}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Notifier />

        <BrowserRouter>
          {isAuthenticated(user) ? (
            <>
              <Header
                mobileOpen={mobileOpen}
                handleDrawerToggle={handleDrawerToggle}
                theme={theme}
                toggleTheme={toggleTheme}
              />
              <Sidebar mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
              <div className={classes.toolbar + ` ta-toolbar`} />
              <main
                className={
                  width <= calenderMobileWidth && window.location.pathname === '/sessions'
                    ? null
                    : classes.content
                }
              >
                <div className={classes.toolbar + ` ta-toolbar`} />
                <Routes />
              </main>
            </>
          ) : window.location.href.includes('switch') ? (
            <>
              <Route exact path="switch" component={SwitchUser} />
            </>
          ) : (
            <>
              <Routes />
            </>
          )}
        </BrowserRouter>
        <ValidationModal
      open={open}
      setOpen={openModal}
      success={digitalVergoResponse}
      />
      </SnackbarProvider>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'Bottom',
          horizontal: 'center',
        }}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert severity={severity} onClose={() => setOpenSnackBar(false)}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default App;
