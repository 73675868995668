import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { CardTitle, ResponsiveTable, TransactionsResponsiveTable } from '..';
import { allTransactions } from '../../redux/slices/points';
import { useDispatch, useSelector } from 'react-redux';

const Index = () => {
  const { t } = useTranslation();
  const { transactions } = useSelector((state) => state.points);
  const dispatch = useDispatch();
  const headers = [
    { accessor: 'sender_id', label: t('ID Expéditeur') },
    { accessor: 'sender_name', label: t('Expéditeur') },
    { accessor: 'receiver_id', label: t('ID Destinataire') },
    { accessor: 'receiver_name', label: t('Destinataire') },
    { accessor: 'points', label: t('Points') },
    { accessor: 'updated_at', label: t('Envoyé le'), type: 'date' },
    { accessor: 'status', label: t('Statut') },
  ];
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    dispatch(allTransactions());
  }, []);

  React.useEffect(() => {
    if (transactions) {

      setData(transactions || []);
    }
  }, [transactions]);

  return (
    <Card className="ta-card">
      <CardHeader title={<CardTitle title={t("historique de transfert des points")} />} />
      <CardContent>
        <div className="child-full">
          <TransactionsResponsiveTable cols={headers} rows={data || []} />
        </div>
      </CardContent>
    </Card>
  );
};

export default Index;
