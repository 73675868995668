import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import OfferPeriod from '../../pages/Offers/OfferPeriod';
import OfferOoredooPeriods from '../../pages/Offers/OfferOoredooPeriods';
import OfferPriceCards from '../../pages/Offers/OfferPriceCards';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  TextField,
  Typography,
  InputAdornment,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import { Dialog, DialogContent } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { addDiscountCode } from '../../redux/slices/points';
import CouponItem from './couponItem';
import { formatMessages } from '../../utilities/translateHelper';
import OoredooItem from './OoredooModals/OoredooItem';
import PaimentOoreedoo from './OoredooModals/PaimentOoreedoo';
import { digitalvirgoRedirect } from '../../redux/slices/offers';
import Im_paiment from '../../assets/img/icons/paiment.svg';

const useStyles = makeStyles((theme) => ({
  input: {
    borderRadius: '5px',
    color: 'black',
    backgroundColor: 'white',
    fontWeight: 'bold',
    fontSize: '16px',
    marginTop: '10px',
    minHeight: '40px',
  },
}));

const Index = (props) => {
  const { i18n, t } = useTranslation();
  const {
    offer,
    open,
    setOpen,
    handleAcheter,
    prix,
    amount,
    offerId,
    weekly,
    daily,
    offerDivisionId,
    typePeriod,
    offerParam,
    color,
    width,
   setPeriod,
    selectedPeriod,
    setPricingIndex,
    discountType,
    discountValueNoCoupon,
    finalPriceNoCoupon,
    priceNoCoupon,
    indexSliceToPay,
    isFree
  } = props;
  const {
    isDiscountCodesLoading,
    discount,
    discountError,
    discountCodes,
    isAddingDiscountLoading,
    deletedDiscountCode,
  } = useSelector((state) => state.points);
  const dispatch = useDispatch();
  const theme = useTheme();
  const [code, setCode] = useState(null);
  const [data, setData] = useState([]);
  const [showMessage, setShow] = useState(false);
  const [validation, setValidation] = useState(null);
  const [openPaiment, setOpenPaiment] = useState(false);
  const [ooredooPeriodValue, setOoredooPeriodValue] = useState("1 jour");
  const [selectedOoredooPeriod, setOoredooPeriod] = useState(ooredooPeriodValue);
  const [paimentData, setPaimentData] = useState({ recurrence: '', offerDivisionDetail: '' });
  const [ooreedooPrice, setOoreedooPrice] = useState();
  const {digitalVirgoClient } = useSelector((state) => state.user);

  const classes = useStyles();
  const quantityCheck =
    data?.length > 0 &&
    data?.filter((p) =>
      selectedPeriod != "reste de l'année"
        ? p?.discount_code.quantity?.includes(3)
        : p.discount_code.all_year == true
    );
  const finalCodes =
    quantityCheck?.length > 0
      ? quantityCheck?.filter((item) =>
          item.discount_code.discount_code_offers.length > 0
            ? item.discount_code.discount_code_offers.find((p) => p.offer.id === offerId)
            : item
        )
      : [];
    // set default values for period = 6
  const lastIndex = offerParam?.pricing?.length-1;
  //const offerPeriodsList = [...offerParam?.pricing];
  const offerPeriods = offerParam?.pricing && offerParam?.pricing?.filter(item=>item.period !=="reste de l'année");
  const currentPeriod = offerPeriods && offerPeriods[offerPeriods?.length-1];
  const defaultPrice =  currentPeriod?.final_price;
  const defaultPriceNoCoupon = currentPeriod?.price;
  const defaultDiscountValue = currentPeriod?.infoDiscount?.discountValue;
  const defaultDiscountType =currentPeriod?.infoDiscount?.discountType;

  const handleDaily = () => {
    setOpenPaiment(true);
    setPaimentData({ recurrence: '1', offerDivisionDetail: offerDivisionId });
    setOoreedooPrice(daily);
  };

  const handleWeekly = () => {
    setOpenPaiment(true);
    setPaimentData({ recurrence: '7', offerDivisionDetail: offerDivisionId });
    setOoreedooPrice(weekly);
  };

  useEffect(() => {
    if (!isDiscountCodesLoading) {
      setData(discountCodes);
    }
    if (deletedDiscountCode && data?.length == 1) {
      setData(null);
    }
  }, [discountCodes]);

  const handleClose = () => {
    setOpen(false);
    setShow(false);
    setOpenPaiment(false);
    setPaimentData({ recurrence: '', offerDivisionDetail: '' });
    setOoreedooPrice();
    setPricingIndex(lastIndex)
    setPeriod("reste de l'année")
  };

  const handleSubmit = () => {
    if (!code) {
      setShow(true);
      setValidation('Code coupon requis');
      setTimeout(() => setShow(false), 3000);
    } else if (indexSliceToPay!== 1 && indexSliceToPay !== -1) {
      setShow(true);
      setValidation('Vous pouvez ajouter un code coupon que pour la premiére tranche');
    } else {
    dispatch(
        addDiscountCode(
          code,
          offerId,
          selectedPeriod != "reste de l'année" ? selectedPeriod : selectedPeriod,
          selectedPeriod == "reste de l'année" ? 1 : 0
        )
      );
      setOpen(true);
      setShow(true);
      setValidation(null);
      setTimeout(() => setShow(false), 3000);
    }
  };
  const handleRedirect = () => {
    dispatch(digitalvirgoRedirect(paimentData.recurrence));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      titleStyle={{ textAlign: 'center' }}
      aria-describedby="alert-dialog-description"
      className="couponModal"
      fullWidth={true}
      maxWidth="lg"
    >

      <div className="modal-close-coupon" onClick={handleClose}>
        <IconButton onClick={handleClose}>
          <CloseIcon className="close-coupon" />
        </IconButton>
      </div>
      {(typePeriod === "Par jour" )&&
      <DialogContent className={'content'}>
        {(weekly || daily) && !openPaiment ? (
          <div>
            <div
              style={{ display: 'flex', marginTop: '50px', justifyContent: 'space-between' }}
            ></div>

            {/* <Divider style={{ margin: '40px', width: '87%' }} /> */}
             <div className="ooreedoo-periods">
                   <OfferOoredooPeriods
                      classCard={'down'}
                      setOoredooPeriod={setOoredooPeriod}
                      ooredooPeriodValue={selectedOoredooPeriod}
                      color={offerParam.color}
                      selectedOoredooPeriod={selectedOoredooPeriod}
                      width={width}
                       />
             </div>
            <div className="Content-ooredoo">
              { selectedOoredooPeriod === '1 jour' && (
              <OoredooItem
               //   onClick={handleDaily}
                  color={color}
                  text={
                    <span>
                      {t('Ce type d abonnement est renouvelable automatiquement chaque jour')}
                      <br /> <br /> {t('Vous pouvez annuler votre abonnement a tout moment')}
                    </span>
                  }
                  price={`${daily}  ${t('TND')} / ${t('jour')}`}
                />
              )}
                { selectedOoredooPeriod === '7 jours' && (
              <OoredooItem
                //  onClick={handleWeekly}
                  color={color}
                  text={
                    <span>
                      {t("Ce type d'abonnement est renouvelable automatiquement chaque semaine")}
                      <br /> <br /> {t('Vous pouvez annuler votre abonnement a tout moment')}
                    </span>
                  }
                  price={`${weekly}  ${t('TND')} / ${t('Semaine')}`}
                />
              )}
            </div>
            <div className="group-btn">
                  <Button
                    onClick={selectedOoredooPeriod === '1 jour' ? handleDaily : selectedOoredooPeriod === '7 jours' ? handleWeekly :  handleAcheter}
                    className="acheter"
                    style={{ backgroundColor: color, marginBottom: '30px', width: '80%' }}
                  >
                    {isFree ? t('Essayer gratuitement') : t('Acheter')}
                  </Button>
                </div>
          </div>
        ) : (
          <div style={{ width: '100%' }}>
            {!openPaiment && (
              <div>
                <div className={'offer-Title'} style={{ color: color }}>
                  {offer}
                </div>
                {/* <Divider style={{ margin: '20px' }} /> */}

                <div className="simple-price-offer">
                  <div
                    style={{ marginBottom: '40px' }}
                    className={i18n.language === 'ar' ? 'price-header-rtl' : ''}
                  >
                    <span className="total">{t('Total')} </span>
                    {finalCodes?.length > 0 && !isDiscountCodesLoading && (
                      <div className="discount-price">
                        {Math.round(amount(finalCodes, prix))} {t('TND')}
                      </div>
                    )}
                    <div
                      className={
                        finalCodes?.length == 0 || isDiscountCodesLoading
                          ? 'before-discount'
                          : 'after-discount'
                      }
                    >
                      {isFree === true ? t('Gratuit') : prix + ' ' + t('TND')}
                      {'   '}
                      {finalCodes?.length > 0 &&
                        !isDiscountCodesLoading &&
                        !(
                          finalCodes[0]?.discount_code.type == 'discount' ||
                          finalCodes[1]?.discount_code.type == 'discount'
                        ) && (
                          <span className="discount-value">
                            -{Math.round((1 - amount(finalCodes, prix) / prix) * 100)} %
                          </span>
                        )}
                    </div>
                  </div>
                </div>

                {/* <div className="group-btn">
                  <Button
                    onClick={handleAcheter}
                    className="acheter"
                    style={{ backgroundColor: color, marginBottom: '30px', width: '80%' }}
                  >
                    {t('Acheter')}
                  </Button>
                </div> */}
              </div>
            )}
          </div>
        )}
        {openPaiment && (
          <PaimentOoreedoo
            image={Im_paiment}
            buttonText={t('Procéder au paiement')}
            disabledBtn={digitalVirgoClient === true}
            buttonWidth={'auto'}
            onClick={handleRedirect}
            color={color}
            text={
              <span>
                {t(
                  'En cliquant sur ce bouton, vous serez redirigé vers une interface de paiement sécurisée'
                )}
                <br /> <br />
                {` ${t('Prix')} ${ooreedooPrice} ${t('TND')}`}{' '}
              </span>
            }
          />
        )}

      </DialogContent>}
      {!isFree && (typePeriod =="Par mois"|| typePeriod =='1'|| typePeriod =='6'||typePeriod =='3' )&&
           <DialogContent className={'content'}>
           <div style={{ direction: i18n.language === 'ar' ? 'rtl' : 'ltr', display: 'flex', marginTop: '30px'}}>
             <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '-20px' }}>
             <span className="total">{t('Total')}  </span>
             <div className="offer-price-in-coupon" >
                         <Typography
                         style={{
                           paddingLeft: finalPriceNoCoupon ? '30px' : '0px',
                         }}
                       >
                      {typePeriod === "Par mois" ? (
                        <span className="default-base-offer-price-in-coupon" style={{textDecoration: 'line-through', marginLeft:'30px', display:    finalCodes?.length > 0 && !isDiscountCodesLoading   ?  'none': 'inline' }}>
                                {defaultPriceNoCoupon}
                                {defaultDiscountValue  ? '' : t('Dt')}
                                </span>
                      ):(
                        <span className="base-offer-price-in-coupon" style={{textDecoration: 'line-through', display:    finalCodes?.length > 0 && !isDiscountCodesLoading || priceNoCoupon === prix || offerParam.type === "pack" ?  'none': 'inline' }}>
                              {priceNoCoupon }
                               {discountValueNoCoupon  ? '' : t('Dt')}
                      </span> )}
                         {finalPriceNoCoupon
                           ? discountValueNoCoupon != null &&  (
                               <span id="Remise" style={{display:    finalCodes?.length > 0 && !isDiscountCodesLoading  ?  'none': 'inline'}}>
                                 -
                                 {discountType ==
                                 'percentage_discount'
                                   ? discountValueNoCoupon + '%'
                                   : discountValueNoCoupon + t('Dt')}
                               </span>
                             )
                           : ''}
                         {typePeriod === "Par mois" && defaultDiscountValue &&
                                <span id="Remise" style={{display:    finalCodes?.length > 0 && !isDiscountCodesLoading  ?  'none': 'inline'}}>
                                 -
                                 {defaultDiscountType ==
                                 'percentage_discount'
                                   ? defaultDiscountValue + '%'
                                   : defaultDiscountValue + t('Dt')}
                               </span>}
                       </Typography>
                   </div>
               <div className={i18n.language === 'ar' ? 'price-header-rtl' : 'price-header'}>
                 {finalCodes?.length > 0 && !isDiscountCodesLoading && (
                   <div className="discount-price">
                     <span>
                       {typePeriod === "Par mois" ? Math.round(amount(finalCodes, defaultPrice)) :  Math.round(amount(finalCodes, prix))} {t('TND')}
                     </span>
                   </div>
                 )}
                 <div
                   className={
                     finalCodes?.length == 0 || isDiscountCodesLoading
                       ? 'before-discount'
                       : 'after-discount'
                   }
                 >
                 {typePeriod === "Par mois" &&  finalCodes ? defaultPrice : prix } {t(t('Dt'))}
                  </div>
                 {finalCodes?.length > 0 &&
                   !isDiscountCodesLoading &&
                   !(
                     finalCodes[0]?.discount_code.type == 'discount' ||
                     finalCodes[1]?.discount_code.type == 'discount'
                   ) && (
                     <div className="discount-value">
                       -{typePeriod === "Par mois" ? Math.round((1 - amount(finalCodes, defaultPrice) / defaultPrice) * 100):  Math.round((1 - amount(finalCodes, prix) / prix) * 100)} %
                     </div>
                   )}
               </div>
           </div>
           </div>
           <div>

           </div>
           {offerParam.type === "primary" &&
        <div className='offerPeriodDialog'>
          <OfferPeriod
             classCard={'down'}
              color={offerParam.color}
              pricings={offerParam.pricing}
              setPeriod={setPeriod}
             selectedPeriod={typePeriod === "Par mois"  ? currentPeriod?.period : selectedPeriod}
             setPricingIndex={setPricingIndex}
              //pricingIndex={pricingIndex}
              width={width }
              />
           </div>  }
     <div className="group-btn">
             <Button onClick={handleAcheter} className="acheter" style={{ backgroundColor: color }}>
               {isFree ? t('Essayer gratuitement') : t('Acheter')}
             </Button>
           </div>
           <br />
           {finalCodes !== [] &&
             finalCodes?.map((item) => {
               return (
                 <div className="code-list-item">
                   <CouponItem couponCode={item.discount_code} offerId={offerId} id={item.id} />
                 </div>
               );
             })}
           <div className={i18n.language === 'ar' ? 'message-code-rtl' : 'message-code'}>
             {t("Ajouter votre code coupon pour bénéficier d'une réduction")}
           </div>
           <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
             <TextField
               disabled={finalCodes?.length >= 2}
               outlined
               id={`code success`}
               label={t('Taper votre code coupon')}
               type="string"
               value={code}
               margin="dense"
               variant="outlined"
               className={`code-promo`}
               fullWidth
               InputProps={{
                 endAdornment: (
                   <InputAdornment position="end">
                     {/* { <img
                       src={
                         discount && showMessage
                           ? correctIcon
                           : discountError && showMessage
                           ? wrongIcon
                           : ''
                       }
                       className="icon-discount-message"
                     /> } */}
                     <IconButton
                       onClick={handleSubmit}
                       className="validate"
                       style={{ backgroundColor: finalCodes?.length >= 2 ? 'lightgray' : color }}
                       disabled={isAddingDiscountLoading || finalCodes?.length >= 2}
                       edge="end"
                       root={{borderRadius : '0.1em'}}
                     >
                       {isAddingDiscountLoading ? (
                         <CircularProgress style={{ color: 'white' }} />
                       ) : (
                         t('Appliquer')
                       )}
                     </IconButton>
                   </InputAdornment>
                 ),
               }}
               onChange={(e) => setCode(e.target.value)}
             />
           </div>
           <div className="message-group">
             {(discount || discountError || validation) && showMessage && (
               <span className={discount ? 'message-discount-success' : 'message-discount-warning'}>
                 {discount ? t('Code coupon validé') :validation? t(validation) :t(formatMessages(discountError) )}
               </span>
             )}
           </div>
         </DialogContent> }
         {(typePeriod === "reste de l'année" || isFree) &&
         <DialogContent className={'content'}>
         <div style={{ display: 'flex', marginTop: '30px' }}>
           <div style={{ display: 'flex', flexDirection: 'column' }}>
             <div className={i18n.language === 'ar' ? 'price-header-rtl' : 'price-header'}>
               <span className='total'>{t('Total')} </span>
               {finalCodes?.length > 0 && !isDiscountCodesLoading && (
                 <div className="discount-price">
                   <span>
                     {Math.round(amount(finalCodes, prix))} {t('TND')}
                   </span>
                 </div>
               )}
               <div
                 className={
                   finalCodes?.length === 0 || isDiscountCodesLoading
                     ? 'before-discount'
                     : 'after-discount'
                 }
               >
                 {isFree === true ? t('Gratuit') : prix + ' ' + t('TND')}
               </div>
               {finalCodes?.length > 0 &&
                 !isDiscountCodesLoading &&
                 !(
                   finalCodes[0]?.discount_code.type == 'discount' ||
                   finalCodes[1]?.discount_code.type == 'discount'
                 ) && (
                   <div className="discount-value">
                     -{Math.round((1 - amount(finalCodes, prix) / prix) * 100)} %
                   </div>
                 )}
             </div>
           </div>
         </div>
         <div className="group-btn">
           <Button onClick={handleAcheter} className="acheter" style={{ backgroundColor: color }}>
             {isFree ? t('Essayer gratuitement') : t('Acheter')}
           </Button>
         </div>
         <br />
         {finalCodes !== [] &&
           finalCodes?.map((item) => {
             return (
               <div className="code-list-item">
                 <CouponItem couponCode={item.discount_code} offerId={offerId} id={item.id} />
               </div>
             );
           })}
         <div className={i18n.language === 'ar' ? 'message-code-rtl' : 'message-code'}>
           {t("Ajouter votre code coupon pour bénéficier d'une réduction")}
         </div>
         <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
           <TextField
             disabled={finalCodes?.length >= 2}
             outlined
             id={`code success`}
             label={t('Taper votre code coupon')}
             type="string"
             value={code}
             margin="dense"
             variant="outlined"
             className={`code-promo`}
             fullWidth
             InputProps={{
               endAdornment: (
                 <InputAdornment position="end">
                   {/* { <img
                     src={
                       discount && showMessage
                         ? correctIcon
                         : discountError && showMessage
                         ? wrongIcon
                         : ''
                     }
                     className="icon-discount-message"
                   /> } */}
                   <IconButton
                     onClick={handleSubmit}
                     className="validate"
                     style={{ backgroundColor: finalCodes?.length >= 2 ? 'lightgray' : color }}
                     disabled={isAddingDiscountLoading || finalCodes?.length >= 2}
                     edge="end"
                   >
                     {isAddingDiscountLoading ? (
                       <CircularProgress style={{ color: 'white' }} />
                     ) : (
                       t('Appliquer')
                     )}
                   </IconButton>
                 </InputAdornment>
               ),
             }}
             onChange={(e) => setCode(e.target.value)}
           />
         </div>
         <div className="message-group">
           {(discount || discountError || validation) && showMessage && (
             <span className={discount ? 'message-discount-success' : 'message-discount-warning'}>
               {discount ? t('Code coupon validé') :validation? t(validation) :t(formatMessages(discountError) )}
             </span>
           )}
         </div>
       </DialogContent>

         }
    </Dialog>
  );
};

Index.propTypes = {
  onCloseModal: PropTypes.func,
};

export default Index;
