import React from 'react';
import { useTranslation } from 'react-i18next';
import ViewTitle from '../../components/ViewTitle';
import { WalletDetails, ProfileWidget, WarningWidget, PubsCards } from '../../components';
import { useSelector } from 'react-redux';

import ImP1 from '../../assets/img/icons/info.svg';
import ImP2 from '../../assets/img/icons/tinder.svg';
import ImP3 from '../../assets/img/icons/gift.svg';
import ImP4 from '../../assets/img/icons/nine.svg';
import ImP5 from '../../assets/img/icons/trial.svg';
import TxP1 from '../../assets/img/icons/text1.svg';
import TxP2 from '../../assets/img/icons/text2.svg';
import TxP3 from '../../assets/img/icons/text3.svg';
import TxP4 from '../../assets/img/icons/text4.svg';
import TxP5 from '../../assets/img/icons/text5.svg';
import Bg5 from '../../assets/img/icons/bg5.svg';

const Index = () => {
  const { t } = useTranslation();
  const { warning, permissions } = useSelector((state) => state.user);
  return (
    <div className="full-page">
      <ViewTitle title={t('Mon profil')} />
      <div className="ta-row">
        <div className="ta-column right-margin childrens-inverse layout-profile">
          <div className="child-left">
            {warning && !!Object.keys(warning)?.length && <WarningWidget warning={warning} />}
            <WalletDetails external />
          </div>
          <div className="child-right">
            <ProfileWidget />
          </div>
        </div>
        <div className="ta-column fixed right">
          <div style={{ width: '100%', marginTop: '60px' }}></div>
          <PubsCards className="info" icon={ImP1} text={TxP1} modal path={`L36YrGMQGO0`} />
          <PubsCards className="warning" icon={ImP2} text={TxP2} modal path={`ESJGB-lqVMU&ab`} />
          <PubsCards className="success" icon={ImP3} text={TxP3} path={`/offres`} />
          {/* <PubsCards className="anniversary" modal icon={ImP4} text={TxP4} path={`6rtRNOA4PvA`} /> */}
          {permissions.takeTrialAdvantage && (
            <PubsCards className="trial" icon={ImP5} text={TxP5} bg={Bg5} path={`/offres`} />
          )}{' '}
        </div>
      </div>
    </div>
  );
};

export default Index;
