import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import Skeleton from '@material-ui/lab/Skeleton';
import { map } from 'jquery';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress);

const SubjectTabs = ({ chapters, slug,
                       //chaptersProgress,
                       newchaptersProgress }) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState('course');
  const [selectedChapters, setSelectedChapters] = useState([]);
  const courses = chapters.filter((chapter) => chapter.chapter.type === 'course');
  const recordings = chapters.filter((chapter) => chapter.chapter.type === 'recording');
  const exams = chapters.filter((chapter) => chapter.chapter.type === 'exam');
  const divers = chapters.filter((chapter) => chapter.chapter.type === 'divers');
  useEffect(() => {
    setSelectedTab('course');
    setSelectedChapters(chapters.filter((chapter) => chapter.chapter.type === 'course'));
  }, [chapters]);

  useEffect(() => {
    setSelectedChapters(chapters.filter((chapter) => chapter.chapter.type === selectedTab));
  }, [selectedTab]);

  const handleName = (name) => {
    if (name.includes(',')) {
      const tab2 = [];
      const tab3 = [];
      const tab4 = [];
      const tab1 = name.split(', ');
      tab2.push(tab1.map((e) => e.split(' ').reverse().join(' ')));
      tab3.push(tab2.map((e) => e.map((r) => r.split(' '))));
      tab4.push(
        tab3.map((e) => e.map((r) => r.map((f) =>  ' '+ f[0][0].toUpperCase()+ '.' + ((f[2] && f[2]=='ben') ? f[1]:f[2] ? f[2]:f[1])  )))
      ); 
      return tab4;
    } else if(name) {
      const tab2 = [];
      const tab3 = [];
      const tab1 = name.split(', ').reverse();
      tab2.push(tab1[0].split(' '));

      tab3.push(tab2.map((e) => ' '+(e[2] ? e[2][0].toUpperCase() : e[1][0].toUpperCase()) + '.' + e[0] ));

      return tab3;
    }else if(!name.includes(' '))
    return(name)
  };

  function renderSkeletons() {
    return (
      <>
        {[1, 2, 3, 4, 5].map(() => {
          return (
            <Skeleton
              variant="rect"
              width={'100%'}
              height={120}
              style={{ marginLeft: '0', marginTop: '20px' }}
            />
          );
        })}
      </>
    );
  }

  return (
    <Card className="ta-card subject-tabs">
      <CardContent>
        <div className="child-full">
          <div className="header">
            <span
              className={selectedTab === 'course' ? 'menu-item active' : 'menu-item'}
              onClick={() => setSelectedTab('course')}
              style={courses.length <= 0 ? { pointerEvents: 'none' } : {}}
            >
              {t("Cours")}
            </span>
            <span
              className={selectedTab === 'exam' ? 'menu-item active' : 'menu-item'}
              onClick={() => setSelectedTab('exam')}
              style={exams.length <= 0 ? { pointerEvents: 'none' } : {}}
            >
              {t("Examens")}
            </span>
            <span
              className={selectedTab === 'recording' ? 'menu-item active' : 'menu-item'}
              onClick={() => setSelectedTab('recording')}
              style={recordings.length <= 0 ? { pointerEvents: 'none' } : {}}
            >
              {t("Enregistrements")}
            </span>
            <span
              className={selectedTab === 'divers' ? 'menu-item active' : 'menu-item'}
              onClick={() => setSelectedTab('divers')}
              style={divers.length <= 0 ? { pointerEvents: 'none' } : {}}
            >
              {t("Divers")}
            </span>
          </div>
          <div className="chapters-container">
            {chapters.length > 0 ? null : renderSkeletons()}
            {selectedChapters &&
              selectedChapters.length > 0 &&
              selectedChapters
                //.filter((chapter) => chapter.videosNumber > 0)
                .map((chapter, index) => {
                  //old progress
                 /* const progress =
                    chaptersProgress &&
                    chaptersProgress.length > 0 &&
                    //chaptersProgress.find((p) => p.chapter_id == chapter.slug.split('-')[0]);
                    chaptersProgress.find((p) => p.chapter_id == chapter?.chapter?.id);
                    const progressValue = progress ? progress.value : 0;*/
                    //new progress
                  const newProgress =
                      newchaptersProgress &&
                      newchaptersProgress.length > 0 &&
                      newchaptersProgress.find((p) => p?.chapter_id === chapter?.chapter?.id);
                  const newProgressValue = (newProgress && parseInt(chapter?.nbContent)!==0) ? Math.round((newProgress?.nbCompleted/chapter?.nbContent)*100):0

                  return (
                    <Link
                      className="chapter-item"
                      to={
                        chapter?.chapter?.type !== 'recording'
                          ? `/subjects/${slug}/chapters/${chapter.chapter.slug}/lessons/${chapter.firstLessonSlug}/content`
                          : `/subjects/${slug}/chapters/${chapter.chapter.slug}/recordings/${chapter.firstLessonSlug}/content`
                      }
                    >
                      <span className="number">{index < 9 ? `0${index + 1}` : index + 1}</span>
                      <div className="details">
                        <span className="label">{chapter.chapter.name}</span>
                        <div className="infos">
                          <span className="teacher">{t("Par")} {handleName(chapter.instructor)} </span>
                          {/* <span className="videos">{chapter.nbContent} {t("videos")}</span> */}
                          {chapter.nbContent !== 1 &&  chapter.nbContent <= 10 ? (
                              <span className="videos">
                                {chapter.nbContent} {t("videosAr")}
                              </span> ) :( 
                              <span className="videos">
                                {chapter.nbContent} {t("videos")}
                              </span> ) }
                        </div>
                        <div className="progress-container">
                      <BorderLinearProgress
                            variant="determinate"
                            //value={progressValue}
                            value={newProgressValue > 100 ? 100 :newProgressValue}
                            className="bg-orange"
                            />
                         <span className="progress">
                           {/*{progressValue}%*/}
                           {newProgressValue > 100 ? 100 :newProgressValue}%
                         </span>
                        </div>
                      </div>
                    </Link>
                  );
                })}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

SubjectTabs.propTypes = {};

export default SubjectTabs;
