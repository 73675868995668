import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import Skeleton from '@material-ui/lab/Skeleton';
import { CardTitle, MyProgressList } from '../../../components';
import {getNewSubjectsProgress} from "../../../redux/slices/newProgress";

const SubjectWidget = ({ subject, subjects, totalChapters, totalVideos, newsubjectProgress}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { newsubjectsProgress } = useSelector((state) => state.newProgress);
  const [Subjects, setSubjects] = useState([]);
  const [itemsNumber, setItemsNumber] = useState(5);
  const currentProgress = newsubjectProgress ?Math.round((newsubjectProgress.nbCompleted/totalVideos)*100):'';
  const collapsedSubjects= subjects.filter((sub) => sub.id !== subject.id)
  
  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#1a90ff',
    },
  }))(LinearProgress);

  useEffect(() => {
    if (!newsubjectsProgress) {
      dispatch(getNewSubjectsProgress());
    }
  }, []);

  useEffect(() => {
    if (subjects) {
      setSubjects(collapsedSubjects.slice(0, itemsNumber));
    }
  }, [subjects, collapsedSubjects]);

  useEffect(() => {
    if (subjects) {
      setSubjects(collapsedSubjects.slice(0, itemsNumber));
    }
  }, [itemsNumber]);

  function renderSkeletons() {
    return (
      <>
        {[1, 2, 3, 4, 5].map(() => {
          return (
            <>
              <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <Skeleton variant="rect" width={20} height={20} style={{ marginLeft: '0' }} />
                <Skeleton variant="rect" width={60} height={8} style={{ marginLeft: '12px' }} />
              </div>
              <Skeleton
                variant="rect"
                width={140}
                height={8}
                style={{ marginLeft: '0', marginTop: '8px' }}
              />
            </>
          );
        })}
      </>
    );
  }

  return (
    <Card className="ta-card subject-widget">
      <CardContent>
        <div className={itemsNumber === 5 ? 'child-full' : 'child-full active'}>
          <div className="subject-title">
            {subject ? (
              <>
                <img alt="subject" src={subject?.img} />
                <span>{subject?.label}</span>
              </>
            ) : (
              <>
                <Skeleton variant="rect" width={60} height={60} style={{ marginLeft: '0' }} />
                <Skeleton variant="rect" width={150} height={20} />
              </>
            )}
          </div>
          <div className="progression">
            <div className="textes">
              {subject ? (
                <>
                  <span>{t('Progression')}</span>
                  <span>{currentProgress > 100 ? 100 : currentProgress}%</span>
                </>
              ) : (
                <>
                  <Skeleton variant="rect" width={40} height={20} style={{ marginLeft: '0' }} />
                  <Skeleton variant="rect" width={60} height={20} />
                </>
              )}
            </div>
            {subjects.length > 0 ? (
              <>
                <BorderLinearProgress variant="determinate" value={currentProgress > 100 ? 100 : currentProgress} className="bg-orange" />
              </>
            ) : (
              <Skeleton variant="rect" width={'100%'} height={8} style={{ marginLeft: '0' }} />
            )}
          </div>
          <div className="subject-infos">
            {totalChapters ? (
              <span>
                {t('Nombre de chapitres')}:{' '}
                 {totalVideos !== 1 &&  totalVideos <= 10 ? (
                <span>
                  {totalChapters} {t('chapitresAr')}
                </span> ) :( 
                   <span>
                  {totalChapters} {t('chapitres')}
                 </span> ) }
              </span>
            ) : (
              <Skeleton variant="rect" width={160} height={8} style={{ marginLeft: '0' }} />
            )}

            {totalVideos ? (
              <span>
                {t('Nombre de videos')}:{' '}
                {totalVideos !== 1 &&  totalVideos <= 10 ? (
                <span>
                  {totalVideos} {t('videosAr')}
                </span> ) :( 
                   <span>
                  {totalVideos} {t('videos')}
                 </span> ) }
              </span>
            ) : (
              <Skeleton variant="rect" width={140} height={8} style={{ marginLeft: '0' }} />
            )}
          </div>
          <div className="others">
          <CardTitle title={t('autres matieres')} />
          {subject ? (
            <MyProgressList subjects={Subjects}
                            newSubjectsProgress={newsubjectsProgress}
            />
            ) : (
            renderSkeletons()
          )}

            <div
              className="show-more-link"
              onClick={() => setItemsNumber(itemsNumber === 5 ? subjects && subjects.length : 5)}
            >
              <span>{itemsNumber === 5 ? t('voir plus') : t('voir moins')} </span>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

SubjectWidget.propTypes = {};

export default SubjectWidget;
