import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles, Snackbar, TextField, useMediaQuery, useTheme } from '@material-ui/core';
import { Dialog, DialogContent } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TakiacademyLogo from '../../../assets/img/TakiacademyLogo.png';
import SelectInput from '../../../components/AuthComponents/SelectInput';
import OoredooItem from './ooredooItem';
import { checkoutDigitalVergo } from '../../../redux/slices/auth';
import { digitalVergoValidation } from '../../../components/AuthComponents/AuthValidation';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  input: {
    borderRadius: '32px',
    fontWeight: 'bold',
    fontSize: '16px',
    height: '50px',
    width: '330px',
  },
}));

const Index = (props) => {
  const { i18n, t } = useTranslation();
  const { open, setOpen, price, setValidationResult, setValidationStatus, setOpenSnackBar,validationResult,openSnackBar,validationStatus } = props;
  const [values, setValues] = useState({});
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const dispatch = useDispatch();
  const { divisions } = useSelector((state) => state.auth);
  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSelect = (e, value, name) => {
    setValues((prevState) => ({
      ...prevState,
      [name]: value && value.id,
    }));
  };

  const handleDaily = async (values) => {
    const validation = digitalVergoValidation(values);

    if (validation) {
      setValidationResult(validation.message);
      setValidationStatus(validation.success);
      setOpenSnackBar(true);
    } else {
      await dispatch(
        checkoutDigitalVergo({
          recurrence: '1',
          phone: values.phone,
          division: values.division,
          optionalSubject: null,
        })
      );
      return setOpenSnackBar(true);
    }
  };
  const handleWeekly = async (values) => {
    const validation = digitalVergoValidation(values);

    if (validation) {
      setValidationResult(validation.message);
      setValidationStatus(validation.success);
      setOpenSnackBar(true);
    } else {
      await dispatch(
        checkoutDigitalVergo({
          recurrence: '7',
          phone: values.phone,
          division: values.division,
          optionalSubject: null,
        })
      );
      return setOpenSnackBar(true);
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      titleStyle={{ textAlign: 'center' }}
      aria-describedby="alert-dialog-description"
      className="couponModal"
      //fullWidth={true}
      maxWidth="md"
      fullScreen={fullScreen}
    >
      <div className="modal-close-coupon" onClick={handleClose}>
        <IconButton onClick={handleClose}>
          <CloseIcon className="close-coupon" />
        </IconButton>
      </div>
      <DialogContent className={'DigitalVergoModalContent'}>
        <img src={TakiacademyLogo}></img>
        <div classeName="classeinput">
          <div id="inputTitle">Numero de téléphone :</div>
          <TextField
            onChange={handleChange}
            name="phone"
            placeholder="téléphone"
            variant="outlined"
            size="medium"
            values={values.phone}
            InputProps={{
              classes: {
                root: classes.input,
              },
            }}
          />
          <div id="inputTitle">Classes :</div>
          <SelectInput
            placeholder={t('Choisissez votre classe')}
            options={divisions || []}
            inputProp={classes.input}
            variant={'outlined'}
            handleFunction={handleSelect}
            onChange={handleChange}
            name="division"
          />
        </div>
        <div className="ooredooCards">
          <OoredooItem
            disabled={values.phone?.length !== 8 || !values.division}
            text={
              <span>
                {'Ce type d abonnement est renouvelable automatiquement chaque jour'}
                <br /> <br /> {'Vous pouvez annuler votre abonnement à tout moment'}
              </span>
            }
            price={`${price.dailyPrice} TND/jour`}
            onClick={() => handleDaily(values)}
          />
          <OoredooItem
            text={
              <span>
                {'Ce type d abonnement est renouvelable automatiquement chaque semaine'}
                <br /> <br /> {'Vous pouvez annuler votre abonnement à tout moment'}
              </span>
            }
            disabled={values.phone?.length !== 8 || !values.division}
            price={`${price.weeklyPrice} TND/semaine`}
            onClick={() => handleWeekly(values)}
          />
        </div>
      </DialogContent>
      <Snackbar
      style={{zIndex:1000}}
        open={openSnackBar}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'Bottom',
          horizontal: 'center',
        }}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert
          severity={validationStatus ? 'success' : 'error'}
          onClose={() => setOpenSnackBar(false)}
        >
          {validationResult}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

Index.propTypes = {
  onCloseModal: PropTypes.func,
};

export default Index;
