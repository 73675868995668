import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ptr } from '../../utilities/methods';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#FE726E',
  },
}))(LinearProgress);

dayjs.extend(customParseFormat);

const Index = ({ index, offer }) => {
  const { t } = useTranslation();
  return (
    <div className="subs-item-2" key={index}>
      <span className="label">{`${offer?.group?.name}${` ${offer?.offer_division_subject?.subject.name||''}`}`}</span>
      <BorderLinearProgress variant="determinate" value={ptr(offer?.start_date, offer?.end_date)} />
      {offer && offer.chapter_id ? (
        <span className="due-date">{offer?.group?.name}</span>
      ) : (
        <span className="due-date">{t("Se termine le")}{`${dayjs(offer?.end_date).format(
          'DD/MM/YYYY'
        )}`}</span>
      )}
    </div>
  );
};

Index.propTypes = {
  index: PropTypes.number,
  offer: PropTypes.object,
  ptr: PropTypes.func,
};

export default Index;
