import React, { useEffect, useRef, useState } from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { forceResetPasswordCode } from '../../../redux/slices/auth';
import { Slide } from "@material-ui/core";
import cadnat from '../../../assets/img/icons/cadnat.svg';
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import InputCard from '../../AuthComponents/InputCard';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import LockIcon from '@material-ui/icons/Lock';
import { forceResetPasswordValidation } from '../../AuthComponents/AuthValidation';
import ReCAPTCHA from 'react-google-recaptcha';
import WindowDimensions from '../../../utilities/windowDimenssions';
import  SuccessReset  from '../../../components/ForceUpdatePassword/SuccessReset';
import { enqueueSnackbar } from '../../../redux/slices/notifier';



const initialState={
  token: null,
  credential: null,
  newPassword: null,
  retypePassword: null,
}

const ResetCode = () => {
  const { t } = useTranslation();
  const { informations } = useSelector((state) => state.user);
  const {width} = WindowDimensions();
  const maxWidth = width >= 750 ? 750 : width - 50;
  const dispatch = useDispatch();
  const { authResult, success, statusReset } = useSelector((state) => state.auth);
  const [values, setValues] = useState(initialState);

  useEffect(() => {
    setValidationResult(authResult);
  }, [authResult]);
  useEffect(() => {
  if(statusReset === "succeeded")
    setSuccessTrue(true);
  }, [statusReset]);
  useEffect(() => {
    setValidationStatus(success);
  }, [success]);

  const [validationResult, setValidationResult] = useState(authResult);
  const [validationStatus, setValidationStatus] = useState(success);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [successTrue, setSuccessTrue] = useState(false);
  const recaptchaRef = useRef();

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validation = forceResetPasswordValidation(values);
    if (validation) {
      dispatch(
        enqueueSnackbar({
          message: validation.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "warning",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
    }
  else  recaptchaRef.current.execute();
  };

  const onRecaptchaChange = async (value) => {
    let data = values;
    data.credential = informations.phone ? informations.phone : informations.email;
    data['g-recaptcha-response'] = value;
    await dispatch(forceResetPasswordCode(data))
  
    setOpenSnackBar(true);
  };
  return (
    <div className="login-card" style={{ margin:'30px 0px'}}>
      {successTrue ? 
        ( <SuccessReset /> ) : (
      <div className="centered-box" style={{ minHeight: 500 }}>
          <img src={cadnat}
                 width={width >= 750 ? 170 : maxWidth - 300}
                 style={{margin: 10}}/>
          <span className="title-warning">{t("Warning")}</span>
          <span className="text-warning">{t("Your password expired For security reasons we force a password change from time to time Please change it below Thanks")}</span>
        <InputCard
          value={values.token}
          handleFunction={handleChange}
          placeholder={t('Code de vérification')}
          typeInput={'text'}
          icon={<VpnKeyIcon className="icon-input" />}
          name="token"
        />
        <InputCard
          disabled
          value={informations.phone ? informations.phone : informations.email}
          placeholder={t('Email ou numéro de téléphone')}
          typeInput={'text'}
          icon={<EmailIcon className="icon-input" />}
          name="credential"
        />
        <InputCard
          value={values.newPassword}
          handleFunction={handleChange}
          placeholder={t('Mot de passe minimum 8 caractères')}
          typeInput={t('password')}
          icon={<LockIcon className="icon-input" />}
          name="newPassword"
        />
        <InputCard
          value={values.retypePassword}
          handleFunction={handleChange}
          placeholder={t('Confirmez le mot de passe')}
          typeInput={t('password')}
          icon={<LockIcon className="icon-input" />}
          name="retypePassword"
        />
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={process.env.REACT_APP_RECAPATCHA_KEY}
          size="invisible"
          onChange={onRecaptchaChange}
        />
        <Button
          variant="outlined"
          disableElevation
          onClick={(e) => handleSubmit(e)}
          className="submit-reset"
        >
          {t("Réinitialiser le mot de passe")}
        </Button>
      </div>)}
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'Bottom',
          horizontal: 'center',
        }}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert
          severity={validationStatus ? 'success' : 'error'}
          onClose={() => setOpenSnackBar(false)}
        >
          {t(validationResult)}
        </Alert>
      </Snackbar>
    </div>
  );
};

ResetCode.propTypes = {
  dispatch: PropTypes.func,
  email: PropTypes.string,
  setEmail: PropTypes.func,
  password: PropTypes.string,
  setPassword: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default ResetCode;