import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BookmarksWidget,
  ForumWidget,
  FreeVideosWidget,
  MyProgressWidget,
  ProfileWidget,
  PubsCards,
  TodayEventsWidget,
  WarningWidget,
} from '../../components';
import { getSubscriptionWarning } from '../../redux/slices/user';
import { useLocation, useHistory } from 'react-router-dom';

import ImP1 from '../../assets/img/icons/info.svg';
import ImP2 from '../../assets/img/icons/tinder.svg';
import ImP3 from '../../assets/img/icons/gift.svg';
import ImP4 from '../../assets/img/icons/nine.svg';
import ImP5 from '../../assets/img/icons/trial.svg';
import TxP1 from '../../assets/img/icons/text1.svg';
import TxP2 from '../../assets/img/icons/text2.svg';
import TxP3 from '../../assets/img/icons/text3.svg';
import TxP4 from '../../assets/img/icons/text4.svg';
import TxP5 from '../../assets/img/icons/text5.svg';
import Bg5 from '../../assets/img/icons/bg5.svg';
import Coupon1 from '../../assets/img/icon.svg';
import Coupon2 from '../../assets/img/text.svg';

const Index = () => {
  const dispatch = useDispatch();

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const { warning, informations, permissions } = useSelector((state) => state.user);
  const offerFreeId = 104;
  const offerFreeCondition = informations?.offers?.filter(
    (offer) => offer.group.id === offerFreeId //this condition is temporary for offre free//
  ).length;

  useEffect(() => {
    dispatch(getSubscriptionWarning());
  }, []);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  const useWindowDimensions = () => {
    useEffect(() => {
      const handleResize = () => {
        setWindowDimensions(getWindowDimensions());
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowDimensions;
  };
  return (
    <div className="full-page">
      <div className="ta-row">
        <div className="ta-column right-margin layout-home">
          <div className="child-left">
            {warning && !!Object.keys(warning)?.length && <WarningWidget warning={warning} />}
            {useWindowDimensions(windowDimensions).width <= 600 && <ProfileWidget mini />}
            <TodayEventsWidget />
            {offerFreeCondition === 0 && <BookmarksWidget />}
            <FreeVideosWidget />
          </div>
          <div className="child-right">
            <MyProgressWidget />
            <ForumWidget />
            {useWindowDimensions(windowDimensions).width <= 1199 && (
              <>
                <PubsCards className="info" icon={ImP1} text={TxP1} modal path={`L36YrGMQGO0`} />
                <PubsCards
                  className="warning"
                  icon={ImP2}
                  text={TxP2}
                  modal
                  path={
                    [35, 32, 31, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1].includes(informations?.division_id)
                      ? `fn3CYj48SP4`
                      : `x5DTcAEYo-M&t=9s`
                    //['14', '15', '17', '20', '22'].includes(informations?.division_id) ?`EXU1Mn8yKMM` :`teKpsvR4RVM`
                    //`QrO0c5MY7bg`
                  }
                />

                <PubsCards className="success" icon={ImP3} text={TxP3} path={`/offres`} />
                {/* <PubsCards
                  className="anniversary"
                  modal
                  icon={ImP4}
                  text={TxP4}
                  path={`6rtRNOA4PvA`}
                /> */}

                {permissions.takeTrialAdvantage && (
                  <PubsCards className="trial" icon={ImP5} text={TxP5} bg={Bg5} path={`/offres`} />
                )}
              </>
            )}
          </div>
        </div>
        <div className="ta-column fixed right">
          <ProfileWidget mini />
          <PubsCards className="info" icon={ImP1} text={TxP1} modal path={`L36YrGMQGO0`} />
          <PubsCards
            className="warning"
            icon={ImP2}
            text={TxP2}
            modal
            path={
              [35, 32, 31, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1].includes(informations?.division_id)
                ? `fn3CYj48SP4`
                : `x5DTcAEYo-M&t=9s`
              //['14', '15', '17', '20', '22'].includes(informations?.division_id) ?`EXU1Mn8yKMM` :`teKpsvR4RVM`
              //`QrO0c5MY7bg`
            }
          />
          <PubsCards className="success" icon={ImP3} text={TxP3} path={`/offres`} />
          {/* <PubsCards className="anniversary" modal icon={ImP4} text={TxP4} path={`6rtRNOA4PvA`} /> */}
          {permissions.takeTrialAdvantage && (
            <PubsCards className="trial" icon={ImP5} text={TxP5} bg={Bg5} path={`/offres`} />
          )}{' '}
        </div>
      </div>
    </div>
  );
};

export default Index;
