import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { CardTitle, BankAccountsList, WalletBalance, VariantButton } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { me } from '../../redux/slices/user';

const Index = ({ external }) => {
  const { t } = useTranslation();
  const { informations } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [points, setPoints] = React.useState('0');

  React.useEffect(() => {
    if (!informations) {
      dispatch(me());
    }
  }, []);

  React.useEffect(() => {
    if (informations) {
      setPoints(informations.points);
    }
  }, [informations]);

  return (
    <Card className="ta-card wallet-details">
      <CardHeader title={<CardTitle title={t("Porte monnaie")} />} />
      <CardContent>
        <div className="child-left">
          <BankAccountsList />
        </div>
        <div className="child-right">
          <WalletBalance points={points} external={external} />
          {!!external && (
            <>
              <Link to="/porte-monnaie">
                <VariantButton label={t("charger mon compte")} className="blue compte-button" />
              </Link>
              {/* <Link to="/porte-monnaie">
                <VariantButton label="Historique des points" className="blue-outlined mt" />
              </Link> */}
            </>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

Index.propTypes = {
  points: PropTypes.string,
  setPoints: PropTypes.func,
  external: PropTypes.bool,
  dispatch: PropTypes.func,
  informations: PropTypes.object,
};

export default Index;
