import React from 'react';
import { useTranslation } from 'react-i18next';
import ViewTitle from '../../components/ViewTitle';
import { SubscriptionList, ProfileWidget, MessageItem } from '../../components';

const Index = ({ match }) => {
  const { t } = useTranslation();
  const messageId = match.params.id;
  return (
    <div>
      <ViewTitle title={t('Messages')} />
      <div className="ta-row">
        <div className="subject-column right-margin">
          <MessageItem messageId={messageId} />
        </div>
        {/*<div className="ta-column fixed right">*/}
        {/*  <ProfileWidget mini />*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default Index;
