import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Fab from '@material-ui/core/Fab';
import SkipPreviousRoundedIcon from '@material-ui/icons/SkipPreviousRounded';
import SkipNextRoundedIcon from '@material-ui/icons/SkipNextRounded';
import ThumbUpRoundedIcon from '@material-ui/icons/ThumbUpRounded';
import ThumbDownRoundedIcon from '@material-ui/icons/ThumbDownRounded';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import Typography from '@material-ui/core/Typography';
import { toggleBookmarked, sendReaction, getReactions } from '../../../redux/slices/courses';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import bookmark from '../../../assets/img/icons/Bookmark.svg';
import Icon from '@material-ui/core/Icon';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import Modal from '../../../components/Modal/Modal';
import VideoReport from '../../common/VideoReport/videoReport';

const Index = (props) => {
  const { i18n , t } = useTranslation();
  const dispatch = useDispatch();
  const { lessons, data, subjectSlug, chapterSlug, lessonSlug, chapter } = props;
  const [prev_click_playlist, setPrevPlayList] = useState(false);
  const [next_click_playlist, setNextPlayList] = useState(false);
  const [message, setAlertMessage] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [severity, setAlertSeverity] = useState('success');
  const [reaction, setReaction] = useState(null);
  const [reaction_trigger, setReactionTrigger] = useState(false);
  const { bookmarked, bookmarkedMessage } = useSelector((state) => state.courses);
  const { reactions } = useSelector((state) => state.courses);
  const angry =reactions && reactions.content_reactions.angry ? reactions.content_reactions.angry : 0;
  const sad = reactions && reactions.content_reactions.sad ? reactions.content_reactions.sad : 0;
  const thinking =reactions && reactions.content_reactions.thinking ? reactions.content_reactions.thinking : 0;
  const happy =reactions && reactions.content_reactions.happy ? reactions.content_reactions.happy : 0;
  const love = reactions && reactions.content_reactions.love ? reactions.content_reactions.love : 0;
  const reactionsTotal =parseInt(sad) + parseInt(angry) + parseInt(happy) + parseInt(love) + parseInt(thinking);
  const user_type_reaction =reactions && reactions.user_reaction && reactions.user_reaction.reaction_type;
  const user_reaction =  user_type_reaction;
  const bookmarked_content = bookmarked ? bookmarked?.bookmarked : data?.bookmarked;

  let counter = 0;
  let position = 0;
  const playlist = lessons && Object.values(lessons);
  playlist &&
    playlist.forEach((item) => {
      if (item?.content?.slug === lessonSlug) {
        position = counter;
      }
      counter += 1;
    });

  let previous_playlist = playlist && playlist[position - 1];
  let next_playlist = playlist && playlist[position + 1];

  if (prev_click_playlist === true) {
    if (position > 0) {
      previous_playlist = playlist && playlist[position - 1];
    } else {
      previous_playlist = playlist && playlist[0];
    }
  }
  if (next_click_playlist === true) {
    if (position < playlist.length - 1) {
      next_playlist = playlist && playlist[position + 1];
    } else {
      next_playlist = playlist && playlist[playlist.length - 1];
    }
  }
  const previous_slug = previous_playlist && previous_playlist?.content?.slug;
  const next_slug = next_playlist && next_playlist?.content?.slug;
  const lessonId = data && data?.content?.id;
  const [open, openModal] = useState(false);
  useEffect(() => {
    if(lessonId){
      dispatch(getReactions(lessonId));
     // setReaction(null);
      setReactionTrigger(false);
    }
  }, [dispatch, lessonId, lessonSlug,reaction_trigger]);
  




  const handlePrevChange = () => {
    setPrevPlayList(true);
  };
  const openTransferModal = () => {
    openModal(true);
  };
  const onCloseModal = () => {
    openModal(false);
  };

  const handleNextChange = () => {
    setNextPlayList(true);
  };

  const toggBookmarked = (e) => {
    if (!data || data.locked === true) {
      setOpenSnackBar(true);
      setAlertSeverity('warning');
      setAlertMessage("Bonjour, débloquez d'abord ce cours!");
    } else {
     dispatch(toggleBookmarked(subjectSlug, chapter?.id, data?.content?.id));
      setOpenSnackBar(true);
      setAlertSeverity('success');
     //setAlertMessage(bookmarkedMessage);
    }
  };
  const handleSubmit = (e, reaction) => {
    if (!data || data.locked === true) {
      setOpenSnackBar(true);
      setAlertSeverity('warning');
      setAlertMessage("Bonjour, débloquez d'abord ce cours!");
    } else {
       setReaction(reaction)
      setReactionTrigger(true);
      dispatch(sendReaction(lessonId, reaction=='love'||reaction=='sad' ? reaction : null));
    }
  };

  return (
    <div className="control-button">
      <Link
        to={ chapter?.type!=='recording' ?
          '/subjects/' +
          subjectSlug +
          '/chapters/' +
          chapterSlug +
          '/lessons/' +
          previous_slug +
          '/content' :

          '/subjects/' +
          subjectSlug +
          '/chapters/' +
          chapterSlug +
          '/recordings/' +
          previous_slug +
          '/content'
        }
        key={previous_playlist && previous_playlist.content.id}
      >
        <Fab
          size="small"
          className="fabStyle"
          onClick={handlePrevChange}
          disabled={position === 0 ? true : false}
        >
          <SkipPreviousRoundedIcon className="iconStyle" />
        </Fab>
      </Link>
      <Link
        to={chapter?.type!=='recording' ?
          '/subjects/' +
          subjectSlug +
          '/chapters/' +
          chapterSlug +
          '/lessons/' +
          next_slug +
          '/content' :

          '/subjects/' +
          subjectSlug +
          '/chapters/' +
          chapterSlug +
          '/recordings/' +
          next_slug +
          '/content'
        }
        key={next_playlist && next_playlist.content.id}
      >
        <Fab
          size="small"
          className="fabStyle"
          onClick={handleNextChange}
          disabled={position === (playlist && playlist.length - 1) ? true : false}
        >
          <SkipNextRoundedIcon className="iconStyle" />
        </Fab>
      </Link>
      <Fab size="small" className="fabStyle" onClick={toggBookmarked}>
        <Icon className="bookmarkIcon">
          <img
            className={
              (bookmarked_content && bookmarked_content !== false) ? 'bookmarkImg bookmarked' : 'bookmarkImg ubookmarked'
            }
            src={bookmark}
          />
        </Icon>
      </Fab>
      <Fab
        size="small"
        className="global"
        aria-label="love"
        value="love"
        onClick={(e) => handleSubmit(e, 'love')}
        onChange={(e) => {setReaction(e.target.value)}}
      >
        <ThumbUpRoundedIcon
          className={
            user_reaction === 'love' || user_reaction === 'happy'
              ? 'iconReactionSelected'
              : 'iconReactionStyle'
          }
        />
      </Fab>
      <Fab
        size="small"
        className="fabStyle"
        name="sad"
        value="sad"
        onClick={(e) => handleSubmit(e, 'sad')}
        onChange={(e) => setReaction(e.target.value)}
      >
        <ThumbDownRoundedIcon
          className={
            user_reaction === 'sad' || user_reaction === 'angry' || user_reaction === 'thinking'
              ? 'iconReactionSelected'
              : 'iconReactionStyle'
          }
        />
      </Fab>
      <Typography variant="body2" component="p" className="title" display="inline">
        {reactionsTotal ? reactionsTotal : 0} {t("interactions")}
      </Typography>
      <div>
        <Fab size="small" className="reportFab" name="sad" onClick={openTransferModal}>
          <AnnouncementIcon className="iconReactionStyle" />
        </Fab>
        <Modal
          onCloseModal={onCloseModal}
          component={VideoReport}
          data={lessonSlug}
          openModal={open}
          title={t('signalement d erreur')}
          modalWidth={'xs'}
        />
      </div>
      {
       (bookmarkedMessage ||  message !== false) &&
        <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'Bottom',
          horizontal: 'center',
        }}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert severity={severity} onClose={() => setOpenSnackBar(false)}>
          {t(bookmarkedMessage)}
        </Alert>
      </Snackbar>
      }
    </div>
  );
};

Index.propTypes = {
  data: PropTypes.object,
  lessons: PropTypes.object,
  subjectSlug: PropTypes.any,
  chapterSlug: PropTypes.any,
  lessonSlug: PropTypes.any,
};
export default Index;
