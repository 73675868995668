import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import { Typography } from '@material-ui/core';
import OfferCardNewPeriod from './OfferCardNewPeriod';
import OfferBuyButton from './OfferBuyButton';
import OfferDetails from './OfferDetails';
import parse from 'html-react-parser';
import { useDispatch, useSelector } from 'react-redux';
import { buyOffer } from '../../redux/slices/offers';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import bookMark from '../../assets/img/bookmark.png';
import { ReactComponent as OfferWave } from '../../assets/img/offer-wave.svg';
import { offerCardMaxWidth } from '../../utilities/constants';
import OfferSubjectDetails from './OfferSubjectDetails';
import OfferPriceCards from './OfferPriceCards';
import { formatMessages } from '../../utilities/translateHelper';
import { OffreConfirmation } from '../../components';
import { CouponModal } from '../../components';
import UnsubscribeButton from './unsubscribeButton';

const OfferCard = ({ offer, updateOffer, setUpdateOffer, width, offerBookMark, offersLength }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { buyResult, loadingId, error, isLoading } = useSelector((state) => state.offers);
  const { permissions } = useSelector((state) => state.user);
  const { informations,digitalVirgoClient } = useSelector((state) => state.user);
  const { discountCodes } = useSelector((state) => state.points);
  const [selectedPeriod, setPeriod] = useState(offer?.pricing && offer?.pricing?.filter(item=>item.period =="reste de l'année").length > 0 ? "reste de l'année": "1" );
  const [pricingIndex, setPricingIndex] = useState(offer?.pricing && offer?.pricing?.filter(item=>item.period =="reste de l'année").length > 0 ? offer?.pricing?.length - 1 : 0);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [selectedSubjectsSum, setSelectedSum] = useState(0);
  const [disableBuy, setDisableButton] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [showSubjects, setShowMoreSubjects] = useState(false);
  const RevisionPackId=84;
  const GroupId=85; //group id for pack revision
  const offerLength = offer.pricing?.length-1;
  const freeOffer = offer?.offer?.is_free;
  const discount =offer?.pricing ? offer?.pricing[pricingIndex]?.infoDiscount?.discountType :
      offer?.infoDiscount?.discountType
  const offerSubjectDesibled = informations?.offers?.filter(
    (offer) => offer.offer_division_subject !== null
  ).length;

  const offerHashed = informations?.offers?.filter(
    (offer) =>
      (offer.offer_division_subject && offer.offer_division_subject.subject.id !== null) ||
      (offer.group.id === GroupId && offer.offer_division_subject === null)
  ).length;
  const subjectDetailsLength =
    offer && offer.offer_subject_details ? offer.offer_subject_details?.length : null;

  const amountSum = (list, prix) => {
    list.map((item) =>
        (prix +=
          item.discount_code.type == 'percentage'
            ? -prix * (item.discount_code.amount / 100)
            : -item.discount_code.amount)
    );
    return prix;
  };

  useEffect(() => {
    if (buyResult) {
      setUpdateOffer(!updateOffer);
    }
  }, [buyResult]);
  const handleSubmitOffer = async () => {
    setDisableButton(true);
    setConfirmation(false);
    await dispatch(
      buyOffer(
        offer.id,
        selectedPeriod,
        null,
        null,
        selectedSubjects.length
          ? selectedSubjects.includes('all') || selectedSubjects.length === subjectDetailsLength
            ? null
            : selectedSubjects
          : null
      )
    );
    setOpenSnackBar(true);
    setDisableButton(false);
  };
  const handleSelectSubject = (subjectId, price) => {
    const existSubject = selectedSubjects?.find((subject) => subject === subjectId);
    if (existSubject) {
      let result = selectedSubjects.filter((subject) => subject !== existSubject);
      if (subjectId !== 'all' || selectedSubjects.length === subjectDetailsLength) {
        setSelectedSubjects(result);
        setSelectedSum((prevState) => parseInt(prevState) - parseInt(price));
        if (subjectId === 'all') {
          setSelectedSubjects([]);
          setSelectedSum(0);
        }
      } else {
        setSelectedSubjects([]);
        setSelectedSum(0);
      }
    } else {
      setSelectedSubjects((prevArray) => [...prevArray, subjectId]);
      setSelectedSum(selectedSubjectsSum);
      if (subjectId !== 'all') {
        setSelectedSum((prevState) => parseInt(prevState) + parseInt(price));
      }
    }
  };
  let offerDescription = offer?.description ? offer.description : offer?.offer_description;
  const showMoreExist =
    (offerDescription?.length > 991 && (width <= 1100 || width >= 1590)) ||
    (offerDescription.length > 993 && width > 1200);
  const handleConfiramtion = () => {
    setConfirmation(true);
  };

  const handleShow = () => {
    setShowMoreSubjects(!showSubjects);
  };
  return (
    <Card
      className={`offer-card ${!offer.offer_subject_details?.length > 0 ? 'offer-subject' : ''}`}
      style={{ height: '100%' }}
    >
      <div
        className="card"
        style={{
          width:
            width / (2 + offersLength) <= offerCardMaxWidth
              ? width / (2 + offersLength)
              : offerCardMaxWidth,
        }}
      >
        <div className="offer-header">
          <OfferWave fill={offer?.color} />
          {offerBookMark && <img className="offer-bookmark" src={bookMark} />}
          <div className="offer-header-container" style={{ fill: offer?.color }}>
            {offer?.offer_id === 33 && digitalVirgoClient && <div >
              <UnsubscribeButton/>
            </div>}
            <div className="offer-details">
              <Typography className="offer-name">{offer.name}</Typography>
              <div className="offer-price">
                { offer.type === 'primary' && offer.pricing[pricingIndex]?.discount && (
                  <Typography
                    style={{
                      paddingLeft: offer.pricing[pricingIndex]?.final_price ? '30px' : '0px',
                    }}
                  >
                    <span className="base-offer-price">
                      {' '}
                      {offer.pricing[pricingIndex]?.price}
                      {offer.pricing[pricingIndex].infoDiscount.discountValue  ? '' : 'Dt'}
                    </span>{' '}
                    {offer.pricing[pricingIndex]?.final_price
                      ? offer.pricing[pricingIndex].infoDiscount.discountValue != null && (
                          <span id="Remise">
                            -
                            {offer.pricing[pricingIndex].infoDiscount.discountType ===
                            'percentage_discount'
                              ? offer.pricing[pricingIndex].infoDiscount.discountValue + '%'
                              : offer.pricing[pricingIndex].infoDiscount.discountValue + 'Dt'}
                          </span>
                        )
                      : ''}
                  </Typography>

                )}
                {offer.type === 'pack' &&
                  offer?.Baseprice &&
                  !offer?.subject_details?.length &&
                  !offer?.slices && (
                    <Typography style={{ paddingLeft: offer?.Finalprice ? '30px' : '0px' }}>
                      <span className="base-offer-price">
                        {offer?.Baseprice}
                        {offer?.infoDiscount.discountValue ? '' : 'Dt'}
                      </span>
                      {offer?.Finalprice
                        ? offer?.infoDiscount.discountValue != null && (
                            <span id="Remise">
                              -
                              {offer?.infoDiscount?.discountType === 'percentage_discount'
                                ? offer?.infoDiscount.discountValue + '%'
                                : offer.infoDiscount.discountValue}
                              {offer?.infoDiscount?.discountType !== 'percentage_discount' && (
                                <span id="dinnar">dt</span>
                              )}
                            </span>
                          )
                        : ''}
                    </Typography>
                  )}
                {offer.type === 'pack' && !offer?.subject_details?.length &&  (
                  <Typography className="final-offer-price">
                    {offer?.slices ? offer?.slices.amount : offer?.Finalprice || offer?.price} Dt
                  </Typography>
                )}
                {offer.type === 'primary' &&
                  (offer.pricing.length > 0 ? (
                    <Typography className="final-offer-price">
                      {offer.pricing[pricingIndex]?.final_price
                        ? offer.pricing[pricingIndex]?.final_price
                        : offer.pricing[pricingIndex]?.price}{' '}
                      {selectedPeriod === "reste de l'année" ||  selectedPeriod === '6' ||  selectedPeriod === '3' || selectedPeriod === '1' ? 'Dt' : ''}
                    </Typography>
                  ) : (
                    <Typography className="final-offer-price">{t('Gratuit')}</Typography>
                  ))}
              </div>
            </div>
          </div>
        </div>
        {freeOffer === false && offer.type === 'primary' &&  offer.name !== "Silver" && offer.offer_id !== 102 && (
          <OfferCardNewPeriod
           isNewPeriod={false}
           classCard={'down'}
            color={offer.color}
            pricings={offer.pricing}
            setPeriod={setPeriod}
            selectedPeriod={selectedPeriod}
            setPricingIndex={setPricingIndex}
            offerLength={offerLength}
            width={
              width / (2 + offersLength) <= offerCardMaxWidth
                ? width / (2 + offersLength)
                : offerCardMaxWidth
            }
          />
        )}
                {freeOffer === false && offer.type === 'primary' && offer.name === "Silver" && offer.offer_id !== 102 && (

          <OfferCardNewPeriod
          classCard={'down'}
           isNewPeriod={true}
            color={offer.color}
            pricings={offer.pricing}
            setPeriod={setPeriod}
            selectedPeriod={selectedPeriod}
            setPricingIndex={setPricingIndex}
            pricingIndex={pricingIndex}
            offerLength={offerLength}
          width={
              width / (2 + offersLength) <= offerCardMaxWidth
                ? width / (2 + offersLength)
                : offerCardMaxWidth
            }
          />
        )}
        {!offer?.offer_subject_details?.length && (
          <OfferBuyButton
            color={offer.color}
            button={t(
              formatMessages(
                `${
                  offer.offer_id === RevisionPackId &&
                  offerSubjectDesibled !== offer.offer_subject_details?.length &&
                  offerSubjectDesibled !== 0
                    ? 'Acheter'
                    : offer.Button
                }`
              )
            )}
            handleSubmit={handleConfiramtion}
            isLoading={loadingId}
            offerId={offer.id}
            disabled={
              offer.offer_id === RevisionPackId
                ? offerSubjectDesibled === offer.offer_subject_details?.length
                : offer?.disabled
            }
            sliceAmount={offer?.slices?.slice?.amount}
            disableButton={isLoading}
          />
        )}
        {
          <OfferDetails
            description={parse(offerDescription)}
            offerType={offer.type}
            showMore={showMore}
            showMoreExist={showMoreExist}
            width={width}
          />
        }
        {!!offer.offer_subject_details?.length && showSubjects && (
          <div>
            {offer.offer_subject_details?.length ? (
              <button
                style={{
                  backgroundColor: 'white',
                  color: offer.color,
                  border: `1.8px solid ${offer.color}`,
                }}
                className={`ShowMoreButton`}
                onClick={handleShow}
              >
                <div className={'ShowMoreButtonText'}>{t('Choisir une matière')}</div>
              </button>
            ) : (
              ''
            )}
            {offer.offer_subject_details.map((subject) => {
              return (
                <OfferSubjectDetails
                  name={t(formatMessages(`${subject.subject}`))}
                  description={subject.description}
                  color={offer.color}
                  handleSelectSubject={handleSelectSubject}
                  selectedSubjects={selectedSubjects}
                  disabledOption={informations?.offers?.find(
                    (offer) =>
                      (offer.offer_division_subject &&
                        offer.offer_division_subject.subject.id === subject.subjectId) ||
                      (offer.group.id === GroupId && offer.offer_division_subject === null)
                  )}
                  id={subject.id}
                  price={subject.price}
                  offers={informations?.offers}
                />
              );
            })}
            <OfferSubjectDetails
              name={t('Toutes les matières')}
              description={`Vous bénéficiez d\'une promotion de   ${
                '20%' || ''
              } pour l\'achat du Pack complet.`}
              color={offer.color}
              selectedSubjects={selectedSubjects}
              id={'all'}
              handleSelectSubject={handleSelectSubject}
              disabledOption={offerHashed > 0}
              subjectDetailsLength={subjectDetailsLength}
            />
          </div>
        )}
        {!offer?.offer?.is_free && offer.type === 'primary' &&  offer.name !== "Silver" && offer.offer_id !== 102 && (
          <OfferCardNewPeriod
           isNewPeriod={false}
           classCard={'down'}
            color={offer.color}
            pricings={offer.pricing}
            setPeriod={setPeriod}
            selectedPeriod={selectedPeriod}
            setPricingIndex={setPricingIndex}
           isFree={offer?.offer?.is_free}
           width={
              width / (2 + offersLength) <= offerCardMaxWidth
                ? width / (2 + offersLength)
                : offerCardMaxWidth
            }
          />
        )}
                {!offer?.offer?.is_free && offer.type === 'primary' && offer.name === "Silver" && offer.offer_id !== 102 && (

          <OfferCardNewPeriod
          classCard={'down'}
           isNewPeriod={true}
            color={offer.color}
            pricings={offer.pricing}
            setPeriod={setPeriod}
            selectedPeriod={selectedPeriod}
            setPricingIndex={setPricingIndex}
            isFree={offer.offer.is_free}
            width={
              width / (2 + offersLength) <= offerCardMaxWidth
                ? width / (2 + offersLength)
                : offerCardMaxWidth
            }
          />
        )}

        {!!offer.offer_subject_details?.length && showSubjects && selectedPeriod === "reste de l'année" && (
          <OfferPriceCards
            subjects={offer.offer_subject_details}
            color={offer.color}
            handleSelectSubject={handleSelectSubject}
            selectedSubjects={selectedSubjects}
            totalPrice={selectedSubjectsSum}
            offerBasePrice={offer.Baseprice || ''}
            discount={offer.Finalprice || false}
            subjectDetailsLength={subjectDetailsLength}
            offerPrice={offer.Finalprice || offer.price}

          />
        )}
        {offer.offer_subject_details?.length && showSubjects === false ? (
          <button
            style={{
              backgroundColor: 'white',
              color: offer.color,
              border: `1.8px solid ${offer.color}`,
            }}
            className={`ShowMoreButton`}
            onClick={handleShow}
          >
            <div className={'ShowMoreButtonText'}>{t('Choisir une matière')}</div>
          </button>
        ) : (
          ''
        )}
        <OfferBuyButton
          color={offer.color}
          button={t(
            formatMessages(
              `${
                offer.offer_id === RevisionPackId &&
                offerSubjectDesibled !== offer.offer_subject_details?.length &&
                offerSubjectDesibled !== 0
                  ? t('Acheter')
                  : offer.Button
              }`
            )
          )}
          handleSubmit={handleConfiramtion}
          isLoading={loadingId}
          offerId={offer.id}
          disabled={
            offer.offer_id === RevisionPackId
              ? offerSubjectDesibled === offer.offer_subject_details?.length ||
                informations?.offers?.filter(
                  (offer) => offer.group.id === GroupId && offer.offer_division_subject === null
                ).length ||
                (offerSubjectDesibled !== offer.offer_subject_details?.length &&
                  offerHashed !== 0 &&
                  selectedSubjects.length === 0)
              : offer.disabled
          }
          sliceAmount={offer?.slices?.slice?.amount}
          disableButton={isLoading}
        />
        <OffreConfirmation
          handleClick={handleSubmitOffer}
          setOpen={setConfirmation}
          offre={offer.name}
        />
        {confirmation && (
          <CouponModal
          setPeriod={setPeriod}
          selectedPeriod={selectedPeriod}
            open={confirmation}
            setOpen={setConfirmation}
            handleAcheter={handleSubmitOffer}
            prix={
              offer.type === 'primary'
                ? offer?.pricing[pricingIndex]?.final_price
                : (offer.type === 'primary' || offer.type === 'pack') &&
                  offer.offer_id !== RevisionPackId &&
                  offer.Button !== ('Acheter' || 'Prolonger')
                ? offer?.slices?.amount
                : offer.type === 'pack' && offer?.Finalprice && offer.offer_id !== RevisionPackId
                ? offer?.Finalprice
                : (offer.offer_id === RevisionPackId &&
                    (selectedSubjects.includes('all') === true ||
                      selectedSubjects.length === subjectDetailsLength)) ||
                  selectedSubjects.length === 0
                ? offer?.Finalprice || offer?.price
                : selectedSubjectsSum !== 0 &&
                  offer.offer_id === RevisionPackId &&
                  (selectedSubjects.includes('all') === false ||
                    selectedSubjects.length !== subjectDetailsLength)
                ? selectedSubjectsSum
                : offer?.price
            }
            priceNoCoupon={offer?.pricing ? offer?.pricing[pricingIndex]?.price : offer?.price }
            finalPriceNoCoupon={offer?.pricing ? offer?.pricing[pricingIndex]?.final_price : offer?.Finalprice}
            discountValueNoCoupon={offer?.pricing ? offer?.pricing[pricingIndex]?.infoDiscount?.discountValue : offer?.infoDiscount?.discountValue}
              discountType={discount}
            remise={permissions.discount}
            offer={offer.name}
            color={offer.color}
            amount={amountSum}
            offerId={offer.offer_id}
            isFree={offer.offer.is_free}
            indexSliceToPay={offer?.indexSliceToPay}
            weekly={offer.weeklyPrice}
            daily={offer?.dailyPrice}
            offerDivisionId={offer.id}
            typePeriod={selectedPeriod}
            offerParam={offer}
            setPricingIndex={setPricingIndex}
          />

        )}

    {confirmation && offer.type ==='pack' &&(
          <CouponModal
          setPeriod={setPeriod}
          selectedPeriod={selectedPeriod}
            open={confirmation}
            setOpen={setConfirmation}
            handleAcheter={handleSubmitOffer}
            prix={
              offer.type === 'primary'
                ? offer?.pricing[pricingIndex]?.final_price
                : (offer.type === 'primary' || offer.type === 'pack' )
                && offer.offer_id!==RevisionPackId
                && offer.Button !== ('Acheter' || 'Prolonger')

                ? offer?.slices?.amount
                : offer.type === 'pack'
                && offer?.Finalprice
                && offer.offer_id!== RevisionPackId
                ? offer?.Finalprice
                : (offer.offer_id=== RevisionPackId
                && (selectedSubjects.includes('all')===true
                || selectedSubjects.length === subjectDetailsLength)
                ||selectedSubjects.length===0)
                ? (offer?.Finalprice || offer?.price)
                : selectedSubjectsSum !== 0
                && offer.offer_id=== RevisionPackId
                && (selectedSubjects.includes('all')===false
                || selectedSubjects.length !== subjectDetailsLength)
                ? selectedSubjectsSum
                : offer?.price
            }
            remise={permissions.discount}
            offer={offer.name}
            color={offer.color}
            amount={amountSum}
            offerId={offer.offer_id}
            weekly={offer.weeklyPrice}
            daily={offer?.dailyPrice}
            offerDivisionId={offer.id}
            typePeriod={selectedPeriod}
            offerParam={offer}
            isFree={offer.offer.is_free}
            indexSliceToPay={offer?.indexSliceToPay}
            setPricingIndex={setPricingIndex}
          />
        )}
      </div>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'Bottom',
          horizontal: 'center',
        }}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert severity={error ? 'warning' : 'success'} onClose={() => setOpenSnackBar(false)}>
          {error ? t(formatMessages(error.message)) : t(formatMessages(buyResult.message))}
        </Alert>
      </Snackbar>
    </Card>
  );
};
OfferCard.propTypes = {
  buyResult: PropTypes.any,
  isLoading: PropTypes.any,
  loadingId: PropTypes.any,
  error: PropTypes.any,
};
export default OfferCard;
