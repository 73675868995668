import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import download from '../../assets/img/icons/download.svg';

const useStyles = makeStyles((theme) => ({}));

const ExamDownloadButton = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { homework, onClick, text, link } = props;
  return (
    <>
      <div className="examDownloadButton">
        <div className="buttonTitle">
          <Typography>{text}</Typography>
          {homework && (
            <DeleteOutlineIcon
              onClick={onClick}
            />
          )}
        </div>
        <div>
          <a href={link} rel="noreferrer" target="_blank" className="buttonLink">
            <img src={download} className="icon" alt="icon" />
            <div className="buttonText">{t("Télécharger")}</div>
          </a>
        </div>
      </div>
    </>
  );
};

export default ExamDownloadButton;
