import React, { useEffect, useState } from 'react';
import ClearIcon from '@material-ui/icons/Clear';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import { deleteDiscountCodes } from '../../redux/slices/points';
import { useDispatch } from 'react-redux';

const CouponItem =(props)=>  {
    const {id,couponCode,offerId,check,...rest}=props;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const handleDelete=()=>{
        dispatch(deleteDiscountCodes(id))
    }
    return <div>
        <div>
            <IconButton onClick={handleDelete}>
                <ClearIcon  style={{ verticalAlign: 'middle'}}/>{' '}
            </IconButton>
            {i18n.language === 'ar' ?  (
                    <div>
                        {t('est appliqué')} <span style={{ fontWeight: 'bold'}}>{couponCode.code}</span>  {t('Le code')}
                    </div>) :
                (<div>
                    {t('Le code')} <span style={{ fontWeight: 'bold'}}>{couponCode.code}</span> {t('est appliqué')}
                </div>)}
        </div>
    </div>;

}

export default CouponItem;
