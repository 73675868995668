import React, { useEffect, useState } from 'react';
import SectionCard from './digitalVergoComponents/sectionCard';
import Silver from '../../assets/img/icons/Silver.png';
import DigitalVergoModal from './digitalVergoModal';
import { getDivisionsByLevel, getSilverOffer } from '../../redux/slices/auth';
import { useDispatch, useSelector } from 'react-redux';
import VergoHeader from './header/VergoHeader';

const Index = () => {
  const [open, setOpenModal] = useState(false);
  const [offers, setOffers] = useState([]);
  const [validationResult, setValidationResult] = useState(digitalVergoResult);
  const [validationStatus, setValidationStatus] = useState(success);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [price, setPrice] = useState({ dailyPrice: '', weeklyPrice: '' });
  const dispatch = useDispatch();
  const { silverOffers, digitalVergoResult, success } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getSilverOffer());
  }, []);

  useEffect(() => {
    setValidationResult(digitalVergoResult);
  }, [digitalVergoResult]);
  useEffect(() => {
    setValidationStatus(success);
  }, [success]);
  useEffect(() => {
    setOffers(() => handleOffer(silverOffers));
  }, [silverOffers]);
  const handleOfferLevel = (level, dailyPrice, weeklyPrice) => {
    setPrice({ dailyPrice, weeklyPrice });
    setOpenModal(true);
    dispatch(getDivisionsByLevel(level));
  };

  const handleOffer = (silverOffers) => {
    let newArr = [];
    silverOffers?.length > 0 &&
      silverOffers.map((item,index) => {
        if (item.level == 'primaire') {
          newArr[0]=item;
        }
        if (item.level == 'base') {
         newArr[1]=item
        }
        if (item.level == 'secondaire') {
          newArr[2]=item
        }
        if (item.level == 'bac') {
          newArr[3]=item
        }
      });

    return newArr;
  };
  return (
    <div className="digitalVergo">
      <VergoHeader />
      <div className="digitalVergo-title"> استثمر في تطوير مهاراتك واشترك الآن</div>
      <div className="digitalVergo-title-description">
      من الابتدائي إلى البكالوريا    
        </div>
      <div className="digitalVergo-cards">
        {offers.length > 0 &&
          offers?.map((item, index) => {
            return (
              <SectionCard
                key={index}
                offerImage={Silver}
                offerName={item.name}
                text={[
                  'فيديوهات تفسير كامل الدروس في كل المواد',
                  'تمارين مرفقة بالاصلاح مطابق للبرنامج الرسمي',
                  ' (Magazines + PDF) مجلات تلاخيص الدروس ',
                ]}
                section={
                  item.level == 'base'
                    ? 'الأساسي'
                    : item.level == 'primaire'
                    ? ' الابتدائي'
                    : item.level == 'bac'
                    ? ' البكالوريا'
                    : 'الثانوي'
                }
                priceDescription={
                  <span className='textRtl'>
                    {item.weeklyPrice + ' ' + 'د/الأسبوع'} <br />{' '}
                    {item.dailyPrice + ' ' + ' د/اليوم '}
                  </span>
                }
                OnClick={() => handleOfferLevel(item?.level, item.dailyPrice, item.weeklyPrice)}
              ></SectionCard>
            );
          })}
      </div>
      <DigitalVergoModal
        setOpenSnackBar={setOpenSnackBar}
        setValidationResult={setValidationResult}
        setValidationStatus={setValidationStatus}
        price={price}
        open={open}
        setOpen={setOpenModal}
        validationStatus={validationStatus}
        validationResult={validationResult}
        openSnackBar={openSnackBar}
      />
    
    </div>
  );
};

export default Index;
