import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { isAuthenticated, isVerified } from '../utilities/auth';
import { useSelector } from 'react-redux';
import { Confirm, UpdatePassword } from '../pages';

function AuthRoute({ component: Component, allowedAuthorities, authorityKey, ...rest }) {
  const user = localStorage.getItem('token');
  return (
    <Route
      {...rest}
      render={(props) => (isAuthenticated(user) ? <Redirect to="/" /> : <Component {...props} />)}
    />
  );
}

function PrivateRoute({ component: Component, allowedAuthorities, authorityKey, ...rest }) {
  const user = localStorage.getItem('token');
  const { informations, permissions } = useSelector((state) => state.user);
  const completed = informations && informations.completed;
  const changePassword = permissions && permissions.changePassword;
  const googleId = informations && informations.google_id;
  const facebookId = informations && informations.facebook_id;
  const social = (googleId || facebookId) && completed != 1;
  const adminId = localStorage.getItem('admin_user_id');
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated(user) ? (
          !changePassword && 
          (isAuthenticated(adminId) ||
          (!informations?.phone && informations?.countryId === 1) ||
          isVerified(social || informations?.verified)) ||
          (changePassword && isAuthenticated(adminId))? (
            <Component {...props} />
          ) : (
            <>
            {informations && !informations?.verified &&(
              <>
                <Redirect
                  to={
                    document.referrer.includes('?nextPage=offres')
                      ? '/confirm?nextPage=offres'
                      : '/confirm'
                  }
                /> 
                <Route path="/" component={Confirm} />
              </>
            )}
            {changePassword && (
              <>
                <Redirect
                  to={
                  document.referrer.includes('?nextPage=offres')
                    ? '/update-password?nextPage=offres'
                    : '/update-password'
                  }
                />
                <Route path="/" component={UpdatePassword} />
              </>
            )}
          </>
          )
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}

export { AuthRoute, PrivateRoute };
